@import "bootstrap/dist/css/bootstrap.min.css";
@import 'font-awesome/css/font-awesome.min.css';
@import 'linearicons/dist/web-font/style.css';

@import 'datatables.net-dt';
@import 'datatables.net-buttons-dt';
@import 'datatables.net-select-dt';

@font-face {
  font-family: 'Proxima Nova Cn Th';
  src: url('../fonts/globalis/ProximaNovaCond-Thin.eot');
  src: local('Proxima Nova Condensed Thin'), local('ProximaNovaCond-Thin'),
  url('../fonts/globalis/ProximaNovaCond-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-Thin.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-Thin.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url('../fonts/globalis/ProximaNovaCond-BoldIt.eot');
  src: local('Proxima Nova Condensed Bold Italic'), local('ProximaNovaCond-BoldIt'),
  url('../fonts/globalis/ProximaNovaCond-BoldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-BoldIt.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-BoldIt.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Cn Lt';
  src: url('../fonts/globalis/ProximaNovaCond-SemiboldIt.eot');
  src: local('Proxima Nova Condensed Semibold Italic'), local('ProximaNovaCond-SemiboldIt'),
  url('../fonts/globalis/ProximaNovaCond-SemiboldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-SemiboldIt.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-SemiboldIt.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Cn Th';
  src: url('../fonts/globalis/ProximaNovaCond-ThinIt.eot');
  src: local('Proxima Nova Condensed Thin Italic'), local('ProximaNovaCond-ThinIt'),
  url('../fonts/globalis/ProximaNovaCond-ThinIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-ThinIt.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-ThinIt.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-ThinIt.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Cn Lt';
  src: url('../fonts/globalis/ProximaNovaCond-Light.eot');
  src: local('Proxima Nova Condensed Light'), local('ProximaNovaCond-Light'),
  url('../fonts/globalis/ProximaNovaCond-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-Light.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-Light.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url('../fonts/globalis/ProximaNovaCond-RegularIt.eot');
  src: local('Proxima Nova Condensed Regular Italic'), local('ProximaNovaCond-RegularIt'),
  url('../fonts/globalis/ProximaNovaCond-RegularIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-RegularIt.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-RegularIt.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Cn Bl';
  src: url('../fonts/globalis/ProximaNovaCond-Black.eot');
  src: local('Proxima Nova Condensed Black'), local('ProximaNovaCond-Black'),
  url('../fonts/globalis/ProximaNovaCond-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-Black.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-Black.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Cn Th';
  src: url('../fonts/globalis/ProximaNovaCond-ExtrabldIt.eot');
  src: local('Proxima Nova Condensed Extrabold Italic'), local('ProximaNovaCond-ExtrabldIt'),
  url('../fonts/globalis/ProximaNovaCond-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-ExtrabldIt.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-ExtrabldIt.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-ExtrabldIt.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Cn Th';
  src: url('../fonts/globalis/ProximaNovaCond-Extrabld.eot');
  src: local('Proxima Nova Condensed Extrabold'), local('ProximaNovaCond-Extrabld'),
  url('../fonts/globalis/ProximaNovaCond-Extrabld.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-Extrabld.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-Extrabld.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url('../fonts/globalis/ProximaNovaCond-Bold.eot');
  src: local('Proxima Nova Condensed Bold'), local('ProximaNovaCond-Bold'),
  url('../fonts/globalis/ProximaNovaCond-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-Bold.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-Bold.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Cn Lt';
  src: url('../fonts/globalis/../../fonts/globalis/ProximaNovaCond-Semibold.eot');
  src: local('Proxima Nova Condensed Semibold'), local('ProximaNovaCond-Semibold'),
  url('../fonts/globalis/ProximaNovaCond-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-Semibold.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-Semibold.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Cn Lt';
  src: url('../fonts/globalis/ProximaNovaCond-LightIt.eot');
  src: local('Proxima Nova Condensed Light Italic'), local('ProximaNovaCond-LightIt'),
  url('../fonts/globalis/ProximaNovaCond-LightIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-LightIt.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-LightIt.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url('../fonts/globalis/ProximaNovaCond-Regular.eot');
  src: local('Proxima Nova Condensed Regular'), local('ProximaNovaCond-Regular'),
  url('../fonts/globalis/ProximaNovaCond-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-Regular.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-Regular.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Cn Bl';
  src: url('../fonts/globalis/ProximaNovaCond-BlackIt.eot');
  src: local('Proxima Nova Condensed Black Italic'), local('ProximaNovaCond-BlackIt'),
  url('../fonts/globalis/ProximaNovaCond-BlackIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/globalis/ProximaNovaCond-BlackIt.woff2') format('woff2'),
  url('../fonts/globalis/ProximaNovaCond-BlackIt.woff') format('woff'),
  url('../fonts/globalis/ProximaNovaCond-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}


:root {
  --main-color: #FFF;
  --secondary-color: #FFF;

  --navigation-menu-background1: #FFF;
  --navigation-menu-background2: #FFF;

  --navigation-item: #000;
  --navigation-item-hover: var(--main-color);
  --navigation-item-before: var(--main-color);
  --navigation-item-after: var(--main-color);
  --navigation-search: var(--main-color);
  --navigation-search-color: var(--main-color);

  --footer-background: #dddddd;
  --footer-fon-color: #000;
  --footer-title-fon-color: #000;
  --footer-arrow-color: #000;
  --footer-hofer: #000;

  --button-background: var(--main-color);
  --button-background-shadow: var(--main-color);
  --button-background-hover: var(--main-color);
  --button-background-text-color: #FFF;
  --button-background-text-hover: #FFF;
  --button-radius: 5px;

  --button2-background: var(--main-color);
  --button2-background-shadow: var(--main-color);
  --button2-background-hover: var(--main-color);
  --button2-background-text-color: #FFF;
  --button2-background-text-hover: #FFF;
  --button2-radius: 5px;

  --counter-background: var(--main-color);
  --counter-background-shadow: var(--main-color);
  --counter-text-color: #FFF;
  --discount-background: var(--main-color);
  --discount-background-shadow: var(--main-color);
  --discount-text-color: #FFF;

  --review-text-color: #FFF;
  --review-rating-color: #FFF;
  --review-background: var(--main-color);

  --card-header-background: var(--main-color);
  --card-header-border-color: var(--main-color);
  --card-text-color: #FFF;

  --icon-color: var(--main-color);

  --price-color: var(--main-color);

  --search-box-background: var(--main-color);
  --search-box-color: #FFF;
  --search-box-Button: #FFF;
  --search-box-Button-color: #000;
  --search-box-Button-hover: rgba(255, 255, 255, 0.88);
}

* {
  margin: 0;
  padding: 0;
}

html {
  //font-family: sans-serif;
  font-family: 'Proxima Nova Cn Th';
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

a:focus, button {
  outline: none;
  text-decoration: none;
}

a {
  background-color: transparent;
}

a:active, a:hover, a:focus, a:visited {
  outline: none !important;
  text-decoration: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #E8E8E8;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

body, button, input, select, textarea {
  color: #404040;
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
}

p {
  margin-bottom: 15px;
}

p:last-child {
  margin-bottom: 0;
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code, kbd, tt, var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark, ins {
  text-decoration: none;
}

big {
  font-size: 125%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background: #fff;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

ul, ol {
  margin: 0 0 1.5em 3em;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul, li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

img {
  height: auto;
  max-width: 100%;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  border: 1px solid;
  border-color: #ccc #ccc #bbb;
  background: #e6e6e6;
}

/*
input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
  color: #666;
}
*/

select {
  border: 1px solid #ccc;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus {
  color: #111;
}

/*input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"] {
  padding: 3px;
}*/

textarea {
  padding-left: 3px;
  width: 100%;
}

a:focus {
  outline: thin dotted;
}

a:hover, a:active {
  outline: 0;
}

.menu-toggle, .main-navigation.toggled ul {
  display: block;
}

@media screen and (min-width: 37.5em) {
  .menu-toggle {
    display: none;
  }

  .main-navigation ul {
    display: block;
  }
}

.site-main .comment-navigation, .site-main .posts-navigation, .site-main .post-navigation {
  margin: 1.5em 0;
  overflow: hidden;
  padding-top: 10px;
}

.site-main .comment-navigation .nav-links {
  overflow: hidden;
}

.comment-navigation .nav-previous, .posts-navigation .nav-previous, .post-navigation .nav-previous {
  float: left;
  width: 50%;
  text-align: left;
}

.comment-navigation .nav-next, .posts-navigation .nav-next, .post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

#content[tabindex="-1"]:focus {
  outline: 0;
}

.alignleft {
  float: left;
}

img.alignleft {
  margin-right: 25px;
}

img .alignright {
  margin-left: 25px;
}

.alignright {
  float: right;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.clear:before, .clear:after, .entry-content:before, .entry-content:after, .comment-content:before, .comment-content:after, .site-header:before, .site-header:after, .site-content:before, .site-content:after, .site-footer:before, .site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after, .entry-content:after, .comment-content:after, .site-header:after, .site-content:after, .site-footer:after {
  clear: both;
}

.widget {
  margin: 0 0 1.5em;
}

.widget select {
  max-width: 100%;
}

.sticky {
  display: block;
}

.byline, .updated:not(.published) {
  display: none;
}

.single .byline, .group-blog .byline {
  display: inline;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
}

.blog .format-aside .entry-title, .archive .format-aside .entry-title {
  display: none;
}

.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

.infinite-scroll .posts-navigation, .infinite-scroll.neverending .site-footer {
  display: none;
}

.infinity-end.neverending .site-footer {
  display: block;
}

.page-content .wp-smiley, .entry-content .wp-smiley, .comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

embed, iframe, object {
  max-width: 100%;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

table, th, td {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 4px 6px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin-bottom: 24px;
  width: 100%;
}

caption, th, td {
  font-weight: normal;
  text-align: left;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}

td {
  border-width: 0 1px 1px 0;
}

dd {
  margin-bottom: 24px;
}

.post-password-form label {
  font-weight: normal;
}

.post-password-form label input[type="password"] {
  padding: 6px 15px;
  border: 1px solid #E8E8E8;
}

.post-password-form input[type="Submit"] {
  padding: 6px 20px;
  border: none;
  background: #181818;
  color: #fff;
  box-shadow: 0 0 0;
  border-radius: 0;
}

input[type=radio], input[type=checkbox] {
  border: 1px solid #b4b9be;
  background: #fff;
  color: #555;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 16px;
  margin: -4px 4px 0 0;
  outline: 0;
  padding: 0 !important;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  min-width: 16px;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: .05s border-color ease-in-out;
  transition: .05s border-color ease-in-out;
}

input[type=radio]:focus, input[type=radio]:active, input[type=checkbox]:focus, input[type=checkbox]:active {
  outline: none;
}

input[type=radio]:checked + label:before {
  color: #82878c;
}

input[type=radio] {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 4px;
  line-height: 10px;
}

input[type=radio]:checked:before, input[type=checkbox]:checked:before {
  float: left;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  font: 400 21px/1 FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=checkbox]:checked:before {
  content: "\f00c";
  margin: 1px 0 0 0;
  color: #1e8cbe;
  font-size: 12px;
}

input[type=radio]:checked:before {
  content: "\f111";
  text-indent: -9999px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  font-size: 24px;
  width: 6px;
  height: 6px;
  margin: 4px;
  line-height: 16px;
  background-color: #1e8cbe;
}

@-moz-document url-prefix() {
  .form-table input.tog, input[type=radio], input[type=checkbox] {
    margin-bottom: -1px;
  }
}

.shortcode_title {
  color: #333;
}

.shortcode_title.title-center {
  margin-bottom: 60px;
  text-align: center;
  position: relative;
}

.shortcode_title .title_subtitle {
  margin-bottom: 10px;
  font-size: 1.200em;
  color: inherit;
  line-height: 1;
}

.shortcode_title .title_primary {
  margin: 0;
  line-height: 1.333em;
  font-weight: 700;
  text-transform: uppercase;
  color: inherit;
}

.shortcode_title.title-decoration-bottom-center {
  padding-bottom: 20px;
}

.shortcode_title.title-decoration-bottom-center .line_after_title:after {
  content: ' ';
  display: block;
  position: absolute;
  height: 1px;
  bottom: -2px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 50px;
  border-style: solid;
  border-width: 3px 0 0 0;
}

.shortcode_title.title-decoration-bottom-center .line_after_title:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  margin-left: -4px;
  bottom: 1px;
  border-style: solid;
  border-width: 0 4px 4px 4px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.shortcode_title.title-underline {
  border-bottom: 1px solid #e9e9e9;
}

.shortcode_title.title-underline:before {
  display: none;
}

.shortcode_title.text-white .title_subtitle, .shortcode_title.text-white .title_primary {
  color: #fff;
}

.shortcode_title.text-white.title-decoration-bottom-center .line_after_title {
  color: #fff;
}

.iconbox-center {
  text-align: center;
}

.iconbox-center .boxes-icon {
  margin: 0 auto 20px auto;
  width: 80px;
  height: 80px;
  line-height: 80px;
  display: inline-block;
  font-size: 0;
}

.iconbox-center .heading__primary {
  margin-top: 0;
}

.iconbox-center .icon-image {
  display: inline-block;
  font-size: 0;
  margin: 0 auto;
}

.widget-icon-box .boxes-icon {
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0 0 3px #26BDF7;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  cursor: pointer;
}

.widget-icon-box .boxes-icon:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  background: #26BDF7;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.widget-icon-box .boxes-icon:hover:after {
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  transform: scale(0.85);
}

.widget-icon-box a:hover {
  color: inherit;
}

.inner-icon {
  display: table;
  text-align: center;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  z-index: 1;
}

.inner-icon i {
  font-size: inherit;
}

.iconbox-left .boxes-icon {
  float: left;
}

.iconbox-left .content-inner {
  float: left;
  text-align: left;
  padding-left: 20px;
}

.iconbox-left .content-inner .heading__primary {
  margin-top: 0;
}

.iconbox-right .boxes-icon {
  float: right;
}

.iconbox-right .content-inner {
  float: left;
  text-align: right;
  padding-right: 20px;
}

.iconbox-right .content-inner .heading__primary {
  margin-top: 0;
}

.tours-type-slider .tours_type_item {
  position: relative;
  text-align: center;
  /*padding: 0 15px;*/
  width: 20%;
  float: left;
}

.tours-type-slider .tours_type_item .content-item,
.tours-type-slider .tours_type_item .content-item h2 {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  color: #fff;
  font-size: 22px;
  text-align: center;
  pointer-events: none;
  font-style: normal;
  text-transform: uppercase;
  transform: translateY(-50%);
}

.tours-type-slider .tours_type_item .tours-type__item__image {
  display: block;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-bottom: none;
}

.tours-type-slider .tours_type_item .tours-type__item__image img {
  border-radius: 50%;
}

.tours-type-slider .tours_type_item .tours-type__item__image:hover {
  border-bottom: none;
  opacity: 0.8;
}

.tours-type-pain .item-tour {
  margin-bottom: 30px;
}

.tours-type-pain .tours_type_item {
  width: 33.33%;
  padding: 0 10px;
  margin-bottom: 20px;
  float: left;
  position: relative;
}

.tours-type-pain .tours_type_item .count-attr {
  font-size: 1.2em;
  font-weight: 300;
}

.tours-type-pain .tours_type_item .item__title {
  font-size: 2.6em;
  line-height: 1.1em;
}

.tours-type-pain .tours_type_item .item__title:before {
  font: normal normal normal 0.7em/1 'Linearicons-Free';
  content: "\e833";
  margin-right: 10px;
}

.tours-type-pain .tours_type_item.width2x3 {
  width: 66.67%;
}

.tours-type-pain .tours_type_item:nth-child(3) {
  clear: both;
}

.tours-type-pain .tours_type_item .tours-type__item__image {
  display: block;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-bottom: none;
  overflow: hidden;
  position: relative;
}

.tours-type-pain .tours_type_item .tours-type__item__image:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 500ms ease-out;
  -moz-transition: opacity 500ms ease-out;
  -ms-transition: opacity 500ms ease-out;
  -o-transition: opacity 500ms ease-out;
  transition: opacity 500ms ease-out;
  opacity: 0;
}

.tours-type-pain .tours_type_item .content-item {
  position: absolute;
  bottom: 15px;
  right: 30px;
  text-align: right;
  color: #fff;
}

.tours-type-pain .tours_type_item:hover .tours-type__item__image:before {
  opacity: 1;
}

.shortcode_title {
  color: #333;
}

.shortcode_title.shortcode-title-style_1 {
  margin-bottom: 60px;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
}

.shortcode_title.shortcode-title-style_1 .line_after_title {
  color: #ffd200;
}

.shortcode_title.shortcode-title-style_1 .line_after_title:after {
  content: ' ';
  display: block;
  position: absolute;
  height: 1px;
  bottom: -2px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 50px;
  border-style: solid;
  border-width: 3px 0 0 0;
}

.shortcode_title.shortcode-title-style_1 .line_after_title:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  margin-left: -4px;
  bottom: 1px;
  border-style: solid;
  border-width: 0 4px 4px 4px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.shortcode_title .title_subtitle {
  margin-bottom: 10px;
  font-size: 1.200em;
  color: inherit;
  line-height: 1.3em;
}

.shortcode_title .title_subtitle a:hover {
  text-decoration: underline;
}

.shortcode_title .title_primary {
  margin: 0;
  line-height: 1.333em;
  font-weight: 700;
  text-transform: uppercase;
  color: inherit;
}

.shortcode_title.title-underline {
  border-bottom: 1px solid #e9e9e9;
}

.shortcode_title.title-underline:before {
  display: none;
}

.shortcode_title.text-white .title_subtitle, .shortcode_title.text-white .title_primary {
  color: #fff;
}

.shortcode_title.text-white.shortcode-title-style_1 .line_after_title {
  color: #fff;
}

.shortcode_title.shortcode-title-style_2 {
  margin-bottom: 40px;
  text-align: center;
  position: relative;
}

.shortcode_title.shortcode-title-style_2 .title_primary {
  text-transform: none;
}

.shortcode_title.shortcode-title-style_2 .title_subtitle {
  margin-top: 10px;
}

.shortcode_title.shortcode-title-style_3 {
  margin-bottom: 20px;
  position: relative;
}

.shortcode_title.shortcode-title-style_3 {
  *zoom: 1;
}

.shortcode_title.shortcode-title-style_3:before, .shortcode_title.shortcode-title-style_3:after {
  content: "";
  display: table;
}

.shortcode_title.shortcode-title-style_3:after {
  clear: both;
}

.shortcode_title.shortcode-title-style_3 .title_primary {
  float: left;
  text-transform: none;
}

.shortcode_title.shortcode-title-style_3 .title_subtitle {
  float: right;
  margin-top: 10px;
}

.swiper-slide {
  float: left;
}

.owl-item .tours_type_item {
  width: 100%;
  float: none;
}

.tours-type_item_container {
  display: block;
  width: 195px;
  height: 195px;
  margin: 0 auto;
  position: relative;
  border: 3px solid rgba(255, 255, 255, 0.85);
  border-radius: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tours-type_item_container .tours-type_content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 1.200em;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tours-type_item_container:hover {
  background: rgba(38, 189, 247, 0.5);
  text-decoration: none;
  border: 3px solid rgba(38, 189, 247, 0.7);
}

.title.text-white {
  color: #fff;
}

.shortcode-tour-reviews {
  padding: 35px 25px;
  background: var(--review-background);
}

.shortcode-tour-reviews .owl-dots {
  text-align: center;
}

.tour-reviews-item {
  margin-bottom: 10px;
  color: var(--review-text-color);
}

.tour-reviews-item .reviews-item-info {
  text-align: center;
  width: 100%;
  margin: 15px 0;
  overflow: hidden;
}

.tour-reviews-item .reviews-item-info .item_rating {
  width: 100%;
}

.tour-reviews-item .reviews-item-info img {
  border-radius: 100%;
  border: 6px solid #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
}

.tour-reviews-item .reviews-item-info .reviews-item-info-name {
  font-size: 16px;
  padding-top: 15px;
}

.tour-reviews-item .reviews-item-info .star-rating {
  float: none;
  margin: 5px auto;
  color: #ffcd47;
}

.tour-reviews-item .reviews-item-info .star-rating:before {
  color: #ffcd47;
}

.tour-reviews-item .reviews-item-info .reviews-item-rating {
  margin-top: 5px;
  color: #ffcd47;
  font-size: 1.200em;
}

.tour-reviews-item .reviews-item-content {
  width: 100%;
  text-align: center;
}

.tour-reviews-item .reviews-item-content .reviews-item-title {
  margin: 10px 0;
  font-size: 1.4em;
}

.tour-reviews-item .reviews-item-content .reviews-item-title a {
  color: #fff;
}

.tour-reviews-item .reviews-item-content .reviews-item-title a:hover {
  color: #26BDF7;
}

.tour-reviews-item:last-child {
  border-bottom: none;
}

.tours-type-slider .tours-type-slider {
  width: 25%;
  float: left;
}

.tours-type-slider .owl-item .tours-type-slider {
  width: 100%;
}

.addthis_sharing_toolbox {
  margin-top: 10px;
  border: 2px solid #ddd;
  padding-top: 5px;
}

.post_list_content_unit {
  margin-bottom: 20px;
}

.post_list_content_unit.col-sm-6:nth-child(2n+1) {
  clear: both;
}

.post_list_content_unit .feature-image {
  margin-bottom: 20px;
  text-align: center;
}

.post_list_content_unit .post-list-content {
  *zoom: 1;
}

.post_list_content_unit .post-list-content:before, .post_list_content_unit .post-list-content:after {
  content: "";
  display: table;
}

.post_list_content_unit .post-list-content:after {
  clear: both;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit {
  width: 100%;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .post_list_title {
  margin-bottom: 8px;
  margin-top: 0;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .post_list_title a {
  color: #555;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .post_list_title a:hover {
  color: var(--main-color);
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .wrapper-meta {
  color: #aaa;
  font-size: 12px;
  margin-bottom: 10px;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .wrapper-meta .date-time {
  margin-right: 15px;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .wrapper-meta .date-time:before {
  content: "\f073";
  font: normal normal normal 14px/1 FontAwesome;
  margin-right: 5px;
  display: inline-block;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .wrapper-meta .post_list_cats:before {
  content: "\f02c";
  font: normal normal normal 14px/1 FontAwesome;
  margin-right: 5px;
  display: inline-block;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .wrapper-meta .date-time, .post_list_content_unit .post-list-content .post_list_inner_content_unit .wrapper-meta .post_list_cats {
  display: inline-block;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .post_list_cats a {
  color: #aaa;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .post_list_cats a:hover {
  color: #26BDF7;
}

.stats_counter {
  color: #fff;
}

.stats_counter .wrapper-icon {
  height: 86px;
}

.stats_counter .wrapper-icon i {
  display: inline-block;
  color: inherit;
}

.stats_counter .wrapper-icon i:before {
  font-size: 70px;
  margin: 0;
  line-height: 1;
}

.stats_counter .stats_counter_number {
  font-size: 55px;
  line-height: 60px;
}

.stats_counter .stats_counter_title {
  font-size: 14px;
  color: inherit;
  text-transform: uppercase;
}

.bg__shadow {
  position: relative;
}

.bg__shadow:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  display: inline-block;
}

.bg__shadow .container {
  position: relative;
}

.discounts-tour {
  text-align: center;
}

.discounts-tour .line {
  position: relative;
  width: 50px;
  height: 3px;
  margin: 10px auto 20px auto;
  background: #fff;
  display: inline-block;
}

.discounts-tour .line:after {
  content: "";
  position: absolute;
  top: -4px;
  left: 50%;
  margin-left: -4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.discounts-tour .discounts-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 55px;
  color: #fff;
  margin-top: -6px;
}

.discounts-tour .discounts-title span {
  font-size: 40px;
  color: var(--discount-text-color);
  padding: 2px 5px;
}

.discounts-tour p {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.counter-group {
  margin: 30px auto;
  display: inline-block;
}

.counter-group {
  *zoom: 1;
}

.counter-group:before, .counter-group:after {
  content: "";
  display: table;
}

.counter-group:after {
  clear: both;
}

.counter-block {
  float: left;
  margin-right: 20px;
}

.counter-block .counter {
  position: relative;
  width: 120px;
  height: 80px;
  overflow: hidden;
}

.counter-block .counter .number {
  padding: 1%;
  width: 46%;
  height: 98%;
  box-shadow: 0 2px 0 0 #c6a615;
  color: var(--counter-text-color);
  position: absolute;
  font-size: 50px;
  line-height: 77px;
  border-radius: 3px;
  top: -10000px;
}

.counter-block .counter .number.tens {
  left: 0;
}

.counter-block .counter .number.units {
  left: 50%;
}

.counter-block .counter .number.hundreds {
  display: none;
}

.counter-block .counter .number.show {
  top: 0;
  z-index: 2;
}

.counter-block .counter .number.hidden-down {
  top: 100%;
}

.counter-block .counter .number.hidden-up {
  top: -100%;
  z-index: 100;
}

.counter-block .counter.with-hundreds {
  width: 300px;
}

.counter-block .counter.with-hundreds .number {
  width: 30.66%;
}

.counter-block .counter.with-hundreds .number.tens {
  left: 33.33%;
}

.counter-block .counter.with-hundreds .number.units {
  left: 66.66%;
}

.counter-block .counter.with-hundreds .number.hundreds {
  display: block;
  left: 0;
}

.counter-block .counter-caption {
  color: #fff;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
}

.wrapper-special-tours .inner-special-tours {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.wrapper-special-tours .inner-special-tours > a {
  display: block;
  position: relative;
}

.wrapper-special-tours .inner-special-tours > a:focus, .wrapper-special-tours .inner-special-tours > a:visited {
  outline: none;
}

.wrapper-special-tours .inner-special-tours > a .onsale {
  background-color: #14f545;
  top: 15px;
  right: -70px;
  width: 200px;
  height: 35px;
  line-height: 35px;
  position: absolute;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #fefefe;
  text-align: center;
  left: auto;
}

.wrapper-special-tours .inner-special-tours:last-child {
  margin-bottom: 0;
}

.wrapper-special-tours .inner-special-tours .item_price {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
}

.wrapper-special-tours .inner-special-tours .item_price .price {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 3px 0 0 0;
  color: #fefefe;
  font-size: 1.333em;
}

.wrapper-special-tours .inner-special-tours .post_title {
  margin: 0;
  position: absolute;
  top: 55%;
  left: 20px;
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.wrapper-special-tours .inner-special-tours .post_title a {
  color: #fff;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.wrapper-special-tours .inner-special-tours .item_rating {
  font-size: 1.200em;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0;
  width: auto;
}

.tour-reviews-items {
  border-top: 1px solid #e9e9e9;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.tour-reviews-form {
  border-top: 1px solid #e9e9e9;
  padding-top: 30px;
}

.tour-reviews-item {
  border-bottom: 1px dotted #e9e9e9;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.tour-reviews-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.tour-reviews {
  padding-top: 25px;
}

.tour-reviews-item-container {
  overflow: hidden;
}

.tour-reviews-item-info {
  margin-right: 20px;
  float: left;
  text-align: center;
}

.tour-reviews-item-info img {
  margin-bottom: 15px;
  border-radius: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
  border: 6px solid #fff;
}

.tour-reviews-author {
  font-size: 1.133em;
}

.tour-reviews-item-content-top {
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.tour-reviews-item_rating {
  float: left;
  color: #ffcd47;
}

.tour-reviews-item_rating i {
  margin-right: 2px;
}

.tour-reviews-item-date {
  float: right;
  color: #c3c2c2;
}

.tour-reviews-form-title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 1.333em;
  font-weight: 700;
  font-style: normal;
}

.tour-reviews-form-group {
  position: relative;
}

.tour-reviews-form input, .tour-reviews-form textarea {
  background: #fbfbfb;
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.tour-reviews-form textarea {
  width: 500px;
  height: 100px;
  padding: 12px 15px;
}

.tour-reviews-form input[type="text"], .tour-reviews-form input[type="email"], .tour-reviews-form input[type="search"], .tour-reviews-form input[type="tel"], .tour-reviews-form input[type="number"], .tour-reviews-form input[type="password"], .tour-reviews-form input[type="date"] {
  display: block;
  width: 100%;
  height: 45px;
  line-height: 21px;
  margin-bottom: 10px;
  padding: 12px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.tour-reviews-form input[type="text"] {
  width: 350px;
}

.tour-reviews-form__rating {
  margin: 15px 0;
  font-size: 1.067em;
  overflow: hidden;
}

#respond input#submit {
  line-height: 38px;
  padding: 0 40px;
  border: none;
  color: #333;
  text-transform: uppercase;
  box-shadow: 0 2px 0 0 #c6a615;
  letter-spacing: -0.5px;
}

#respond input#submit:hover {
  color: #fff;
}

.tours .tb_tour .read_more {
  position: relative;
}

.tours .tb_tour .read_more .rating {
  bottom: 10px;
  left: 15px;
  right: auto;
}

.products.wrapper-tours-slider {
  margin: 0 -15px;
  padding: 0;
}

.products.wrapper-tours-slider {
  *zoom: 1;
}

.products.wrapper-tours-slider:before, .products.wrapper-tours-slider:after {
  content: "";
  display: table;
}

.products.wrapper-tours-slider:after {
  clear: both;
}

.wrapper-tours-slider .tours-type-slider {
  position: relative;
}

.wrapper-tours-slider .tours-type-slider .owl-nav .owl-prev, .wrapper-tours-slider .tours-type-slider .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  color: #fff;
  margin-top: -25px;
  opacity: 0.7;
}

.wrapper-tours-slider .tours-type-slider .owl-nav .owl-prev i, .wrapper-tours-slider .tours-type-slider .owl-nav .owl-next i {
  font-size: 50px;
}

.wrapper-tours-slider .tours-type-slider .owl-nav .owl-prev:hover, .wrapper-tours-slider .tours-type-slider .owl-nav .owl-next:hover {
  opacity: 1;
}

.wrapper-tours-slider .tours-type-slider .owl-nav .owl-prev {
  left: -50px;
}

.wrapper-tours-slider .tours-type-slider .owl-nav .owl-next {
  right: -50px;
}

.wrapper-tours-type-slider .tours-type-slider .owl-nav .owl-prev, .wrapper-tours-type-slider .tours-type-slider .owl-nav .owl-next {
  color: #333;
}

.form-block__title {
  text-align: center;
}

.form-block__title h4 {
  display: inline-block;
  border-bottom: 2px solid #E8E8E8;
  padding-bottom: 5px;
}

.hotel-booking-search #tourBookingForm input {
  padding: 7px 10px;
  margin-bottom: 0;
}

#tourBookingForm input {
  width: 100%;
  border: 1px solid #ddd;
  padding: 7px 0;
  margin-bottom: 20px;
}

#tourBookingForm.hb-search-form input {
  margin-bottom: 0;
}

#tourBookingForm input[type="number"] {
  margin-bottom: 0;
  border: 1px solid #ddd;
  padding: 7px 0 7px 15px;
}

#tourBookingForm .total_price {
  margin-bottom: 20px;
}

#tourBookingForm input[type="submit"] {
  border-radius: 0;
  padding: 12px 20px 9px 20px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

#tourBookingForm input[type="submit"]:focus {
  outline: none;
}

#payment input[type="submit"] {
  border-radius: 0;
  padding: 12px 20px 9px 20px;
  border: none;
  color: #fff;
  text-transform: uppercase;
}

#payment input[type="submit"]:focus {
  outline: none;
}

#tour-booking-cart table .cart-subtotal th, #tour-booking-cart table .order-total th, #tour-booking-payment table .cart-subtotal th, #tour-booking-payment table .order-total th {
  text-align: left;
}

#tour-booking-cart select, #tour-booking-payment select {
  width: 100%;
}

.item-list-tour .content-list-tour {
  width: 100%;
  min-height: 150px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  margin-bottom: 30px;
}

.item-list-tour .content-list-tour {
  *zoom: 1;
}

.item-list-tour .content-list-tour:before, .item-list-tour .content-list-tour:after {
  content: "";
  display: table;
}

.item-list-tour .content-list-tour:after {
  clear: both;
}

.item-list-tour .content-list-tour .post_images {
  width: 35%;
  padding: 0;
  position: relative;
  float: left;
  border-radius: 3px 0 0 3px;
  overflow: hidden;
}

.item-list-tour .content-list-tour .wrapper_content {
  width: 65%;
  float: left;
  padding: 25px 30px;
  position: relative;
}

.item-list-tour .content-list-tour .wrapper_content:before {
  content: "";
  width: 1px;
  background: #E8E8E8;
  position: absolute;
  right: 180px;
  top: 30px;
  bottom: 30px;
}

.item-list-tour .content-list-tour .wrapper_content .post_title h4 {
  margin-top: 0;
}

.item-list-tour .content-list-tour .wrapper_content .content-left {
  width: calc(100% - 154px);
  display: inline-block;
  vertical-align: middle;
  padding-right: 30px;
}

.item-list-tour .content-list-tour .wrapper_content .content-left .post_date {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 25px;
  padding: 5px 15px;
  background: #f6f6f6;
  border-right: none;
  border-radius: 3px;
}

.item-list-tour .content-list-tour .wrapper_content .content-left .post_date:before {
  content: "\e864";
  font: normal normal normal 16px/1 'Linearicons-Free';
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;
}

.item-list-tour .content-list-tour .wrapper_content .content-right {
  width: 150px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding-left: 30px;
}

.item-list-tour .content-list-tour .wrapper_content .content-right .item_rating {
  width: 100%;
  height: auto;
  float: none;
  line-height: 1;
  padding: 0;
  margin-bottom: 15px;
}

.item-list-tour .content-list-tour .wrapper_content .content-right .item_rating .star-rating {
  margin: 0 auto !important;
}

.item-list-tour .content-list-tour .wrapper_content .content-right .price {
  font-weight: bold;
}

.item-list-tour .content-list-tour .wrapper_content .content-right .price del {
  font-size: 90%;
  font-weight: normal;
}

.item-list-tour .content-list-tour .wrapper_content .content-right .add_to_cart_button {
  color: #fff;
  text-transform: uppercase;
  padding: 0 20px;
  line-height: 38px;
  border: none;
  border-radius: 0;
  margin-top: 5px;
  display: inline-block;
}

.travel_tour-page .tb_single_tour .images_single_left, .travel_tour .tb_single_tour .images_single_left {
  //width: 72%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  margin-bottom: 0 !important;
}

.travel_tour-page .tb_single_tour .images_single_left span.onsale, .travel_tour .tb_single_tour .images_single_left span.onsale {
  z-index: 9;
  left: auto;
  right: 15px;
}

.travel_tour-page .tb_single_tour .images_single_left .slides li, .travel_tour .tb_single_tour .images_single_left .slides li {
  display: none;
}

.travel_tour-page .tb_single_tour .images_single_left #slider li:first-child, .travel_tour .tb_single_tour .images_single_left #slider li:first-child {
  display: block;
}

.travel_tour-page .tb_single_tour .description_single, .travel_tour .tb_single_tour .description_single {
  //width: 28%;
}

.travel_tour-page .tb_single_tour .description_single .price .text, .travel_tour .tb_single_tour .description_single .price .text {
  font-size: 14px;
  margin-right: 5px;
  font-weight: normal;
}

.travel_tour-page .tb_single_tour .description_single .price span, .travel_tour .tb_single_tour .description_single .price span {
  color: #fff;
}

.travel_tour-page .tb_single_tour .description_single .price del, .travel_tour .tb_single_tour .description_single .price del {
  color: #fff;
  opacity: 1;
  font-size: 80%;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details, .travel_tour .tb_single_tour .tb_single_tour_details {
  margin-bottom: 30px;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs {
  margin: 0;
  padding: 0;
  border-bottom: 3px solid #26BDF7;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs:before, .travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li:before, .travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li:after, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs:before, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li:before, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li:after {
  display: none;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li {
  margin: 0 8px 0 0;
  display: inline-block;
  list-style: none;
  padding: 0;
  border: none;
  border-radius: 0;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a {
  display: inline-block;
  height: 50px;
  line-height: 51px;
  margin: 0;
  padding: 0 35px;
  color: #333;
  font-size: 0.933em;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  border: none;
  background: #f2f2f2;
  border-radius: 3px 3px 0 0;
  -webkit-transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
  transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a:after, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a:after {
  display: none;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a.active, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a.active {
  background: #26BDF7;
  color: #fff;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a:focus, .travel_tour .tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a:focus {
  outline: none;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content {
  padding: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
  border-radius: 0 0 3px 3px;
  border: 3px solid #26BDF7;
  border-top: none;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content {
  *zoom: 1;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content:before, .travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content:after, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content:before, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content:after {
  content: "";
  display: table;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content:after, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content:after {
  clear: both;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content .tb_single_tour_tab_details, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content .tb_single_tour_tab_details {
  *zoom: 1;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content .tb_single_tour_tab_details:before, .travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content .tb_single_tour_tab_details:after, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content .tb_single_tour_tab_details:before, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content .tb_single_tour_tab_details:after {
  content: "";
  display: table;
}

.travel_tour-page .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content .tb_single_tour_tab_details:after, .travel_tour .tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content .tb_single_tour_tab_details:after {
  clear: both;
}

.images_single_left .title-single {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 20px;
}

.images_single_left .title-single h1 {
  margin-top: 0;
}

.images_single_left .title-single {
  *zoom: 1;
}

.images_single_left .title-single:before, .images_single_left .title-single:after {
  content: "";
  display: table;
}

.images_single_left .title-single:after {
  clear: both;
}

.images_single_left .title-single .title {
  float: left;
}

.images_single_left .title-single .tour_code {
  float: right;
  margin-top: 7px;
}

.images_single_left .tour_after_title {
  margin-bottom: 25px;
}

.images_single_left .tour_after_title {
  *zoom: 1;
}

.images_single_left .tour_after_title:before, .images_single_left .tour_after_title:after {
  content: "";
  display: table;
}

.images_single_left .tour_after_title:after {
  clear: both;
}

.images_single_left .tour_after_title .meta_values {
  width: 30%;
  float: left;
  position: relative;
  padding-left: 35px;
}

.images_single_left .tour_after_title .meta_values:before {
  content: "\e82f";
  font: normal normal normal 26px/1 'Linearicons-Free';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -13px;
}

.images_single_left .tour_after_title .meta_values span {
  color: #aaa;
  font-size: 90%;
  line-height: 1;
}

.images_single_left .tour_after_title .meta_values .value {
  text-transform: uppercase;
  font-weight: bold;
}

.images_single_left .tour_after_title .meta_date {
  width: 40%;
  float: left;
  position: relative;
  padding-left: 35px;
  margin-top: 15px;
}

.images_single_left .tour_after_title .meta_date:before {
  content: "\e864";
  font: normal normal normal 24px/1 'Linearicons-Free';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -13px;
}

.images_single_left .tour_after_title .meta_date span {
  display: block;
  line-height: 1.6em;
  font-weight: bold;
}

.images_single_left .tour_after_title .meta_date span i {
  font-style: normal;
  color: #aaa;
  font-weight: normal;
  margin-right: 5px;
}

.images_single_left .tour_after_title .travel_tour-product-rating {
  width: 20%;
  float: right;
  text-align: right;
  margin-bottom: 0 !important;
}

.images_single_left .tour_after_title .travel_tour-product-rating .star-rating {
  float: right !important;
  margin-right: 0;
}

.images_single_left .tour_after_title .travel_tour-product-rating .star-rating:before {
  color: #ffb300;
}

.images_single_left .tour_after_title .travel_tour-product-rating .travel_tour-review-link {
  float: right;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
}

.images_single_left .tour_after_title .tour-share {
  width: 30%;
  float: right;
  text-align: right;
  margin-bottom: 0 !important;
  margin-top: 9px;
}

.images_single_left .tour_after_title .tour-share ul {
  margin: 0 -2px;
}

.images_single_left .tour_after_title .tour-share ul li {
  display: inline-block;
  list-style: none;
  margin: 0 2px;
}

.images_single_left .tour_after_title .tour-share ul li a {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
}

.images_single_left .tour_after_title .tour-share ul li a:focus, .images_single_left .tour_after_title .tour-share ul li a:visited {
  outline: none;
}

.images_single_left .tour_after_title .tour-share ul li a.facebook {
  background: #3b5999;
}

.images_single_left .tour_after_title .tour-share ul li a.twitter {
  background: #55acee;
}

.images_single_left .tour_after_title .tour-share ul li a.pinterest {
  background: #bd081c;
}

.images_single_left .tour_after_title .tour-share ul li a.googleplus {
  background: #dd4b39;
}

.images_single_left .tour_after_title .tour-share ul li a:hover {
  opacity: 0.8;
}

/****tour grid style 1****/
.content_tour_style_2 .item_border .post_images .group-icon {
  right: auto;
  left: 0;
}

.content_tour_style_2 .item_border .post_images span.onsale {
  right: -7px !important;
}

.content_tour_style_2 .item_border .post_images span.onsale:after {
  content: "";
  width: 0;
  height: 0;
  border-right: 7px solid transparent;
  position: absolute;
  bottom: -7px;
  right: 0;
}

.content_tour_style_2 .item_border .item_content .wrapper_content {
  padding: 15px 20px;
}

.content_tour_style_2 .item_border .post_title {
  position: relative;
  min-height: 44px;
  margin-bottom: 10px;
}

.content_tour_style_2 .item_border .post_title h5 {
  line-height: 1.4em;
  padding-right: 80px;
  margin: 0;
}

.content_tour_style_2 .item_border .post_title .price {
  text-align: right;
  position: absolute;
  top: 5px;
  right: 0;
}

.content_tour_style_2 .item_border .post_title .price del, .content_tour_style_2 .item_border .post_title .price .text {
  display: block;
  font-size: 1em;
  color: #a2a2a2;
  line-height: 1;
}

.content_tour_style_2 .item_border .post_title .price del .woocommerce-Price-amount, .content_tour_style_2 .item_border .post_title .price .text .woocommerce-Price-amount {
  font-size: 1em;
  color: #a2a2a2;
}

.content_tour_style_2 .item_border .post_title .price .woocommerce-Price-amount {
  font-size: 1.5em;
}

.content_tour_style_2 .item_border .bg-green {
  position: absolute;
  width: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  min-height: 40px;
}

.content_tour_style_2 .item_border .bg-green .group-icon {
  background: transparent;
}

.content_tour_style_2 .item_border .read_more {
  background: transparent;
  margin: 0 20px;
  border-top: 1px solid #E8E8E8;
  height: auto;
}

.content_tour_style_2 .item_border .read_more {
  *zoom: 1;
}

.content_tour_style_2 .item_border .read_more:before, .content_tour_style_2 .item_border .read_more:after {
  content: "";
  display: table;
}

.content_tour_style_2 .item_border .read_more:after {
  clear: both;
}

.content_tour_style_2 .item_border .read_more .post_date {
  margin-bottom: 0;
  padding: 12px 0;
  float: left;
}

.content_tour_style_2 .item_border .read_more .item_rating {
  float: right;
  padding: 0;
  width: auto;
  margin-top: 5px;
}

.cart_item .label {
  color: initial;
  font-size: 100%;
  font-weight: normal;
  text-align: left;
  display: inline-block;
  padding: 0 10px 0 0;
  min-width: 60px;
}

.cart_item .product-quantity .st_adults {
  margin-bottom: 10px;
}

.cart_item .product-quantity .st_children input, .cart_item .product-quantity .st_adults input {
  border: 1px solid #ddd;
  width: 60px;
  padding: 5px;
  text-align: center;
}

.single-woo-tour .single-tour-tabs {
  padding-top: 30px;
}

.single-woo-tour .single-tour-tabs li a:focus, .single-woo-tour .single-tour-tabs li a:active, .single-woo-tour .single-tour-tabs li a:visited {
  outline: none;
}

.single-woo-tour .description_single .custom-form-title {
  margin-top: 25px;
}

.single-woo-tour .description_single .custom_from {
  padding-top: 20px;
}

.single-woo-tour .description_single .custom_from p:last-child {
  margin-bottom: 0;
}

.single-woo-tour .description_single .custom_from input[type="submit"] {
  margin-bottom: 0 !important;
  width: 100%;
  text-align: center;
}

.single-woo-tour .description_single .custom_from div.wpcf7 .ajax-loader {
  position: absolute;
}

.single-woo-tour .description_single .custom_from div.wpcf7-response-output {
  margin: 0;
}

.single-woo-tour .description_single .booking, .single-woo-tour .description_single .another_from {
  padding-top: 20px;
  margin-top: 21px;
}

.single-woo-tour .description_single .booking, .single-woo-tour .description_single .another_from {
  *zoom: 1;
}

.single-woo-tour .description_single .booking:before, .single-woo-tour .description_single .booking:after, .single-woo-tour .description_single .another_from:before, .single-woo-tour .description_single .another_from:after {
  content: "";
  display: table;
}

.single-woo-tour .description_single .booking:after, .single-woo-tour .description_single .another_from:after {
  clear: both;
}

.single-woo-tour .description_single .booking input[type="submit"], .single-woo-tour .description_single .another_from input[type="submit"] {
  margin-bottom: 0 !important;
}

.single-woo-tour .description_single .widget-area {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.single-woo-tour .description_single .entry-content-tour {
  border: 1px solid #E8E8E8;
  padding: 20px;
  z-index: 2;
  margin-bottom: 30px;
  border-radius: 2px;
}

.single-woo-tour .description_single .entry-content-tour p.price {
  margin: -21px;
  padding: 15px 20px;
  border-radius: 3px 3px 0 0;
}

.single-woo-tour .description_single .affix-sidebar {
  top: -116px;
  position: relative;
}

.single-woo-tour .description_single .affix-sidebar {
  *zoom: 1;
}

.single-woo-tour .description_single .affix-sidebar:before, .single-woo-tour .description_single .affix-sidebar:after {
  content: "";
  display: table;
}

.single-woo-tour .description_single .affix-sidebar:after {
  clear: both;
}

.single-woo-tour .description_single .affix-sidebar.affix {
  top: 0;
  position: fixed;
  width: 297px;
}

.single-woo-tour .description_single #tourBookingForm input[type="text"] {
  border: none;
  border-bottom: 2px solid #E8E8E8;
  border-radius: 0;
  background: transparent;
}

.single-woo-tour .description_single #tourBookingForm input[type="text"]:focus {
  outline: none;
  border-color: #039be5;
}

.single-woo-tour .description_single #tourBookingForm .from-group {
  margin-bottom: 20px;
}

.single-woo-tour .description_single #tourBookingForm .from-group {
  *zoom: 1;
}

.single-woo-tour .description_single #tourBookingForm .from-group:before, .single-woo-tour .description_single #tourBookingForm .from-group:after {
  content: "";
  display: table;
}

.single-woo-tour .description_single #tourBookingForm .from-group:after {
  clear: both;
}

.single-woo-tour .description_single #tourBookingForm .from-group .input-number-ticket {
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.single-woo-tour .description_single #tourBookingForm .from-group .input-number-ticket input:focus {
  outline: none;
}

.single-woo-tour .description_single #tourBookingForm .from-group .total_price_arrow .label {
  color: initial;
  font-size: 100%;
  font-weight: normal;
  text-align: left;
  min-width: 62px;
  display: inline-block;
  padding: 0 10px 0 0;
}

.related.tours {
  margin-top: 30px;
}

.related.tours h2 {
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
}

.related.tours h2:before {
  position: absolute;
  //left: 0;
  width: 35px;
  height: 2px;
  content: "";
  bottom: 0;
}

.interary-item {
  position: relative;
  margin-bottom: 30px;
  min-height: 60px;
}

.interary-item:last-child {
  margin-bottom: 0;
}

.interary-item .icon-left {
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 56px;
  text-align: center;
  border-radius: 50%;
  font-size: 24px;
}

.interary-item .item_content {
  padding-left: 85px;
  overflow: hidden;
}

.interary-item .item_content ul, .interary-item .item_content ol {
  margin: 0 0 1.5em 2em;
}

.interary-item .item_content h2, .interary-item .item_content h3 {
  margin-top: 17px;
  margin-bottom: 15px;
}

.widget-area .search_tour {
  text-align: center;
  border-radius: 3px;
  padding: 20px;
  color: #fff;
}

.widget-area .search_tour {
  *zoom: 1;
}

.widget-area .search_tour:before, .widget-area .search_tour:after {
  content: "";
  display: table;
}

.widget-area .search_tour:after {
  clear: both;
}

.widget-area .search_tour .form-block_title {
  margin-top: 5px;
  color: #fff;
  margin-bottom: 5px;
  font-weight: 700;
}

.widget-area .search_tour .form-block__description {
  margin-bottom: 20px;
}

.widget-area .search_tour input, .widget-area .search_tour select, .widget-area .search_tour button {
  width: 100%;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  border: none;
  border-radius: 3px;
  height: 45px;
  line-height: 21px;
  padding: 12px 15px;
}

.widget-area .search_tour input:focus, .widget-area .search_tour select:focus, .widget-area .search_tour button:focus {
  background: #fff;
  outline: none;
}

.widget-area .search_tour button {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
}

.travel_tour .star-rating span, .travel_tour-page .star-rating span {
  color: #ffb300;
}

.travel_tour .star-rating:before, .travel_tour-page .star-rating:before {
  color: #ffb300;
}

.travel_tour ul.products.wrapper-tours-slider, .travel_tour-page ul.products.wrapper-tours-slider {
  //margin: 0 -15px;
  margin: 0;
}

.travel_tour ul.products.wrapper-tours-slider .item-tour, .travel_tour-page ul.products.wrapper-tours-slider .item-tour {
  margin-bottom: 30px;
}

.travel_tour ul.products.wrapper-tours-slider li.product a img, .travel_tour-page ul.products.wrapper-tours-slider li.product a img {
  display: inline-block;
  margin: 0;
}

.travel_tour ul.products.wrapper-tours-slider li.product .post_images span.price, .travel_tour-page ul.products.wrapper-tours-slider li.product .post_images span.price {
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.travel_tour ul.products.wrapper-tours-slider li.product .post_images span.price del, .travel_tour-page ul.products.wrapper-tours-slider li.product .post_images span.price del {
  font-size: 100%;
  opacity: 1;
  display: inline-block;
}

.travel_tour ul.products.wrapper-tours-slider li.product .star-rating, .travel_tour-page ul.products.wrapper-tours-slider li.product .star-rating {
  font-size: 1em;
}

.travel_tour div.product form.cart, .travel_tour-page div.product form.cart {
  margin-top: 20px;
}

.travel_tour .travel_tour-result-count, .travel_tour-page .travel_tour-result-count {
  margin-top: 15px;
  float: left;
}

.travel_tour .travel_tour-ordering, .travel_tour-page .travel_tour-ordering {
  margin-bottom: 25px;
  float: right;
}

.travel_tour .travel_tour-ordering select, .travel_tour-page .travel_tour-ordering select {
  padding: 6px 10px;
  vertical-align: top;
  outline: 0;
  position: relative;
  width: 100%;
  height: 40px;
  color: #333;
  border-radius: 0;
  line-height: 24px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -ms-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.travel_tour-MyAccount-navigation ul {
  margin-left: 25px;
}

.travel_tour, .travel_tour-page {
}

.travel_tour .col2-set, .travel_tour-page .col2-set {
  width: 100%;
}

.travel_tour .col2-set, .travel_tour-page .col2-set {
  *zoom: 1;
}

.travel_tour .col2-set:before, .travel_tour .col2-set:after, .travel_tour-page .col2-set:before, .travel_tour-page .col2-set:after {
  content: "";
  display: table;
}

.travel_tour .col2-set:after, .travel_tour-page .col2-set:after {
  clear: both;
}

.travel_tour .col2-set .col-1, .travel_tour-page .col2-set .col-1 {
  float: left;
  width: 48%;
}

.travel_tour .col2-set .col-2, .travel_tour-page .col2-set .col-2 {
  float: right;
  width: 48%;
}

.travel_tour img, .travel_tour-page img {
  height: auto;
  max-width: 100%;
}

.travel_tour div.product .single-tour-tabs, .travel_tour #content div.product .single-tour-tabs, .travel_tour-page div.product .single-tour-tabs, .travel_tour-page #content div.product .single-tour-tabs {
  clear: both;
}

.travel_tour .travel_tour-result-count, .travel_tour-page .travel_tour-result-count {
  float: left;
}

.travel_tour .travel_tour-ordering, .travel_tour-page .travel_tour-ordering {
  float: right;
}

.travel_tour table.cart img, .travel_tour #content table.cart img, .travel_tour-page table.cart img, .travel_tour-page #content table.cart img {
  height: auto;
}

.travel_tour table.cart td.actions, .travel_tour #content table.cart td.actions, .travel_tour-page table.cart td.actions, .travel_tour-page #content table.cart td.actions {
  text-align: right;
}

.travel_tour table.cart td.actions .input-text, .travel_tour #content table.cart td.actions .input-text, .travel_tour-page table.cart td.actions .input-text, .travel_tour-page #content table.cart td.actions .input-text {
  width: 80px;
}

.travel_tour table.cart td.actions .coupon, .travel_tour #content table.cart td.actions .coupon, .travel_tour-page table.cart td.actions .coupon, .travel_tour-page #content table.cart td.actions .coupon {
  float: left;
}

.travel_tour table.cart td.actions .coupon label, .travel_tour #content table.cart td.actions .coupon label, .travel_tour-page table.cart td.actions .coupon label, .travel_tour-page #content table.cart td.actions .coupon label {
  display: none;
}

.travel_tour .cart-collaterals, .travel_tour-page .cart-collaterals {
  width: 100%;
}

.travel_tour .cart-collaterals, .travel_tour-page .cart-collaterals {
  *zoom: 1;
}

.travel_tour .cart-collaterals:before, .travel_tour .cart-collaterals:after, .travel_tour-page .cart-collaterals:before, .travel_tour-page .cart-collaterals:after {
  content: "";
  display: table;
}

.travel_tour .cart-collaterals:after, .travel_tour-page .cart-collaterals:after {
  clear: both;
}

.travel_tour .cart-collaterals .related, .travel_tour-page .cart-collaterals .related {
  width: 30.75%;
  float: left;
}

.travel_tour .cart-collaterals .cross-sells, .travel_tour-page .cart-collaterals .cross-sells {
  width: 48%;
  float: left;
}

.travel_tour .cart-collaterals .cross-sells ul.products, .travel_tour-page .cart-collaterals .cross-sells ul.products {
  float: none;
}

.travel_tour .cart-collaterals .cross-sells ul.products li, .travel_tour-page .cart-collaterals .cross-sells ul.products li {
  width: 48%;
}

.travel_tour .cart-collaterals .shipping_calculator, .travel_tour-page .cart-collaterals .shipping_calculator {
  width: 48%;
  clear: right;
  float: right;
}

.travel_tour .cart-collaterals .shipping_calculator, .travel_tour-page .cart-collaterals .shipping_calculator {
  *zoom: 1;
}

.travel_tour .cart-collaterals .shipping_calculator:before, .travel_tour .cart-collaterals .shipping_calculator:after, .travel_tour-page .cart-collaterals .shipping_calculator:before, .travel_tour-page .cart-collaterals .shipping_calculator:after {
  content: "";
  display: table;
}

.travel_tour .cart-collaterals .shipping_calculator:after, .travel_tour-page .cart-collaterals .shipping_calculator:after {
  clear: both;
}

.travel_tour .cart-collaterals .shipping_calculator .col2-set .col-1, .travel_tour .cart-collaterals .shipping_calculator .col2-set .col-2, .travel_tour-page .cart-collaterals .shipping_calculator .col2-set .col-1, .travel_tour-page .cart-collaterals .shipping_calculator .col2-set .col-2 {
  width: 47%;
}

.travel_tour .cart-collaterals .cart_totals, .travel_tour-page .cart-collaterals .cart_totals {
  float: right;
  width: 48%;
}

.travel_tour form .form-row, .travel_tour-page form .form-row {
  *zoom: 1;
}

.travel_tour form .form-row:before, .travel_tour form .form-row:after, .travel_tour-page form .form-row:before, .travel_tour-page form .form-row:after {
  content: "";
  display: table;
}

.travel_tour form .form-row:after, .travel_tour-page form .form-row:after {
  clear: both;
}

.travel_tour form .form-row label, .travel_tour-page form .form-row label {
  display: block;
}

.travel_tour form .form-row label.checkbox, .travel_tour-page form .form-row label.checkbox {
  display: inline;
}

.travel_tour form .form-row select, .travel_tour-page form .form-row select {
  width: 100%;
}

.travel_tour form .form-row .input-text, .travel_tour-page form .form-row .input-text {
  box-sizing: border-box;
  width: 100%;
}

.travel_tour form .form-row-first, .travel_tour form .form-row-last, .travel_tour-page form .form-row-first, .travel_tour-page form .form-row-last {
  float: left;
  width: 47%;
  overflow: visible;
}

.travel_tour form .form-row-last, .travel_tour-page form .form-row-last {
  float: right;
}

.travel_tour form .form-row-wide, .travel_tour-page form .form-row-wide {
  clear: both;
}

.travel_tour #payment .form-row select, .travel_tour-page #payment .form-row select {
  width: auto;
}

.travel_tour #payment .wc-terms-and-conditions, .travel_tour #payment .terms, .travel_tour-page #payment .wc-terms-and-conditions, .travel_tour-page #payment .terms {
  text-align: left;
  padding: 0 1em 0 0;
  float: left;
}

.travel_tour #payment #place_order, .travel_tour-page #payment #place_order {
  float: right;
}

.travel_tour-account .travel_tour-MyAccount-navigation {
  float: left;
  width: 30%;
}

.travel_tour-account .travel_tour-MyAccount-content {
  float: right;
  width: 68%;
}

.travel_tour span.onsale, .travel_tour-page span.onsale {
  position: absolute;
  top: 14px;
  right: 0;
  padding: 3px 12px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  border-radius: 3px 0 0 3px;
  text-transform: uppercase;
  margin: 0;
  min-height: inherit;
  line-height: 26px;
  display: inline-block;
}

.travel_tour ul.products li.product.col-md-6:nth-child(2n+1), .travel_tour-page ul.products li.product.col-md-6:nth-child(2n+1) {
  clear: both;
}

.travel_tour ul.products li.product.col-md-4:nth-child(3n+1), .travel_tour-page ul.products li.product.col-md-4:nth-child(3n+1) {
  clear: both;
}

.travel_tour ul.products li.product.col-md-3:nth-child(4n+1), .travel_tour-page ul.products li.product.col-md-3:nth-child(4n+1) {
  clear: both;
}

.travel_tour ul.products li.product span.onsale, .travel_tour-page ul.products li.product span.onsale {
  top: 14px;
  margin: 0;
}

.travel_tour ul.products li.product .item-product .wrapper_content, .travel_tour-page ul.products li.product .item-product .wrapper_content {
  padding: 10px 15px 15px;
  text-align: center;
}

.travel_tour ul.products li.product .item-product .wrapper_content, .travel_tour-page ul.products li.product .item-product .wrapper_content {
  *zoom: 1;
}

.travel_tour ul.products li.product .item-product .wrapper_content:before, .travel_tour ul.products li.product .item-product .wrapper_content:after, .travel_tour-page ul.products li.product .item-product .wrapper_content:before, .travel_tour-page ul.products li.product .item-product .wrapper_content:after {
  content: "";
  display: table;
}

.travel_tour ul.products li.product .item-product .wrapper_content:after, .travel_tour-page ul.products li.product .item-product .wrapper_content:after {
  clear: both;
}

.travel_tour ul.products li.product .item-product .wrapper_content .post_title, .travel_tour ul.products li.product .item-product .wrapper_content .price, .travel_tour ul.products li.product .item-product .wrapper_content .description, .travel_tour-page ul.products li.product .item-product .wrapper_content .post_title, .travel_tour-page ul.products li.product .item-product .wrapper_content .price, .travel_tour-page ul.products li.product .item-product .wrapper_content .description {
  text-align: left;
}

.travel_tour ul.products li.product .item-product .wrapper_content .button, .travel_tour ul.products li.product .item-product .wrapper_content button, .travel_tour-page ul.products li.product .item-product .wrapper_content .button, .travel_tour-page ul.products li.product .item-product .wrapper_content button {
  border-radius: 0;
  padding: 14px 20px 11px 20px;
  color: #fff;
  text-transform: uppercase;
}

.travel_tour .wc-backward, .travel_tour-page .wc-backward {
  border-radius: 0;
  padding: 14px 20px 11px 20px;
  color: #fff;
  text-transform: uppercase;
}

.post_images span.onsale {
  position: absolute;
  top: 14px;
  right: 0;
  padding: 3px 12px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  border-radius: 3px 0 0 3px;
  text-transform: uppercase;
  margin: 0;
  min-height: initial;
  line-height: 26px;
  display: inline-block;
}

.travel_tour .images_single_left, .travel_tour-page .images_single_left {
  //width: 61%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}

.travel_tour .images_single_left span.onsale, .travel_tour-page .images_single_left span.onsale {
  z-index: 9;
  left: auto;
  right: 15px;
}

.travel_tour .images_single_left .slides li, .travel_tour-page .images_single_left .slides li {
  display: none;
}

.travel_tour .images_single_left #slider li:first-child, .travel_tour-page .images_single_left #slider li:first-child {
  display: block;
}

.travel_tour div.product p.stock, .travel_tour-page div.product p.stock {
  margin-top: 10px;
  margin-bottom: 2px;
  font-size: 1em;
}

.travel_tour div.product form.cart .button, .travel_tour div.product form.cart button, .travel_tour-page div.product form.cart .button, .travel_tour-page div.product form.cart button {
  border-radius: 0;
  padding: 11px 15px 10px 15px;
  color: #fff;
  text-transform: uppercase;
}

.travel_tour div.product form.cart .quantity input, .travel_tour-page div.product form.cart .quantity input {
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px solid #E8E8E8;
}

.travel_tour div.product form.cart .variations .label, .travel_tour-page div.product form.cart .variations .label {
  color: inherit;
  vertical-align: middle;
  font-weight: normal;
  font-size: 100%;
}

.travel_tour div.product form.cart .variations .label label, .travel_tour-page div.product form.cart .variations .label label {
  font-weight: normal;
  margin-top: 5px;
}

.travel_tour div.product form.cart .variations .value select, .travel_tour-page div.product form.cart .variations .value select {
  border: 1px solid #E8E8E8;
  padding: 7px 10px;
}

.travel_tour div.product .product_meta > span, .travel_tour-page div.product .product_meta > span {
  display: block;
  font-weight: 700;
}

.travel_tour div.product .product_meta > span span, .travel_tour div.product .product_meta > span a, .travel_tour-page div.product .product_meta > span span, .travel_tour-page div.product .product_meta > span a {
  font-weight: normal;
}

.travel_tour div.product p.price, .travel_tour-page div.product p.price {
  font-size: 1.6em;
  font-weight: bold;
  color: #333;
}

.travel_tour div.product p.price del, .travel_tour-page div.product p.price del {
  font-weight: normal;
}

.travel_tour div.product p.price del, .travel_tour div.product p.price ins, .travel_tour-page div.product p.price del, .travel_tour-page div.product p.price ins {
  display: inline-block;
}

.travel_tour div.product .single-tour-tabs ul.tabs, .travel_tour-page div.product .single-tour-tabs ul.tabs {
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #26BDF7;
  margin-bottom: -2px;
}

.travel_tour div.product .single-tour-tabs ul.tabs:before, .travel_tour div.product .single-tour-tabs ul.tabs li:before, .travel_tour div.product .single-tour-tabs ul.tabs li:after, .travel_tour-page div.product .single-tour-tabs ul.tabs:before, .travel_tour-page div.product .single-tour-tabs ul.tabs li:before, .travel_tour-page div.product .single-tour-tabs ul.tabs li:after {
  display: none;
}

.travel_tour div.product .single-tour-tabs ul.tabs li, .travel_tour-page div.product .single-tour-tabs ul.tabs li {
  margin: 0 8px 0 0;
  display: inline-block;
  list-style: none;
  padding: 0;
  border: none;
  border-radius: 0;
}

.travel_tour div.product .single-tour-tabs ul.tabs li a, .travel_tour-page div.product .single-tour-tabs ul.tabs li a {
  display: inline-block;
  height: 50px;
  line-height: 51px;
  margin: 0;
  padding: 0 35px;
  color: #333;
  font-size: 0.933em;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  border: none;
  background: #f2f2f2;
  border-radius: 3px 3px 0 0;
  -webkit-transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
  transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
}


.travel_tour div.product .single-tour-tabs ul.tabs li a.active,
.travel_tour-page div.product .single-tour-tabs ul.tabs li a.active {
  background: var(--card-header-background) !important;
  color: var(--card-text-color);
}

.travel_tour div.product .single-tour-tabs .entry-content, .travel_tour-page div.product .single-tour-tabs .entry-content {
  padding: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
  background: transparent;
  border-radius: 0 0 3px 3px;
  border: 2px solid #26BDF7;
  border-top: none;
  margin-bottom: 0;
}

.travel_tour div.product .single-tour-tabs .entry-content, .travel_tour-page div.product .single-tour-tabs .entry-content {
  *zoom: 1;
}

.travel_tour div.product .single-tour-tabs .entry-content:before, .travel_tour div.product .single-tour-tabs .entry-content:after, .travel_tour-page div.product .single-tour-tabs .entry-content:before, .travel_tour-page div.product .single-tour-tabs .entry-content:after {
  content: "";
  display: table;
}

.travel_tour div.product .single-tour-tabs .entry-content:after, .travel_tour-page div.product .single-tour-tabs .entry-content:after {
  clear: both;
}

.travel_tour div.product .single-tour-tabs .entry-content .wpb_wrapper, .travel_tour-page div.product .single-tour-tabs .entry-content .wpb_wrapper {
  *zoom: 1;
}

.travel_tour div.product .single-tour-tabs .entry-content .wpb_wrapper:before, .travel_tour div.product .single-tour-tabs .entry-content .wpb_wrapper:after, .travel_tour-page div.product .single-tour-tabs .entry-content .wpb_wrapper:before, .travel_tour-page div.product .single-tour-tabs .entry-content .wpb_wrapper:after {
  content: "";
  display: table;
}

.travel_tour div.product .single-tour-tabs .entry-content .wpb_wrapper:after, .travel_tour-page div.product .single-tour-tabs .entry-content .wpb_wrapper:after {
  clear: both;
}

.travel_tour div.product .wrap_tab_itinerary, .travel_tour-page div.product .wrap_tab_itinerary {
  margin-bottom: 20px;
}

.travel_tour div.product .wrap_tab_itinerary:last-child, .travel_tour-page div.product .wrap_tab_itinerary:last-child {
  margin-bottom: 0;
}

.travel_tour div.product #reviews:focus, .travel_tour-page div.product #reviews:focus {
  outline: none;
}

.travel_tour div.product #review_form_wrapper, .travel_tour-page div.product #review_form_wrapper {
  margin-top: 20px;
}

.travel_tour #review_form #respond .form-submit input, .travel_tour-page #review_form #respond .form-submit input {
  margin-top: 20px;
  border-radius: 0;
  /*padding:        15px 30px 11px 30px;*/
  border: none;
  color: #333;
  text-transform: uppercase;
  box-shadow: 0 2px 0 0 #c6a615;
  letter-spacing: -0.5px;
}

.travel_tour #review_form #respond .form-submit input:focus, .travel_tour-page #review_form #respond .form-submit input:focus {
  outline: none;
}

.travel_tour #review_form #respond .form-submit input:hover, .travel_tour-page #review_form #respond .form-submit input:hover {
  color: #fff;
}

.travel_tour .related.products h2, .travel_tour .up-sells h2, .travel_tour-page .related.products h2, .travel_tour-page .up-sells h2 {
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
}

.travel_tour .related.products h2:before, .travel_tour .up-sells h2:before, .travel_tour-page .related.products h2:before, .travel_tour-page .up-sells h2:before {
  position: absolute;
  left: 0;
  width: 35px;
  height: 2px;
  content: "";
  bottom: 0;
}

.post_description .checklist-icon-check li:before {
  content: "\f00c";
  font-family: FontAwesome;
  color: #555;
  margin-right: 5px;
  font-size: 12px;
}

.list-icon-arrow, .checklist-icon-check, .list-icon-flag, .list-icon-check, .checklist, .checklist-icon-arrow {
  margin: 0;
}

.list-icon-arrow li, .checklist-icon-check li, .list-icon-flag li, .list-icon-check li, .checklist li, .checklist-icon-arrow li {
  list-style: none;
}

.list-icon-arrow li, .list-icon-check li, .list-icon-flag li {
  position: relative;
  padding-left: 25px;
}

.list-icon-arrow li:before, .list-icon-check li:before, .list-icon-flag li:before, .checklist-icon-arrow li:before, .checklist-icon-check li:before {
  font-family: FontAwesome;
  margin-right: 3px;
  color: #e83100;
  position: absolute;
  left: 0;
  top: 1px;
}

.list-icon-flag li:before {
  content: "\f024";
}

.list-icon-arrow li:before, .checklist-icon-arrow li:before {
  content: "\f0da";
}

.list-icon-check li:before, .checklist-icon-check li:before {
  content: "\f00c";
}

.checklist-icon-arrow li:before, .checklist-icon-check li:before {
  color: #000;
  position: static;
  margin-right: 5px;
}

.comments-area {
  margin-top: 15px;
}

.comments-area .comments-title {
  margin-bottom: 25px;
}

.comments-area .comment-list {
  margin: 0;
  list-style: none;
}

.comments-area .comment-list-inner .comments-title {
  padding: 10px 0;
  margin-bottom: 25px;
  border-bottom: 1px #ddd solid;
}

.comments-area .comment-list-inner .comment-author.meta {
  color: #b3b3b3;
}

.comments-area .comment-list-inner .comment-author.meta strong {
  color: #000;
}

.comments-area .comment-list-inner > li {
  list-style: none outside none;
  margin-bottom: 25px;
}

.comments-area .comment-list-inner li .wrapper-comment {
  overflow: hidden;
}

.comments-area .comment-list-inner li .wrapper_avatar {
  width: 60px;
  height: 60px;
  float: left;
}

.comments-area .comment-list-inner li .wrapper_avatar .avatar {
  border-radius: 50%;
}

.comments-area .comment-list-inner li .comment-right {
  width: calc(100% - 60px);
  width: -webkit-calc(100% - 60px);
  width: -moz-calc(100% - 60px);
  float: left;
}

.comments-area .comment-list-inner li .comment-awaiting-moderation {
  margin-left: 20px;
}

.comments-area .comment-list-inner li .comment-extra-info {
  padding-left: 20px;
  text-transform: uppercase;
  color: #858585;
  font-size: 12px;
}

.comments-area .comment-list-inner li .comment-extra-info a {
  color: #858585;
}

.comments-area .comment-list-inner li .comment-extra-info i {
  margin-right: 5px;
}

.comments-area .comment-list-inner li .comment-extra-info .author, .comments-area .comment-list-inner li .comment-extra-info .date, .comments-area .comment-list-inner li .comment-extra-info .comment-edit-link {
  float: none;
  display: inline-block;
  margin-right: 15px;
}

.comments-area .comment-list-inner li .comment-extra-info .comment-reply-link {
  float: right;
  color: #858585;
}

.comments-area .comment-list-inner li .content-comment {
  padding: 10px 0 15px 20px;
}

.comments-area .comment-list-inner li .children {
  padding-top: 5px;
  margin-left: 60px;
  margin-bottom: 20px;
}

.comments-area .comment-list-inner li .children li {
  list-style: none;
}

.comments-area .comment-respond .comment-form-author, .comments-area .comment-respond .comment-form-email, .comments-area .comment-respond .comment-form-url {
  width: 50%;
  display: block;
}

.comments-area .comment-respond .comment-form-author input, .comments-area .comment-respond .comment-form-email input, .comments-area .comment-respond .comment-form-url input {
  width: 100%;
  border: 1px solid #ddd;
  padding: 8px 15px;
}

.comments-area .comment-respond textarea {
  width: 100%;
  border: 1px solid #ddd;
  padding: 8px 15px;
  height: 150px;
  display: inherit;
}

.comments-area .comment-respond .form-submit {
  text-align: left;
}

.comments-area .comment-respond .form-submit input#submit {
  text-shadow: none;
  box-shadow: none;
  border: none;
  padding: 8px 42px;
  line-height: 2;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  background: #222;
}

.comments-area .comment-respond .form-submit input#submit:focus {
  outline: none;
}

.comment-respond p.stars {
  color: #ffb300;
}

#reviews .comment-text .star-rating {
  float: right;
  color: #ffb300;
}

.comments-area .comment-respond-area #respond.comment-respond .comment-reply-title {
  padding: 10px 0;
  margin-bottom: 25px;
  border-bottom: 1px #ddd solid;
}

.comment-navigation {
  overflow: hidden;
}

.comment-navigation .nav-previous {
  float: left;
}

.comment-navigation .nav-next {
  float: right;
}

.pages_content h4 {
  //text-transform: uppercase;
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-weight: 700;
}

.contact_infor {
  margin-bottom: 30px;
}

.contact_infor ul {
  margin: 0;
}

.contact_infor ul li {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  padding-top: 10px;
  list-style: none;
}

.contact_infor ul li:last-child {
  border-bottom: none;
}

.contact_infor ul li label {
  text-transform: uppercase;
  width: 190px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.contact_infor ul li label i {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #ccc;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  margin-right: 10px;
}

.contact_infor ul li .des {
  width: calc(100% - 194px);
  display: inline-block;
  vertical-align: middle;
}

.tb-billing-fields p label {
  display: block;
}

.tb-billing-fields p input {
  width: 100%;
}

.tb-billing-fields p.create-account label, .tb-billing-fields p.create-account input {
  display: inline-block;
  width: auto;
}

#ship-to-different-address {
  position: relative;
}

#ship-to-different-address label {
  font-weight: normal;
  padding-left: 30px;
}

#ship-to-different-address #ship-to-different-address-checkbox {
  position: absolute;
  top: 5px;
  left: 4px;
}

.travel_tour #payment #place_order {
  width: 100%;
}

.travel_tour-checkout.checkout:after {
  content: "";
  display: table;
  clear: both;
}

.travel_tour-checkout.checkout .travel_tour-checkout-review-order-table {
  border-right: none;
}

.travel_tour-checkout.checkout .travel_tour-checkout-review-order-table td, .travel_tour-checkout.checkout .travel_tour-checkout-review-order-table th {
  border-bottom: none;
}

.travel_tour-checkout.checkout #payment {
  border-radius: 0;
}

.travel_tour-checkout label {
  font-weight: normal;
}

.travel_tour-checkout .button {
  box-shadow: none;
  border-radius: 0 !important;
  text-align: center;
  vertical-align: top;
  margin: 0 2px;
  border: none;
  padding: 11px 25px;
  text-shadow: 0 0 0;
  text-transform: uppercase;
}

.travel_tour-checkout .input-text, .travel_tour-checkout .country_select {
  border: 1px solid #E8E8E8;
  padding: 7px 10px;
}

.travel_tour-checkout #order_review_heading {
  margin-top: 0;
}

.travel_tour-checkout .columns .col2-set .col-2 {
  padding-top: 20px;
}

.travel_tour-checkout .columns .col2-set .col-1, .travel_tour-checkout .columns .col2-set .col-2 {
  width: 100%;
}

.travel_tour-checkout .columns .order-wrapper {
  background: rgba(84, 84, 84, 0.05);
  margin-left: 30px;
  padding: 20px;
}

.travel_tour form.checkout_coupon, .travel_tour form.login, .travel_tour form.register {
  border: 1px solid #d3ced2;
  padding: 20px;
  margin: 2em 0;
  text-align: left;
  border-radius: 5px;
}

.travel_tour-checkout .columns #add_payment_method #payment, .travel_tour-checkout .columns #payment {
  background: transparent;
}

.travel_tour-checkout .columns #add_payment_method #payment .wc_payment_methods, .travel_tour-checkout .columns #add_payment_method #payment .form-row.place-order, .travel_tour-checkout .columns #payment .wc_payment_methods, .travel_tour-checkout .columns #payment .form-row.place-order {
  padding-right: 0;
  padding-left: 0;

}

.travel_tour-checkout .wc_payment_methods {
  margin: 0;
}

.travel_tour table.shop_table tbody th, .travel_tour table.shop_table tfoot td, .travel_tour table.shop_table tfoot th {
  font-weight: 700;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.travel_tour table.shop_table th {
  font-weight: 700;
  padding: 9px 12px;
}

.travel_tour table.shop_table {
  border: 1px solid rgba(0, 0, 0, .1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px;
}

.travel_tour-checkout.checkout .travel_tour-checkout-review-order-table {
  border-right: none;
}

.travel_tour-checkout .wc_payment_methods li {
  list-style: none;
}

.travel_tour-checkout form.travel_tour-checkout {
  margin-top: 30px;
}

.travel_tour-checkout .travel_tour-info-login-form, .travel_tour-checkout .travel_tour-info-coupon-message {
  text-align: center;
}

.travel_tour-checkout .travel_tour-info-login-form .login, .travel_tour-checkout .travel_tour-info-login-form .checkout_coupon, .travel_tour-checkout .travel_tour-info-coupon-message .login, .travel_tour-checkout .travel_tour-info-coupon-message .checkout_coupon {
  min-height: inherit;
  max-width: 610px;
  margin: 0 auto 30px auto;
  border-radius: 0;
  border: 3px dashed rgba(84, 84, 84, 0.15);
  padding: 30px;
}

.travel_tour-checkout .travel_tour-info-login-form .checkout_coupon .input-text, .travel_tour-checkout .travel_tour-info-coupon-message .checkout_coupon .input-text {
  width: calc(100% - 142px);
  float: left;
}

.travel_tour-checkout .travel_tour-info-login-form .checkout_coupon .input-text:focus, .travel_tour-checkout .travel_tour-info-coupon-message .checkout_coupon .input-text:focus {
  outline: none;
}

.travel_tour-checkout .travel_tour-info-login-form .checkout_coupon.button:focus, .travel_tour-checkout .travel_tour-info-coupon-message .checkout_coupon.button:focus {
  outline: none;
}

.travel_tour-checkout .travel_tour-info-login-form .social-networks a:last-child > div, .travel_tour-checkout .travel_tour-info-coupon-message .social-networks a:last-child > div {
  margin-right: 0 !important;
}

.travel_tour-checkout .travel_tour-info-login-form .travel_tour-info, .travel_tour-checkout .travel_tour-info-coupon-message .travel_tour-info {
  border: none;
  background: transparent;
  display: inline-block;
  font-weight: bold;
  padding: 5px 0 5px 35px !important;
  margin-bottom: 20px !important;
  position: relative;
}

.travel_tour-checkout .travel_tour-info-login-form .travel_tour-info a, .travel_tour-checkout .travel_tour-info-coupon-message .travel_tour-info a {
  text-transform: uppercase;
}

.travel_tour-checkout .travel_tour-info-login-form .travel_tour-info a:hover, .travel_tour-checkout .travel_tour-info-coupon-message .travel_tour-info a:hover {
  opacity: 0.8;
}

.travel_tour-checkout .travel_tour-info-login-form .travel_tour-info:before, .travel_tour-checkout .travel_tour-info-coupon-message .travel_tour-info:before {
  font-family: 'Linearicons-Free';
  content: "\e82a";
  color: inherit;
  font-size: 20px;
  left: 3px;
  top: 5px;
  position: absolute;
}

.travel_tour-checkout .travel_tour-info-coupon-message .travel_tour-info:before {
  content: "\e859";
}

.travel_tour-billing-fields h3, #ship-to-different-address, .travel_tour-shipping-fields h3 {
  margin-top: 0;
}

.travel_tour-billing-fields h3 label, #ship-to-different-address label, .travel_tour-shipping-fields h3 label {
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-style: normal;
}

.travel_tour-checkout .select2-container .select2-choice {
  border: 1px solid #E8E8E8;
  border-radius: 0;
  padding: 3px 8px;
}

.travel_tour-checkout .select2-drop-active {
  border: 1px solid #E8E8E8;
}

.travel_tour-checkout .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #E8E8E8;
}

.travel_tour-checkout .select2-search input {
  padding: 5px 20px 5px 5px !important;
  border: 1px solid #E8E8E8;
}

.input-checkbox {
  display: inline-block !important;
  margin: 0 5px 0 0 !important;
}

.travel_tour-shipping-calculator .input-text, .travel_tour-shipping-calculator select {
  border: 1px solid #E8E8E8;
  padding: 7px 10px;
}

.travel_tour-shipping-calculator .shipping-calculator-form .button {
  background-color: #2a2a2a;
  color: #fffefe;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: normal;
  border-radius: 0;
  padding: 15px 24px;
  display: inline-block;
}

.travel_tour-ResetPassword .input-text {
  border: 1px solid #E8E8E8;
  padding: 7px 10px;
}

.travel_tour-ResetPassword .form-row input.button {
  background-color: #2a2a2a;
  color: #fffefe;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: normal;
  border-radius: 0;
  padding: 15px 24px;
  display: inline-block;
}

.travel_tour .shop_table, .travel_tour-page .shop_table {
  border-radius: 0 !important;
}

.travel_tour .shop_table.cart thead tr, .travel_tour-page .shop_table.cart thead tr {
  background-color: #f1f1f1;
}

.travel_tour .shop_table.cart thead tr th, .travel_tour-page .shop_table.cart thead tr th {
  text-transform: uppercase;
  text-align: center;
}

.travel_tour .shop_table.cart thead tr th.product-name, .travel_tour-page .shop_table.cart thead tr th.product-name {
  text-align: left;
}

.travel_tour .shop_table.cart tbody tr.cart_item:nth-child(2n), .travel_tour-page .shop_table.cart tbody tr.cart_item:nth-child(2n) {
  background-color: #fbfbfb;
}

.travel_tour .shop_table.cart tbody tr .product-link a, .travel_tour-page .shop_table.cart tbody tr .product-link a {
  color: #515151;
}

.travel_tour .shop_table.cart tbody tr .product-thumbnail img, .travel_tour-page .shop_table.cart tbody tr .product-thumbnail img {
  width: 60px;
}

.travel_tour .shop_table.cart tbody tr td, .travel_tour-page .shop_table.cart tbody tr td {
  text-align: center;
}

.travel_tour .shop_table.cart tbody tr td.product-name, .travel_tour-page .shop_table.cart tbody tr td.product-name {
  text-align: left;
}

.travel_tour .shop_table.cart tbody tr td.product-remove a, .travel_tour-page .shop_table.cart tbody tr td.product-remove a {
  margin: 0 auto;
}

.travel_tour .return-to-shop .button, .travel_tour .checkout_coupon .button, .travel_tour-page .return-to-shop .button, .travel_tour-page .checkout_coupon .button {
  background-color: #2a2a2a;
  color: #fffefe;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: normal;
  border-radius: 0;
  padding: 10px 24px;
  display: inline-block;
}

.travel_tour form .form-row .required {
  color: red;
  font-weight: 700;
  border: 0;
}

.travel_tour .checkout_coupon .input-text, .travel_tour-page .checkout_coupon .input-text {
  padding: 11px 10px;
}

.travel_tour .wc-proceed-to-checkout a.checkout-button.button, .travel_tour-page .wc-proceed-to-checkout a.checkout-button.button {
  background-color: #2a2a2a;
  color: #fffefe;
  text-transform: uppercase;
  border-radius: 0;
}

.travel_tour .actions, .travel_tour-page .actions {
  text-align: right !important;
}

.travel_tour .actions .button, .travel_tour .actions .button.alt, .travel_tour-page .actions .button, .travel_tour-page .actions .button.alt {
  background-color: #2a2a2a;
  color: #fffefe;
  text-transform: uppercase;
  border-radius: 0;
  padding: 13px 25px;
  display: inline-block;
}

.travel_tour .actions .button:disabled[disabled], .travel_tour .actions .button:disabled, .travel_tour .actions .button.alt:disabled[disabled], .travel_tour .actions .button.alt:disabled, .travel_tour-page .actions .button:disabled[disabled], .travel_tour-page .actions .button:disabled, .travel_tour-page .actions .button.alt:disabled[disabled], .travel_tour-page .actions .button.alt:disabled {
  opacity: 1;
  color: #fffefe;
  padding: 13px 25px;
}

.travel_tour .coupon, .travel_tour-page .coupon {
  float: left;
}

.travel_tour .coupon .input-text, .travel_tour-page .coupon .input-text {
  padding: 9px 10px 10px !important;
  width: auto !important;
}

.travel_tour .coupon label, .travel_tour-page .coupon label {
  display: none;
}

.travel_tour .cart-collaterals, .travel_tour-page .cart-collaterals {
  margin-top: 30px;
}

.travel_tour .cart-collaterals .cart_totals table, .travel_tour-page .cart-collaterals .cart_totals table {
  border: 1px solid #ddd;
  border-right: none;
}

.travel_tour .cart-collaterals .cart_totals table tbody tr th, .travel_tour-page .cart-collaterals .cart_totals table tbody tr th {
  background-color: #fbfbfb;
  font-weight: normal;
  color: #515151;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 0;
  border-left: 0;
  padding: 10px 20px;
  width: auto;
}

.travel_tour .cart-collaterals .cart_totals table tbody tr td, .travel_tour-page .cart-collaterals .cart_totals table tbody tr td {
  padding: 10px 20px;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 0;
  border-left: 0;
}

.travel_tour .cart-collaterals .cart_totals table tbody tr td:first-child, .travel_tour-page .cart-collaterals .cart_totals table tbody tr td:first-child {
  border-top: 0;
  border-right: 0;
}

.travel_tour .cart-collaterals .cross-sells .item-product, .travel_tour-page .cart-collaterals .cross-sells .item-product {
  width: 50%;
}

.travel_tour .cart-collaterals .cross-sells .item-product:nth-child(2n+1), .travel_tour-page .cart-collaterals .cross-sells .item-product:nth-child(2n+1) {
  clear: both;
}

.travel_tour .cart-collaterals .cross-sells .item-product:nth-child(4n+1), .travel_tour-page .cart-collaterals .cross-sells .item-product:nth-child(4n+1) {
  clear: none;
}

.travel_tour .cart-collaterals .cross-sells .item-product:nth-child(4n+1), .travel_tour-page .cart-collaterals .cross-sells .item-product:nth-child(4n+1) {
  clear: none;
}

.travel_tour .calculated_shipping .wc-proceed-to-checkout .button, .travel_tour-page .calculated_shipping .wc-proceed-to-checkout .button {
  background-color: #2a2a2a;
  color: #fffefe;
  text-transform: uppercase;
  border-radius: 0;
  padding: 16px 24px;
  display: inline-block;
  width: 100%;
  font-size: 100%;
}

.travel_tour .calculated_shipping .wc-proceed-to-checkout .button:focus, .travel_tour-page .calculated_shipping .wc-proceed-to-checkout .button:focus {
  outline: none;
}

.travel_tour-cart .travel_tour .cart-empty, .travel_tour-cart .travel_tour .return-to-shop {
  text-align: center;
}

#payment input[type="submit"] {
  background-color: #2a2a2a;
  color: #fffefe;
  text-transform: uppercase;
  border-radius: 0;
  padding: 16px 24px;
}

.travel_tour .login .input-text, .travel_tour .register .input-text, .travel_tour .edit-account .input-text, .travel_tour-page .login .input-text, .travel_tour-page .register .input-text, .travel_tour-page .edit-account .input-text {
  padding: 8px 10px;
  border: 1px solid #E8E8E8;
}

.travel_tour .login input[type="submit"], .travel_tour .register input[type="submit"], .travel_tour .edit-account input[type="submit"], .travel_tour-page .login input[type="submit"], .travel_tour-page .register input[type="submit"], .travel_tour-page .edit-account input[type="submit"] {
  padding: 9px 25px;
  text-align: center;
  background: #2a2a2a;
  color: #fff;
  border: none;
  text-transform: uppercase;
}

.travel_tour .edit-account input[type="submit"], .travel_tour-page .edit-account input[type="submit"] {
  margin-top: 25px;
}

.travel_tour .travel_tour-info .button, .travel_tour-page .travel_tour-info .button {
  padding: 9px 25px;
  text-align: center;
  background: #2a2a2a;
  color: #fff;
  border: none;
  text-transform: uppercase;
}

.travel_tour .login, .travel_tour-page .login {
  min-height: 323px;
}

.travel_tour .login input[type="submit"], .travel_tour-page .login input[type="submit"] {
  float: right;
}

.widget_login_form span {
  cursor: pointer;
}

.widget_login_form .login i {
  margin-right: 5px;
}

.widget_login_form .register_btn {
  margin-left: 25px;
  position: relative;
}

.widget_login_form .register_btn:before {
  content: "|";
  position: absolute;
  left: -15px;
}

.widget_login_form .form_popup {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
  visibility: hidden;
  position: fixed;
  outline: none;
  -webkit-overflow-scrolling: touch;
  z-index: 999;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.widget_login_form .form_popup:focus {
  outline: none;
}

.widget_login_form .form_popup .closeicon {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  opacity: 1;
  z-index: 999;
  cursor: pointer;
}

.widget_login_form .form_popup .closeicon:before {
  transform: rotate(45deg);
}

.widget_login_form .form_popup .closeicon:after {
  transform: rotate(-45deg);
}

.widget_login_form .form_popup .closeicon:before, .widget_login_form .form_popup .closeicon:after {
  position: absolute;
  left: 10px;
  content: ' ';
  height: 22px;
  width: 1px;
  background-color: #000;
}

.widget_login_form .form_popup .inner-form {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -webkit-transform 0.3s ease-out;
  -ms-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  width: 400px;
  margin: 50px auto 20px auto;
  padding: 25px;
  background: #fff;
  position: relative;
  color: #333;
}

.widget_login_form .form_popup .inner-form h3 {
  margin-bottom: 25px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
}

.widget_login_form .form_popup .inner-form form {
  *zoom: 1;
}

.widget_login_form .form_popup .inner-form form:before, .widget_login_form .form_popup .inner-form form:after {
  content: "";
  display: table;
}

.widget_login_form .form_popup .inner-form form:after {
  clear: both;
}

.widget_login_form .form_popup .inner-form p label {
  display: block;
  line-height: 15px;
  font-weight: normal;
}

.widget_login_form .form_popup .inner-form p input[type="password"], .widget_login_form .form_popup .inner-form p input[type="text"], .widget_login_form .form_popup .inner-form p input[type="email"] {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px 15px;
  font-weight: normal;
}

.widget_login_form .form_popup .inner-form p.login-remember {
  margin-bottom: 0;
  margin-top: 10px;
  width: 50%;
  float: left;
}

.widget_login_form .form_popup .inner-form p.login-remember {
  *zoom: 1;
}

.widget_login_form .form_popup .inner-form p.login-remember:before, .widget_login_form .form_popup .inner-form p.login-remember:after {
  content: "";
  display: table;
}

.widget_login_form .form_popup .inner-form p.login-remember:after {
  clear: both;
}

.widget_login_form .form_popup .inner-form p.login-remember input[type="checkbox"] {
  float: left;
  margin-top: 0;
  margin-right: 4px;
}

.widget_login_form .form_popup .inner-form p.login-submit {
  width: 50%;
  float: left;
}

.widget_login_form .form_popup .inner-form p.login-submit {
  *zoom: 1;
}

.widget_login_form .form_popup .inner-form p.login-submit:before, .widget_login_form .form_popup .inner-form p.login-submit:after {
  content: "";
  display: table;
}

.widget_login_form .form_popup .inner-form p.login-submit:after {
  clear: both;
}

.widget_login_form .form_popup .inner-form p input[type="submit"] {
  width: 100%;
  padding: 12px 15px;
  text-align: center;
  background: #2a2a2a;
  color: #fff;
  border: none;
  text-transform: uppercase;
  max-width: 190px;
}

.widget_login_form .form_popup .inner-form .lost-pass {
  color: #333;
  font-weight: normal;
  font-style: italic;
}

.widget_login_form .form_popup .apsl-login-networks {
  margin-top: 20px;
}

.show_form_popup_login, .show_form_popup_register {
  overflow: hidden;
}

.show_form_popup_login .widget_login_form .background-overlay, .show_form_popup_register .widget_login_form .background-overlay {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  transition: all 0.35s cubic-bezier(0.29, 0.63, 0.44, 1);
}

.show_form_popup_login .widget_login_form .from_login {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
}

.show_form_popup_register .widget_login_form .from_register {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
}

.site-header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
}

.navigation-menu {
  padding-top: 16px;
  background: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-bottom: 15px;
}

.dropdown-menu {
  border-radius: 0;
  padding: 0;
  margin: 12px 0 0 0;
}

.dropdown-menu li {
  margin-bottom: 0;
}

.search-toggler-unit .search-toggler {
  display: block;
  position: relative;
  padding: 4px 8.5px;
  background-color: transparent;
  overflow: hidden;
  cursor: pointer;
}

.search-toggler-unit .search-toggler .fa {
  position: relative;
  z-index: 2;
}

.search-toggler-unit .search-toggler:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%;
  top: 0;
  z-index: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.66, 0.36, 0.17, 0.91);
  -moz-transition: all 0.25s cubic-bezier(0.66, 0.36, 0.17, 0.91);
  -ms-transition: all 0.25s cubic-bezier(0.66, 0.36, 0.17, 0.91);
  -o-transition: all 0.25s cubic-bezier(0.66, 0.36, 0.17, 0.91);
  transition: all 0.25s cubic-bezier(0.66, 0.36, 0.17, 0.91);
}

.search-toggler-unit .search-toggler:hover {
  color: #fff;
}

.search-toggler-unit .search-toggler:hover:before {
  left: 0;
}

.header_top_bar {
  background-color: #333;
  color: #aaa;
  font-size: 15px;
  font-weight: 700;
}

.header_top_bar a {
  font-weight: 700;
  font-style: normal;
  color: #aaa;
  font-size: 12px;
}

.header_top_bar a:hover {
  color: #fff;
}

.header_top_bar .top_bar_info {
  list-style: none;
  padding: 0;
  margin: 11px 0 12px;
}

.header_top_bar .top_bar_info li {
  float: left;
  margin-left: 25px;
  margin-bottom: 0;
  line-height: 14px;
}

.header_top_bar .top_bar_info li .fa {
  font-size: 14px;
  margin-right: 5px;
}

.header_top_bar .top_bar_info li:first-child {
  margin-left: 0;
}

.header_top_bar .topbar-right {
  text-align: right;
}

.header_top_bar .topbar-right .widget {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-left: 25px;
  text-align: left;
}

.header_top_bar .topbar-right .widget:first-child {
  margin-left: 0;
}

.header_top_bar .header_login_url {
  line-height: 1;
}

.header_top_bar .header_login_url a {
  display: inline-block;
  vertical-align: bottom;
  text-decoration: none !important;
  line-height: 1;
}

.header_top_bar .header_login_url a i {
  margin-right: 4px;
}

.header_top_bar .header_login_url .vertical_divider {
  display: inline-block;
  vertical-align: bottom;
  margin: 0 8px 0;
  width: 1px;
  height: 12px;
  background-color: #9a9a9a;
}

.header_top_bar .country-selector {
  z-index: 9;
}

.header_top_bar .country-selector .wgcurrent {
  border: none;
}

.header_top_bar .country-selector .wgcurrent:after {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f107";
  background: none !important;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  top: 12px;
}

.header_top_bar .country-selector .wgcurrent a {
  border: none;
  height: inherit;
  line-height: 1;
  background: transparent;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 35px;
  color: inherit;
}

.header_top_bar .wg-drop.country-selector {
  background: transparent;
}

.header_top_bar .wg-drop.country-selector.closed .wgcurrent:after {
  content: "\f105";
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform: rotate(0deg);
}

.header_top_bar .qtranxs_widget .widget-title {
  display: none;
}

.header_top_bar .qtranxs_widget select {
  padding: 10px 10px 10px 0;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}

.header_top_bar .qtranxs_widget select:focus {
  outline: none;
}

.header_top_bar .qtranxs_widget select option {
  color: #333;
}

.header_main_menu_wrapper .navbar-collapse {
  padding: 0;
}

.logo_sticky {
  display: none;
}

.site-header {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.transparent_home_page .wrapper-content {
  padding-top: 0 !important;
}

.transparent_home_page .affix-top .navigation-menu {
  background: transparent !important;
}

.transparent_home_page .affix-top .header-menu > li > a {
  color: #fff;
}

.transparent_home_page .site-header {
  box-shadow: 0 0 0;
}

.transparent_home_page .site-header.affix {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.sticky_header.affix {
  position: fixed;
  top: 0;
}

.sticky_header.affix .header_top_bar {
  height: 0;
  overflow: hidden;
}

.sticky_header.affix .navigation-menu {
  padding-top: 9px;
  padding-bottom: 8px;
}

.sticky_header.affix .logo_sticky {
  display: block;
}

.sticky_header.affix .logo_transparent_static {
  display: none;
}

.nav > li > a:hover, .nav > li > a:focus {
  background-color: transparent !important;
}

.site-header.affix-top {
  position: absolute;
  z-index: 999;
  width: 100%;
}

.site-header.affix-top .sticky-logo {
  display: none;
}

.affix .no-sticky-logo {
  display: none;
}

.site-header {
  width: 100%;
  z-index: 999;
}

.site-header .navbar-nav {
  //text-align: center;
}

.site-header .navbar-nav > li {
  float: none;
  display: inline-block;
  line-height: 1;
}

.sticky_header {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.drag-target {
  height: 100%;
  width: 10px;
  position: fixed;
  top: 0;
  z-index: 998;
}

#sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  will-change: opacity;
}

.menu-main-menu > .menu ul li {
  display: inline-block;
  list-style: none;
  padding: 10px 15px;
}

.navigation-menu .width-logo, .navigation-menu .width-navigation {
  display: inline-block;
  vertical-align: middle;
}

.navigation-menu .width-logo {
  font-size: 30px;
}

.phys-breadcrumb {
  margin: 0;
}

.phys-breadcrumb li {
  display: inline-block;
}

.phys-breadcrumb li:after {
  content: "\f105";
  margin: 0 5px;
  font: normal normal normal 14px/1 FontAwesome;
}

.phys-breadcrumb li a {
  text-decoration: none;
  color: inherit;
}

.phys-breadcrumb li:last-child:after {
  content: "";
  margin: 0;
}

.breadcrumbs-wrapper {
  margin-bottom: 15px;
}

.travel-booking-search {
  position: relative;
  margin-bottom: 3px;
  margin: 0 -10px;
}

.travel-booking-search form {
  display: block;
  padding: 20px 0;
  margin: 0 auto;
}

.travel-booking-search form {
  *zoom: 1;
}

.travel-booking-search form:before, .travel-booking-search form:after {
  content: "";
  display: table;
}

.travel-booking-search form:after {
  clear: both;
}

.travel-booking-search form button {
  width: 100%;
  padding: 9px 8px 5px 8px;
  border: none;
  color: #333;
  text-transform: uppercase;
  background: #ffd200;
  box-shadow: 0 2px 0 0 rgba(255, 210, 0, 0.6);
}

.travel-booking-search form button:hover {
  color: #fff;
}

.travel-booking-search .hb-form-table li {
  width: 20%;
  padding: 0 10px;
  margin: 10px 0;
  float: left;
  list-style: none;
}

.travel-booking-search .hb-form-table {
  margin: 0;
}

.travel-booking-search .hb-form-table {
  *zoom: 1;
}

.travel-booking-search .hb-form-table:before, .travel-booking-search .hb-form-table:after {
  content: "";
  display: table;
}

.travel-booking-search .hb-form-table:after {
  clear: both;
}

.travel-booking-search .hb-form-field {
  position: relative;
  display: inline-block;
}

.travel-booking-search .hb-form-field input, .travel-booking-search .hb-form-field select, .travel-booking-search .hb-form-field button, .travel-booking-search .hb-form-field textarea {
  outline: 0;
  position: relative;
  width: 100%;
  height: 40px;
  border: none;
  background: #fff;
  color: #333;
  border-radius: 0;
  padding: 7px 8px;
  line-height: 24px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.travel-booking-search .hb-form-field input.placeholder, .travel-booking-search .hb-form-field select.placeholder, .travel-booking-search .hb-form-field button.placeholder, .travel-booking-search .hb-form-field textarea.placeholder {
  color: #555;
}

.travel-booking-search .hb-form-field input:-moz-placeholder, .travel-booking-search .hb-form-field select:-moz-placeholder, .travel-booking-search .hb-form-field button:-moz-placeholder, .travel-booking-search .hb-form-field textarea:-moz-placeholder {
  color: #555;
}

.travel-booking-search .hb-form-field input::-webkit-input-placeholder, .travel-booking-search .hb-form-field select::-webkit-input-placeholder, .travel-booking-search .hb-form-field button::-webkit-input-placeholder, .travel-booking-search .hb-form-field textarea::-webkit-input-placeholder {
  color: #555;
}

.travel-booking-search .hb-form-field input:-ms-input-placeholder, .travel-booking-search .hb-form-field select:-ms-input-placeholder, .travel-booking-search .hb-form-field button:-ms-input-placeholder, .travel-booking-search .hb-form-field textarea:-ms-input-placeholder {
  color: #555;
}

.travel-booking-search .hb-form-field-select {
  position: relative;
}

.travel-booking-search .hb-form-field-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.travel-booking-search .hb-form-field-select:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #333;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -3px;
  z-index: 1;
}

.travel-booking-search.travel-booking-style_2 .hb-form-table li {
  margin: 0;
  width: 21.5%;
}

.travel-booking-search.travel-booking-style_2 .hb-form-table li.hb-submit {
  width: 14%;
}

.travel-booking-search.travel-booking-style_2 input, .travel-booking-search.travel-booking-style_2 select {
  border: none !important;
  height: 32px;
  font-weight: bold;
  padding-top: 0 !important;
  color: #333;
  //text-transform: capitalize;
}

.travel-booking-search.travel-booking-style_2 input.placeholder, .travel-booking-search.travel-booking-style_2 select.placeholder {
  color: #484848;
}

.travel-booking-search.travel-booking-style_2 input:-moz-placeholder, .travel-booking-search.travel-booking-style_2 select:-moz-placeholder {
  color: #484848;
}

.travel-booking-search.travel-booking-style_2 input::-webkit-input-placeholder, .travel-booking-search.travel-booking-style_2 select::-webkit-input-placeholder {
  color: #484848;
}

.travel-booking-search.travel-booking-style_2 input:-ms-input-placeholder, .travel-booking-search.travel-booking-style_2 select:-ms-input-placeholder {
  color: #484848;
}

.travel-booking-search.travel-booking-style_2 .hb-submit button {
  height: 60px;
  box-shadow: 0 0 0;
}

.travel-booking-search.travel-booking-style_2 .hb-form-field-input label, .travel-booking-search.travel-booking-style_2 .hb-form-field-select label {
  font-weight: normal;
  padding-left: 10px;
  color: #999;
  opacity: 0.8;
  margin-top: 6px;
  display: block;
  margin-bottom: -4px;
}

.travel-booking-search.travel-booking-style_2 .hb-form-field-select:after {
  content: "";
  width: 1px;
  background: #E8E8E8;
  top: -14px;
  bottom: 0;
  position: absolute;
  left: -10px;
}

.travel-booking-search.travel-booking-style_2 .hb-form-field-select:before {
  content: "\e874";
  font: normal normal normal 18px/1 'Linearicons-Free';
  border: none;
  width: auto;
  height: auto;
  margin-top: -9px;
}

.slider-tour-booking {
  margin-top: -100px;
  position: relative;
  background-color: rgba(67, 67, 67, 0.7);
}

.widget-area ul {
  margin-left: 0;
  margin-bottom: 0;
}

.widget-area .search_tour {
  margin: 0 0 30px 0;
}

.widget-area aside {
  overflow: hidden;
  margin: 0 0 30px 0;
}

.widget-area aside:last-child {
  margin-bottom: 0;
}

.widget-area aside .widget-title {
  margin-bottom: 20px;
  margin-top: 0;
  padding-bottom: 15px;
  text-transform: uppercase;
  position: relative;
}

.widget-area aside .widget-title:before {
  position: absolute;
  left: 0;
  width: 35px;
  height: 2px;
  content: "";
  bottom: 0;
}

.widget-area aside ul li {
  padding-top: 8px;
  padding-bottom: 8px;
  list-style: none;
}

.widget-area aside select {
  max-width: 100%;
  width: 100%;
  border: 1px solid #ddd;
  padding: 6px 10px;
}

.widget-area aside select:focus {
  outline: none;
  box-shadow: 0 0 0;
}

.widget-area aside.widget_archive ul li, .widget-area aside .product-categories li, .widget-area aside.widget_categories ul li {
  border-bottom: 1px solid #ddd;
}

.widget-area aside.widget_archive ul li:last-child, .widget-area aside .product-categories li:last-child, .widget-area aside.widget_categories ul li:last-child {
  border-bottom: 0;
}

.widget-area aside.widget_archive ul li .count, .widget-area aside .product-categories li .count, .widget-area aside.widget_categories ul li .count {
  float: right;
}

.widget-area aside.widget_categories ul li.cat-item, .widget-area aside.widget_categories ul li, .widget-area aside.widget_archive ul li.cat-item, .widget-area aside.widget_archive ul li {
  text-align: right;
  overflow: hidden;
}

.widget-area aside.widget_categories ul li.cat-item a, .widget-area aside.widget_categories ul li a, .widget-area aside.widget_archive ul li.cat-item a, .widget-area aside.widget_archive ul li a {
  float: left;
}

.widget-area .screen-reader-text {
  display: none;
}

.widget-area .widget_tag_cloud .tagcloud, .widget-area .widget_product_tag_cloud .tagcloud {
  margin: 0 -5px;
}

.widget-area .widget_tag_cloud .tagcloud a, .widget-area .widget_product_tag_cloud .tagcloud a {
  font-size: 100% !important;
  margin: 5px;
  padding: 4px 8px;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.widget-area .travel_tour ul.cart_list li {
  margin-bottom: 20px;
  padding: 0;
}

.widget-area .travel_tour ul.cart_list li:last-child {
  margin-bottom: 0;
}

.widget-area .travel_tour ul.cart_list li img {
  width: 100px;
  float: left;
  margin-left: 0;
  margin-right: 15px;
}

.widget-area .travel_tour ul.product_list_widget li {
  margin-bottom: 20px;
  padding: 0;
}

.widget-area .travel_tour ul.product_list_widget li:last-child {
  margin-bottom: 0;
}

.widget-area .travel_tour ul.product_list_widget li .thumb-image {
  width: 90px;
  float: left;
  border-radius: 5px;
  overflow: hidden;
}

.widget-area .travel_tour ul.product_list_widget li .thumb-image img {
  width: auto;
  margin: 0;
}

.widget-area .travel_tour ul.product_list_widget li .content {
  padding-left: 15px;
  width: calc(100% - 90px);
  float: left;
}

.widget-area .travel_tour ul.product_list_widget li .content .travel_tour-Price-amount.amount {
  color: #5a626c;
  font-weight: 700;
}

.widget-area .travel_tour ul.product_list_widget li .content del .travel_tour-Price-amount.amount {
  font-weight: normal;
  color: #9a9696;
}

.widget-area .travel_tour ul.product_list_widget li .content ins {
  text-decoration: none;
}

.widget-area .travel_tour ul.product_list_widget li .content .posted_in a {
  font-style: italic;
  opacity: 0.7;
  line-height: 1;
  display: inline-block;
}

.widget-area .travel_tour ul.product_list_widget li .content p {
  margin-bottom: 10px;
}

.widget-area .travel_tour ul.product_list_widget li .content a {
  font-weight: normal;
}

.widget-area .widget_categories ul li, .widget-area .widget_pages ul li {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.widget-area .widget_categories ul li:last-child, .widget-area .widget_pages ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.widget-area .widget_categories .children, .widget-area .widget_pages .children {
  border-top: 1px solid #ddd;
  margin-left: 20px;
  margin-top: 10px;
}

.widget-area #wp-calendar th {
  background: rgba(0, 0, 0, 0.05);
}

.widget-area #wp-calendar td, .widget-area #wp-calendar th {
  text-align: center;
}

.widget-area .search-form, .not-found .search-form {
  position: relative;
}

.widget-area .search-form:after, .not-found .search-form:after {
  content: "\f002";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: 13px;
  top: 8px;
}

.widget-area .search-form label, .not-found .search-form label {
  width: 100%;
  display: inline-block;
  font-weight: normal;
}

.widget-area .search-form label .screen-reader-text, .not-found .search-form label .screen-reader-text {
  display: none;
}

.widget-area .search-form label .search-field, .not-found .search-form label .search-field {
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #ddd;
  padding: 5px 10px;
}

.widget-area .search-form label .search-submit, .not-found .search-form label .search-submit {
  margin-left: 0;
  margin-right: 0;
}

.widget-area .search-form .search-submit, .not-found .search-form .search-submit {
  display: none;
  margin: 0;
  padding: 0 16px;
}

.not-found {
  text-align: center;
}

.not-found .search-form {
  max-width: 300px;
  margin: 20px auto;
}

.widget_rss .rsswidget {
  font-weight: bold;
}

.widget_rss .rss-date {
  display: block;
  font-style: italic;
  font-size: 90%;
}

.widget_rss cite {
  color: #000;
  font-weight: bold;
}

.widget_nav_menu ul li {
  display: block;
}

.widget_nav_menu ul ul {
  margin: 0 0 0 15px;
}

.widget_nav_menu ul ul li {
  margin: 0;
  display: block;
}

.widget_nav_menu .sub-menu {
  text-transform: none;
}

.widget_nav_menu li.menu-item-has-children {
  padding-bottom: 0;
}

.widget_nav_menu .nav_desc {
  display: none;
}

.widget_recent_entries ul li .post-date {
  font-style: italic;
  display: block;
}

#secondary .widget_tag_cloud .tagcloud a {
  color: #333;
}

#secondary .widget_tag_cloud .tagcloud a:hover {
  color: #fff;
}

.description_single {
  //width: 39%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}

.description_single .post_description {
  border-width: 1px 0;
  border-style: dotted;
  border-color: #E8E8E8;
  margin: 15px 0;
  padding: 15px 0;
}

.description_single .post_description ul {
  margin-bottom: 0;
}

.description_single .post_description ul li {
  margin: 3px 0;
}

.description_single h3 {
  //text-transform: uppercase;
  color: #333;
}

.description_single .title h1 {
  margin-top: 0;
}

.infor_header {
  margin: 0;
  background: #14785e;
  color: #FFF;
}

.infor_header li {
  display: inline-block;
  margin: 10px;
  color: inherit;
}

.infor_header li span {
  padding-right: 7px;
  text-transform: uppercase;
}

.archive-header {
  margin-bottom: 25px;
}

.archive-header .title_page {
  background: #14785e;
  color: #FFF;
  padding: 15px 20px;
  margin: 0;
  text-transform: uppercase;
  font-size: 20px;
}

.post_price_single {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 33px;
  color: #333;
  line-height: 30px;
}

.post_price_single span {
  display: block;
  text-transform: none;
  font-weight: normal;
  font-size: 14px;
}

.tour_code_single {
  margin: 15px 0;
  background: #e93001;
  position: relative;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  color: #fff;
  padding-left: 15px;
}

.tour_code_single span {
  font-weight: bold;
}

.tour_code_single:after {
  position: absolute;
  top: 0;
  right: -20px;
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid #e93001;
  border-top: 17px solid transparent;
  border-bottom: 18px solid transparent;
}

.top_content_single {
  *zoom: 1;
}

.top_content_single:before, .top_content_single:after {
  content: "";
  display: table;
}

.top_content_single:after {
  clear: both;
}

.site-content .entry-content {
  background: #26BDF7;
  padding: 10px;
}

.thumbnail_product {
  padding-left: 31px;
  position: relative;
  padding-right: 31px;
}

.thumbnail_product .slides {
  margin-bottom: 0;
}

.thumbnail_product .slides {
  *zoom: 1;
}

.thumbnail_product .slides:before, .thumbnail_product .slides:after {
  content: "";
  display: table;
}

.thumbnail_product .slides:after {
  clear: both;
}

.thumbnail_product .flex-viewport li {
  margin: 20px 20px 10px 0;
}

.thumbnail_product .flex-viewport li img {
  opacity: 1;
  filter: alpha(opacity=100);
  cursor: pointer;
}

.thumbnail_product .flex-viewport li.flex-active-slide img {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.flex-prev, .flex-next {
  position: absolute;
  top: 50%;
  left: 5px;
  font-family: 'Glyphicons Halflings';
  width: 20px;
  height: 20px;
  margin-top: -10px;
  color: #ccc;
}

.flex-prev:before {
  content: "\e079";
}

.flex-next:before {
  content: "\e080";
}

.flex-next {
  left: auto;
  right: 5px;
}

.flex-prev:hover, .flex-next:hover {
  color: #000;
}

.flex-disabled {
  display: none;
}

.wrapper-map {
  position: relative;
  padding-bottom: 36.25%;
  height: 0;
}

.wrapper-map #mapCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.table_pakages tr td {
  border-bottom: 1px solid #aaa;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.site-content .entry-content .wpb_tabs.wpb_content_element {
  margin-bottom: 0;
}

.site-content .entry-content .wpb_content_element.wpb_tabs .wpb_tour_tabs_wrapper .wpb_tab {
  background: #fff;
  border-radius: 0 3px 3px 3px;
  padding: 20px;
}

.table_pakages tbody tr {
  background: #eee;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
}

.table_pakages tbody tr.no_background {
  background: #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.table_pakages tbody tr.no_background td {
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
}

.table_pakages thead tr {
  background: #c9e7f2;
}

.table_pakages thead tr th {
  text-align: center;
  padding: 10px 0;
}

.site-content .entry-content .wpb_content_element .wpb_tabs_nav li {
  background: #77d0f2;
}

.site-content .entry-content .wpb_tabs .wpb_tabs_nav li {
  margin: 0;
}

.site-content .entry-content .wpb_tabs .wpb_tabs_nav li a {
  text-transform: uppercase;
  color: #fff;
}

.site-content .entry-content .wpb_tabs .wpb_tabs_nav li:last-child {
  border-radius: 0 3px 0 0;
}

.site-content .entry-content .wpb_tabs .wpb_tabs_nav li.ui-state-active {
  font-weight: bold;
}

.site-content .entry-content .wpb_tabs .wpb_tabs_nav li.ui-state-active, .site-content .entry-content .wpb_tabs .wpb_tabs_nav li:hover {
  background: #fff;
  margin-top: -6px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 3px 3px 0 0;
}

.site-content .entry-content .wpb_tabs .wpb_tabs_nav li.ui-state-active a, .site-content .entry-content .wpb_tabs .wpb_tabs_nav li:hover a {
  color: #000;
}

.site-content .entry-content .wpb_tabs .ui-tabs {
  padding-top: 10px;
}

.section-white.padding-1x {
  padding: 15px 15px 0 15px;
  margin-bottom: 30px;
}

.single-post .comments-area {
  padding-bottom: 50px;
}

.list_content > li {
  float: left;
  padding: 15px;
}

.post_images {
  background: #d8d8d8;
  min-height: 125px;
  position: relative;
  margin: -1px;
}

.post_images a {
  display: inline-block;
}

.post_images span.price, .post_images .price {
  position: absolute;
  top: 14px;
  left: 0;
  display: inline-block;
  color: #333;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
  padding: 4px 7px;
  border-radius: 0 3px 3px 0;
}

.post_images span.price del, .post_images .price del {
  font-size: 90%;
  font-weight: normal;
  opacity: 0.9;
}

.post_images span.price ins, .post_images .price ins {
  text-decoration: none;
}

.post_images .group-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.post_images .group-icon a {
  margin: 6px 12px;
  color: #fff;
  position: relative;
}

.post_images .group-icon a i {
  font-size: 18px;
}

.post_images .group-icon a:before {
  content: "";
  width: 1px;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: -13px;
  background: rgba(255, 255, 255, 0.7);
}

.post_images .group-icon a.frist:before {
  display: none;
}

.post_images .group-icon .tooltip {
  white-space: nowrap;
}

.item_content .wrapper_content {
  padding: 14px;
  background: #fff;
}

.item_content .wrapper_content .post_title h4 {
  margin-top: 0;
}

.item_content .wrapper_content .post_description {
  margin-bottom: 10px;
}

.item_content .wrapper_content .post_description ul {
  margin: 0;
}

.item_content .wrapper_content .post_description ul li {
  list-style: none;
}

.item_border {
  border: 1px solid #E8E8E8;
  margin-bottom: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  background: #fff;
}

.post_title h4 a:hover {
  color: #26BDF7;
}

.post_date {
  text-transform: uppercase;
  color: #666;
  margin-bottom: 10px;
  display: block;
  text-align: left;
}

.post_date:before {
  content: "\e864";
  font: normal normal normal 16px/1 'Linearicons-Free';
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;
}

.read_more {
  background: #f5f5f5;
  height: 60px;
}

.travel_tour ul.products li.product .read_more .add_to_cart_button, .travel_tour ul.products li.product .read_more .wc-forward {
  margin-top: 0 !important;
}

.read_more_button, .travel_tour ul.products li.product .read_more .add_to_cart_button, .travel_tour ul.products li.product .read_more .wc-forward {
  float: right;
  color: #fff;
  width: 55%;
  text-align: center;
  line-height: 41px;
  padding: 0 0 0 20px;
  background: #26BDF7;
  position: relative;
  font-weight: 700;
  font-size: 12px;
  margin-right: -1px;
  margin-bottom: -1px;
  border-radius: 0;
  text-transform: uppercase;
}

.read_more_button:before, .travel_tour ul.products li.product .read_more .add_to_cart_button:before, .travel_tour ul.products li.product .read_more .wc-forward:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid #f5f5f5;
  border-bottom: 20px solid transparent;
}

.read_more_button i, .travel_tour ul.products li.product .read_more .add_to_cart_button i, .travel_tour ul.products li.product .read_more .wc-forward i {
  margin-left: 3px;
}

.read_more_button:hover, .travel_tour ul.products li.product .read_more .add_to_cart_button:hover, .travel_tour ul.products li.product .read_more .wc-forward:hover {
  color: #fff;
}

.travel_tour ul.products li.product .read_more .added {
  display: none;
}

.item_rating {
  float: left;
  width: 45%;
  padding: 0 10px 0 15px;
  position: relative;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.item_rating .star-rating {
  float: left;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 6.5em;
  font-family: star;
  letter-spacing: 4px;
  margin-top: 12px !important;
  font-weight: normal;
  color: #ffb300;
}

.item_rating .star-rating:before {
  content: "\73\73\73\73\73";
  float: left;
  top: 0;
  left: 0;
  position: absolute;
}

.item_rating .star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}

.item_rating .star-rating span:before {
  content: "\53\53\53\53\53";
  top: 0;
  position: absolute;
  left: 0;
}

.item-tour {
  padding: 0 15px;
  list-style: none;
}

.item-list-tour {
  list-style: none;
}

.checklist-icon-check li {
  line-height: 22px;
}

.wrapper-blog-content {
  *zoom: 1;
}

.wrapper-blog-content:before, .wrapper-blog-content:after {
  content: "";
  display: table;
}

.wrapper-blog-content:after {
  clear: both;
}

.wrapper-blog-content article {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E8E8E8;
}

.wrapper-blog-content article {
  *zoom: 1;
}

.wrapper-blog-content article:before, .wrapper-blog-content article:after {
  content: "";
  display: table;
}

.wrapper-blog-content article:after {
  clear: both;
}

.wrapper-blog-content article:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.wrapper-blog-content article .entry-content {
  margin-top: 0;
}

.wrapper-blog-content article .img_post {
  width: 260px;
  float: left;
  text-align: center;
}

.wrapper-blog-content article .content-thumbnail {
  width: calc(100% - 260px);
  float: left;
  padding-left: 30px;
}

.wrapper-blog-content article .entry-footer > span {
  color: #000;
  margin-right: 10px;
}

.wrapper-blog-content article .entry-footer > span:last-child {
  margin-right: 0;
}

.wrapper-blog-content article .entry-footer > span a {
  opacity: 0.8;
}

.wrapper-blog-content .entry-content .entry-title {
  margin-top: 0;
  margin-bottom: 5px;
}

.wrapper-blog-content .entry-content .entry-meta {
  font-style: italic;
  opacity: 0.8;
  font-size: 90%;
}

.wrapper-blog-content .entry-content .entry-header {
  margin-bottom: 15px;
}

.wrapper-blog-content .sticky {
  background: #f7f7f7;
  padding: 30px;
  border: 1px solid #E8E8E8;
}

.search-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
  visibility: hidden;
  opacity: 0;
}

.search-overlay .background-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  z-index: 99;
}

.search-overlay.search-show {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.search-overlay .closeicon {
  position: fixed;
  right: 3%;
  top: 16px;
  width: 32px;
  height: 32px;
  opacity: 1;
  z-index: 999;
  cursor: pointer;
}

.search-overlay .closeicon:before {
  transform: rotate(45deg);
}

.search-overlay .closeicon:after {
  transform: rotate(-45deg);
}

.search-overlay .closeicon:before, .search-overlay .closeicon:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 1px;
  background-color: white;
}

.search-overlay .search-form {
  width: 100%;
  text-align: center;
  display: inline-block;
  position: fixed;
  top: 40%;
  left: 0;
  z-index: 999;
}

.search-overlay .search-form input:focus {
  outline: none;
}

.search-overlay .search-form .search-field {
  width: 400px;
  height: 60px;
  line-height: 60px;
  border: 1px solid #ddd;
  padding: 0 15px;
  font-weight: normal;
  font-size: 16px;
  vertical-align: middle;
}

.search-overlay .search-form .search-submit {
  border: none;
  height: 60px;
  line-height: 60px;
  width: 60px;
  margin-left: -4px;
  color: #333;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  vertical-align: middle;
}

.search-overlay .search-form .search-submit:hover {
  color: #fff;
}

.search-overlay .search-form .font-awesome {
  font: normal normal normal 18px/1 FontAwesome;
}

.wrapper-subscribe {
  background-position: center bottom;
  background-repeat: repeat;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  background-size: cover;
}

.wrapper-footer {
  z-index: 1;
  position: relative;
}

.wrapper-footer .wrapper-copyright {
  padding-top: 5px;
  padding-bottom: 5px;
}

.wrapper-footer a {
  color: var(--footer-fon-color);
}

.wrapper-footer .main-top-footer {
  padding: 60px 0 50px 0;
  position: relative;
}

.wrapper-footer .main-top-footer .widget-title {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.wrapper-footer ul {
  margin: 0;
}

.wrapper-footer ul li {
  list-style: none;
}

.list-arrow li {
  margin-bottom: 0;
}

.list-arrow li a {
  /*border-bottom: 1px solid #5b6366;*/
  position: relative;
  display: block;
  padding: 5px 10px 5px 20px;
}

.list-arrow li a:after {
  content: "\f105";
  display: block;
  position: absolute;
  width: 5px;
  height: 7px;
  z-index: 10;
  top: 50%;
  left: 2px;
  margin-top: -6px;
  font: normal normal normal 14px/1 FontAwesome;
}

.list-arrow li:last-child a {
  border-bottom: none;
}

.wrapper-instagram {
  margin: 0 -5px;
  overflow: hidden;
}

.wrapper-instagram a {
  float: left;
  width: 33.333%;
  padding: 5px;
}

.form-subscribe {
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #fff;
  z-index: 1;
}

.subscribe_shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.form-subscribe__title {
  margin-bottom: 5px;
  color: #fff;
  font-size: 2em;
  text-transform: uppercase;
}

.form-subscribe__description {
  font-size: 1.200em;
  margin-bottom: 20px;
}

.form-subscribe-form-wrap {
  position: relative;
}

.form-subscribe-form-wrap .mc4wp-form, .form-subscribe-form-wrap .epm-sign-up-form {
  display: inline-block;
  line-height: 50px;
  width: auto;
  border: 10px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.form-subscribe-form-wrap .mc4wp-form label, .form-subscribe-form-wrap .mc4wp-form .epm-form-field, .form-subscribe-form-wrap .epm-sign-up-form label, .form-subscribe-form-wrap .epm-sign-up-form .epm-form-field {
  margin: 0;
  width: auto;
  position: relative;
  float: left;
}

.form-subscribe-form-wrap .mc4wp-form .mc4wp-email, .form-subscribe-form-wrap .mc4wp-form .email, .form-subscribe-form-wrap .epm-sign-up-form .mc4wp-email, .form-subscribe-form-wrap .epm-sign-up-form .email {
  display: inline-block;
  width: 390px;
  height: 50px;
  line-height: 50px;
  margin: 0;
  padding: 0 20px;
  border: none;
  outline: 0;
  border-radius: 0;
  background: #fff;
  color: #333;
  float: left;
}

.form-subscribe-form-wrap .mc4wp-form .mc4wp-submit-button, .form-subscribe-form-wrap .mc4wp-form .epm-sign-up-button, .form-subscribe-form-wrap .epm-sign-up-form .mc4wp-submit-button, .form-subscribe-form-wrap .epm-sign-up-form .epm-sign-up-button {
  display: inline-block;
  height: 50px;
  width: auto;
  margin: 0 0 0 -5px !important;
  padding: 0 30px;
  border: none;
  border-radius: 0;
  color: #333;
  text-transform: uppercase;
  font-style: normal;
  vertical-align: top;
}

.form-subscribe-form-wrap .mc4wp-form .mc4wp-submit-button:focus, .form-subscribe-form-wrap .mc4wp-form .epm-sign-up-button:focus, .form-subscribe-form-wrap .epm-sign-up-form .mc4wp-submit-button:focus, .form-subscribe-form-wrap .epm-sign-up-form .epm-sign-up-button:focus {
  outline: none;
}

.form-subscribe-form-wrap .mc4wp-form .mc4wp-submit-button:hover, .form-subscribe-form-wrap .mc4wp-form .epm-sign-up-button:hover, .form-subscribe-form-wrap .epm-sign-up-form .mc4wp-submit-button:hover, .form-subscribe-form-wrap .epm-sign-up-form .epm-sign-up-button:hover {
  color: #fff;
}

.form-subscribe-form-wrap .epm-sign-up-form .epm-form-field label {
  display: none;
}

.form-subscribe input {
  height: 50px;
  line-height: 50px;
  margin: 0;
  border: none;
  background: #fff;
  border-radius: 3px;
}

.form.mc4wp-form input {
  width: auto !important;
}

.contact-info a {
  color: #ccc;
}

#copyright {
  padding-top: 10px;
}

.footer_menu {
  text-align: right;
  margin: 0;
}

.footer_menu li {
  display: inline-block;
  margin-left: 10px;
  list-style: none;
}

.wrapper-boxed {
  max-width: 1200px;
  margin: 0 auto;
}

.wrapper-boxed .site-header, .wrapper-boxed .wrapper-subscribe {
  width: 100%;
  left: auto;
  right: auto;
  max-width: 1200px;
}

.wrapper-boxed .wrapper-content .content-area {
  background: #fff;
}

.wrapper-content {
  position: relative;
  z-index: 1;
  background: #fff;
}

.wrapper-content .top_site_main {
  padding-top: 126px;
}

.wrapper-content .wrapper-breadcrumbs {
  background-color: #f2f2f2;
}

.navigation {
  text-align: center;
  border-top: 1px solid #E8E8E8;
  margin: 0 0 25px 0;
}

.navigation ul {
  margin: 30px 0 0;
}

.navigation ul li {
  display: inline-block;
  list-style: none;
}

.navigation ul li .page-numbers {
  padding: 0 5px;
  margin: 0 5px;
  width: 36px;
  height: 36px;
  display: inline-block;
  text-align: center;
  border: 1px solid #E8E8E8;
  line-height: 36px;
}

.padding-top-6x {
  padding-top: 60px;
}

.padding-bottom-6x {
  padding-bottom: 60px;
}

.padding-top-2x {
  padding-top: 20px;
}

.margin-top-6x {
  margin-top: 60px;
}

.margin-bottom-3x {
  margin-bottom: 30px !important;
}

.text-center {
  text-align: center;
}

.section-white {
  background: #fff;
}

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin: 10px 0;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

.owl-theme .owl-controls .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-controls .owl-dot span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.owl-theme .owl-controls .owl-dot span.owl-numbers {
  height: auto;
  width: auto;
  color: #FFF;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.btn-primary, .btn-second {
  text-transform: uppercase;
  //padding: 0 35px;
  //line-height: 50px;
  font-size: 1.2rem;
  border: none;
  border-radius: 0;
  color: #333;
}

.icon-btn {
  color: var(--button-background-text-color);
  text-transform: none;
  box-shadow: 0 2px 0 0 #c6a615;
  padding: 0 25px;
  display: inline-block;
  line-height: 40px;
  text-decoration: none !important;
  font-weight: 700;
}

.icon-btn i {
  font-size: 20px;
  margin-right: 7px;
  vertical-align: middle;
}

.icon-btn:hover {
  color: #fff;
}

.wpcf7-form .row-1x {
  margin-right: -10px;
  margin-left: -10px;
}

.wpcf7-form .row-1x {
  *zoom: 1;
}

.wpcf7-form .row-1x:before, .wpcf7-form .row-1x:after {
  content: "";
  display: table;
}

.wpcf7-form .row-1x:after {
  clear: both;
}

.wpcf7-form .row-1x .col-sm-6 {
  padding-right: 10px;
  padding-left: 10px;
}

.wpcf7-form .wpcf7-form-control-wrap {
  margin-bottom: 20px !important;
  display: block;
}

.wpcf7-form .wpcf7-form-control-wrap.your-message {
  margin-bottom: 0 !important;
}

.wpcf7-form .wpcf7-form-control-wrap.your-message textarea {
  margin-bottom: 0;
}

.wpcf7-form .wpcf7-form-control-wrap textarea, .wpcf7-form .wpcf7-form-control-wrap input {
  padding: 10px 20px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  text-align: left;
  width: 100%;
  display: block;
  background: #f2f2f2;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  height: 45px;
  line-height: 21px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.wpcf7-form .wpcf7-form-control-wrap textarea:focus, .wpcf7-form .wpcf7-form-control-wrap input:focus {
  outline: none;
  border-color: #ccc;
}

.wpcf7-form .wpcf7-form-control-wrap textarea {
  height: 110px;
}

.wpcf7-form .wpcf7-submit {
  border-radius: 0;
  line-height: 45px;
  padding: 0 30px;
  border: none;
  color: #333;
  text-transform: uppercase;
  box-shadow: 0 2px 0 0 #c6a615;
  font-weight: 700;
}

.wpcf7-form .wpcf7-submit:focus {
  outline: none;
}

.wpcf7-form .wpcf7-submit:hover {
  color: #fff;
}

.travel_tour #reviews #comment {
  height: 110px;
}

.comment-respond .comment-form {
  max-width: 600px;
}

.comment-respond .comment-form-author, .comment-respond .comment-form-email {
  max-width: 50%;
  display: block;
}

.comment-respond textarea, .comment-respond input[type="text"], .comment-respond input[type="email"], .comment-respond input[type="url"] {
  padding: 10px 20px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  text-align: left;
  width: 100%;
  display: block;
  background: #f2f2f2;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.comment-respond textarea:focus, .comment-respond input[type="text"]:focus, .comment-respond input[type="email"]:focus, .comment-respond input[type="url"]:focus {
  outline: none;
  border-color: #ccc;
}

.comment-respond textarea {
  height: 110px;
}

.comment-respond .form-submit .submit {
  border-radius: 3px;
  line-height: 45px;
  padding: 0 30px;
  border: none;
  color: #333;
  text-transform: uppercase;
  box-shadow: 0 2px 0 0 #c6a615;
  letter-spacing: -0.5px;
}

.comment-respond .form-submit .submit:focus {
  outline: none;
}

.comment-respond .form-submit .submit:hover {
  color: #fff;
}

.no-border-arrow .flexslider {
  border: none;
  border-radius: 0;
  box-shadow: 0 0 0;
  margin: 0;
}

.no-border-arrow .flexslider .flex-control-paging, .no-border-arrow .flexslider .flex-direction-nav {
  display: none !important;
}

.feature-image .flexslider {
  border-radius: 0;
  box-shadow: 0 0 0;
  padding: 0;
  margin: 0;
  border: none;
}

.flexslider {
  position: relative;
  margin: 0;
  background: transparent;
  border: none;
  border-radius: initial;
  box-shadow: none;
}

.flexslider .flex-direction-nav li a {
  text-decoration: none;
  overflow: hidden;
  text-shadow: none;
  box-shadow: none;
}

.flexslider .flex-direction-nav a.flex-prev:before {
  content: "\f104" !important;
  font-family: FontAwesome !important;
  font-size: 22px !important;
  line-height: 31px !important;
  display: inline-block;
}

.flexslider .flex-direction-nav a.flex-next:before {
  content: "\f105" !important;
  font-family: FontAwesome !important;
  font-size: 22px !important;
  line-height: 31px !important;
  display: block;
}

.flexslider ul.slides {
  margin: 0;
}

.flexslider ul.slides li {
  list-style: none;
  position: relative;
  overflow: hidden;
}

.flexslider ul.slides li a img {
  width: 100%;
  height: auto;
}

.flexslider ol.flex-control-nav {
  text-align: center;
  position: absolute;
  z-index: 99;
  width: 100%;
  bottom: 20px !important;
  height: 15px;
  margin: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.flexslider ol.flex-control-nav li {
  display: inline-block;
  margin: 0 2px;
}

.flexslider ol.flex-control-nav li a {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-indent: -9999px;
  display: inline-block;
  cursor: pointer;
  margin: 0 2px;
  box-shadow: none;
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.flexslider ol.flex-control-nav li a.flex-active {
  background: rgba(255, 255, 255, 0.8);
}

.flexslider ol.flex-control-nav li a:hover {
  background: rgba(255, 255, 255, 0.5);
}

.flexslider ul.flex-direction-nav {
  display: block;
  margin: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.flexslider ul.flex-direction-nav li {
  position: static;
  list-style: none;
}

.flexslider ul.flex-direction-nav li a {
  width: 31px;
  height: 32px;
  text-align: center;
  display: inline-block;
  position: absolute;
  z-index: 99;
  left: 0;
  top: 50%;
  margin-top: -16px;
}

.flexslider ul.flex-direction-nav li a i {
  color: #fff;
  font-size: 28px;
  line-height: 46px;
}

.flexslider ul.flex-direction-nav li a.flex-next {
  position: absolute;
  right: 0;
  left: auto;
}

.flexslider:hover ul.flex-direction-nav a {
  left: 0;
}

.boxed-area {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.boxed-area {
  *zoom: 1;
}

.boxed-area:before, .boxed-area:after {
  content: "";
  display: table;
}

.boxed-area:after {
  clear: both;
}

.boxed-area .home-content {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.boxed-area .home-content > .vc_row {
  margin-left: 0;
  margin-right: 0;
}

.boxed-area .home-content > .vc_row > .vc_col-sm-12 {
  padding-left: 0;
  padding-right: 0;
}

.boxed-area .wrapper-subscribe {
  max-width: 1200px;
  left: auto;
}

.boxed-area .sticky_header.affix {
  max-width: 1200px;
  left: auto;
  right: auto;
  width: 100%;
}

.text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6, .text-white p {
  color: #fff;
}

.search-results article {
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.search-results article:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.search-results article .entry-meta {
  font-style: italic;
  opacity: 0.8;
}

.error-404.not-found {
  max-width: 600px;
  margin: 60px auto;
  text-align: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin-bottom: 15px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-instagram .textwidget {
  margin-left: -10px;
  margin-right: -10px;
}

.top_site_main {
  position: relative;
  text-align: left;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background: #FFF;
  color: #fff;
}

.top_site_main.top-site-no-image {
  color: #fff;
}

.top_site_main .overlay-top-header {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  opacity: 0.4;
}

.top_site_main .banner-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}

.top_site_main .banner-wrapper .heading_primary {
  margin: 0 0 10px 0;
  color: inherit;
  display: inline-block;
  position: relative;
  line-height: 1;
}

.top_site_main .banner-wrapper .heading_primary:after {
  content: "";
  width: 40px;
  height: 2px;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -10px;
  border: 1px solid;
}

.top_site_main .banner-wrapper-destination {
  padding-top: 120px;
  padding-bottom: 120px;
}

.top_site_main .banner-wrapper-destination .heading_primary {
  margin: 0 0 10px 0;
  color: inherit;
  display: inline-block;
  position: relative;
  line-height: 1;
  //text-transform: capitalize;
}

.top_site_main .banner-wrapper-destination .desc {
  max-width: 660px;
  margin: 0 auto 60px auto;
}

.top_site_main .banner-wrapper-destination .breadcrumbs-wrapper {
  text-transform: uppercase;
}

.top_site_main .banner-wrapper-destination .desc {
  font-size: 18px;
  font-weight: normal;
}

.top_site_main .banner-wrapper-destination .heading_primary {
  font-size: 3rem;
}

.content-area {
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 400px;
}

.tours-tabs_table {
  width: 100% !important;
  height: auto !important;
  margin: 30px 0;
  border: none;
  border-top: 2px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  font-size: 0.933em;
}

.tours-tabs_table td, .tours-tabs_table th {
  padding: 20px;
  vertical-align: top;
  border: none;
  border-bottom: 1px solid #e4e4e4;
}

.tours-tabs_table table {
  border: none;
  width: 100% !important;
  height: auto !important;
  margin: 0;
  font-size: 100%;
}

.tours-tabs_table table td, .tours-tabs_table table th {
  padding: 0;
  border: none;
}

.icon-tick {
  margin-right: 10px;
}

.icon-tick--on {
  color: #01cb68;
}

.icon-tick--off {
  color: #eb7591;
}

.padding-bottom-6x {
  padding-bottom: 60px;
}

.padding-top-6x {
  padding-top: 60px;
}

.padding-top-4x {
  padding-top: 40px;
}

.mg-bt-6x {
  margin-bottom: 60px;
}

.mg-top-6x {
  margin-top: 60px;
}

.custom-instagram ul {
  overflow: hidden;
  margin: 0 -5px;
}

.custom-instagram ul li {
  width: 33.33%;
  float: left;
  padding: 5px;
  margin: 0 !important;
}

.section-background {
  background-attachment: fixed;
  background-size: cover;
}

.wrapper-bg-video {
  position: relative;
  //padding-bottom: 56.25%; /* 16:9 */
  padding-bottom: 35%;
  height: 0;
}

.wrapper-bg-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.travel_tour #review_form #respond:after, .travel_tour #reviews #comments ol.commentlist li .comment-text:after, .travel_tour #reviews #comments ol.commentlist:after, .travel_tour .travel_tour-product-rating:after, .travel_tour td.product-name dl.variation:after {
  clear: both
}

.travel_tour #reviews #comments ol.commentlist {
  margin: 0;
  width: 100%;
  background: 0 0;
  list-style: none
}

.travel_tour #reviews #comments ol.commentlist li {
  padding: 0;
  margin: 0 0 20px;
  position: relative;
  background: 0;
  border: 0
}

.travel_tour #reviews #comments ol.commentlist li .meta {
  color: #777;
  font-size: .75em
}

.travel_tour #reviews #comments ol.commentlist li img.avatar {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px;
  width: 32px;
  height: auto;
  background: #ebe9eb;
  border: 1px solid #e4e1e3;
  margin: 0;
  box-shadow: none
}

.travel_tour #reviews #comments ol.commentlist li .comment-text {
  margin: 0 0 0 50px;
  border: 1px solid #e4e1e3;
  border-radius: 4px;
  padding: 1em 1em 0
}

.travel_tour #reviews #comments ol.commentlist li .comment-text p {
  margin: 0 0 1em
}

.travel_tour #reviews #comments ol.commentlist li .comment-text p.meta {
  font-size: .83em
}

.travel_tour #reviews #comments ol.commentlist ul.children {
  list-style: none;
  margin: 20px 0 0 50px
}

.travel_tour #reviews #comments ol.commentlist ul.children .star-rating {
  display: none
}

.travel_tour #reviews #comments ol.commentlist #respond {
  border: 1px solid #e4e1e3;
  border-radius: 4px;
  padding: 1em 1em 0;
  margin: 20px 0 0 50px
}

.travel_tour #reviews #comments .commentlist > li:before {
  content: ""
}

.content-slider {
  position: absolute;
  z-index: 999;
  //top: 35%;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
}

.content-slider.carousel-caption {
  left: 0;
  right: 0;
  top: 30%;
}

.content-slider.carousel-caption p {
  line-height: 36px;
}

.content-slider.carousel-caption h2 {
  margin-bottom: 50px;
  position: relative;
}

.content-slider.carousel-caption h2:before {
  content: "";
  width: 60px;
  height: 3px;
  background: #FFD200;
  left: 50%;
  margin-left: -30px;
  display: inline-block;
  position: absolute;
  bottom: -25px;
}

.content-slider p {
  font-size: 26px;
}

.content-slider h2 {
  font-size: 2.8rem;
  color: #fff;
  //font-weight: bold;
}

.content-slider .btn-slider {
  margin-top: 20px;
  display: inline-block;
  color: var(--button-background-text-color);
  background: var(--button-background);
  font-size: 16px;
  padding: 9px 28px;
  border-radius: var(--button-radius);
  font-weight: 600;
}

.content-slider .btn-slider:hover {
  background: var(--button-background-hover);
  color: var(--button-background-text-hover);
}

.sc-gallery .gallery_item-wrap {
  padding-bottom: 30px;
}

.sc-gallery .gallery_item-wrap .gallery-item {
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}

.sc-gallery .gallery_item-wrap .gallery-item .title {
  display: block;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 0;
  right: 0;
  padding: 20px 25px;
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  z-index: 2;
  overflow: hidden;
  text-transform: uppercase;
}

.sc-gallery .gallery_item-wrap a.swipebox {
  display: block;
  position: relative;
  border-bottom: none;
  overflow: hidden;
}

.sc-gallery .gallery_item-wrap a.swipebox:hover .gallery-item {
  opacity: 1;
}

.sc-gallery .gallery-tabs-wrapper.filters {
  min-height: 62px;
  line-height: 62px;
  text-align: center;
  background: #f9f9f9;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
  margin-bottom: 35px;
}

.sc-gallery .gallery-tabs-wrapper.filters ul {
  margin: 0;
}

.sc-gallery .gallery-tabs-wrapper.filters li {
  display: inline-block;
  border-left: 1px solid #e9e9e9;
  list-style: none;
}

.sc-gallery .gallery-tabs-wrapper.filters li:last-child {
  border-right: 1px solid #e9e9e9;
}

.sc-gallery .gallery-tabs-wrapper.filters li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 35px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  border-bottom: none;
}

#travel-tips .panel {
  border: none;
  box-shadow: 0 0 0;
  border-radius: 0;
  margin: 0 0 -1px 0;
}

#travel-tips .panel-heading {
  border: 1px solid #f7f7f7;
  margin-bottom: -1px;
  border-radius: 0;
  padding: 15px 20px;
}

#travel-tips .panel-collapse {
  border: 1px solid #f7f7f7;
  border-radius: 0;
}

#travel-tips .panel-collapse .content-inner {
  padding: 20px;
}

.carousel-control-left, .carousel-control-right {
  position: absolute;
  top: 50%;
  font-size: 40px;
  line-height: 40px;
  left: 20px;
  margin-top: -20px;
  color: #fff;
}

.carousel-control-right {
  left: auto;
  right: 20px;
}

.wrapper-tours-slider.arrow-black .tours-type-slider .owl-nav .owl-prev, .wrapper-tours-slider.arrow-black .tours-type-slider .owl-nav .owl-next {
  color: #333;
}

.wrapper-tours-slider.arrow-black .owl-theme .owl-controls .owl-dot span {
  border-color: #848484;
}

.wrapper-tours-slider.arrow-black .owl-controls .owl-dot.active span {
  background: #848484;
}

.list-posts.list-post-style_2 {
  margin-left: 0;
  margin-right: 0;
}

.list-posts.list-post-style_2 .shortcode_title {
  text-align: left;
  margin-bottom: 25px;
}

.list-posts.list-post-style_2 .post_list_content_unit {
  width: 100%;
  margin-bottom: 15px;
}

.list-posts.list-post-style_2 .post_list_content_unit:last-child {
  margin-bottom: 0;
}

.list-posts.list-post-style_2 .post_list_content_unit .img_post.feature-image {
  width: 90px;
  float: left;
  margin-right: 15px;
  margin-bottom: 0;
}

.list-posts.list-post-style_2 .post_list_content_unit .post-list-content {
  overflow: hidden;
}

.list-posts.list-post-style_2 .post_list_content_unit .post-list-content .post_list_title {
  font-size: 1.2em;
  font-weight: 600;
}

.list-posts.list-post-style_2 .post_list_content_unit .post-list-content .wrapper-meta {
  line-height: 1em;
}

.border-below {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 0;
  color: #fff;
}

.border-below p {
  max-width: 400px;
  margin: 0 auto 25px auto;
  color: rgba(255, 255, 255, 0.8);
}

.border-below h2 {
  color: #fff;
  font-size: 3em;
}

.banner-tour-booking {
  margin-top: -100px;
  position: relative;
  background: #fff;
}

.text-banner-home4 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.banner-typed {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.banner-typed .phys-typingEffect {
  font-size: 4em;
  color: #fff;
}

.banner-typed .desc {
  font-size: 1.4em;
  color: #fff;
}

.banner-typed .typed-cursor {
  opacity: 1;
  font-weight: normal;
  font-size: 90%;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@media (min-width: 768px) and (max-width: 992px) {
  .content-area .site-main, .content-area .widget-area {
    /* width: 100%;
     float: none;*/
  }

  .slider-tour-booking {
    margin-top: 0;
    background-color: rgba(67, 67, 67, 1);
  }

  .content-slider {
    top: 40%;
  }

  .fix-ipad-deals-discounts .vc_col-sm-8 {
    width: 100%;
  }

  .tour-reviews-item .reviews-item-description {
    display: none;
  }

  .content-slider.carousel-caption {
    top: 40px;
  }
}

@media (min-width: 993px) and (max-width: 1024px) {
  .wrapper-tours-slider .tours-type-slider .owl-nav {
    display: none !important;
  }

  .tour-reviews-item .reviews-item-description {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .content-slider.carousel-caption {
    top: 22%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .wrapper-tours-slider .tours-type-slider .owl-nav .owl-prev {
    left: -40px;
  }

  .wrapper-tours-slider .tours-type-slider .owl-nav .owl-next {
    right: -40px;
  }

}

@media (min-width: 768px) {

  .wrapper-tours-slider.shortcode-tour-reviews .owl-dots {
    display: block !important;
  }
}

@media (min-width: 993px) {

  .after_slider.sticky-header {
    position: relative;
  }

  .sticky_header.affix.menu-hidden {
    transform: translate(0, -100%);
    -webkit-transform: translate(0, -100%);
    -moz-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    -o-transform: translate(0, -100%);
  }

  .width-navigation .menu-right {
    margin: 0;
    display: inline-block;
  }

  .width-navigation .menu-right {
    *zoom: 1;
  }

  .width-navigation .menu-right:before, .width-navigation .menu-right:after {
    content: "";
    display: table;
  }

  .width-navigation .menu-right:after {
    clear: both;
  }

  .width-navigation .navbar-nav {
    display: inline-block;
    width: 100%;
  }

  .width-navigation.table-right {
    text-align: right;
  }

  .navigation-menu {
    position: relative;
    padding: 15px 0;
  }

  .navigation-menu .tm-table {
    width: 100%;
  }

  .navigation-menu nav {
    position: static;
  }

  .navigation-menu .navbar-nav li.menu-item-has-children .sub-menu {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
    -ms-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
  }

  .navigation-menu .navbar-nav li.menu-item-has-children .sub-menu li {
    padding: 0 20px;
  }

  .navigation-menu .navbar-nav li.menu-item-has-children .sub-menu li:after {
    content: "";
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    height: 1px;
  }

  .navigation-menu .navbar-nav li.menu-item-has-children .sub-menu li a, .navigation-menu .navbar-nav li.menu-item-has-children .sub-menu li > span.disable_link {
    -webkit-transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
    -ms-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    padding: 12px 0;
    display: block;
    border-bottom: 1px dashed #fff;
  }

  .navigation-menu .navbar-nav li.menu-item-has-children .sub-menu li:last-child:after {
    display: none;
  }

  .navigation-menu .navbar-nav li.menu-item-has-children .sub-menu li:last-child a, .navigation-menu .navbar-nav li.menu-item-has-children .sub-menu li:last-child > span.disable_link {
    border-bottom: 1px dashed transparent;
  }

  .navigation-menu .menu-right {
    padding-left: 0;
    float: right !important;
  }

  .navigation-menu .menu-right li {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
  }

  .navigation-menu .menu-right li:last-child {
    padding-right: 0;
  }

  .navbar-nav a i.fa {
    margin-right: 5px;
  }

  .navbar-nav > li > span:hover {
    background: transparent;
  }

  nav .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }

  nav ul, nav ul ul, nav ul ul ul {
    margin: 0;
  }

  nav ul li, nav ul ul li, nav ul ul ul li {
    margin: 0;
  }

  .nav > li > a:hover, .nav > li > a:focus {
    background: transparent;
  }

  .navbar-nav {
    float: none;
  }

  .navbar-nav > li {
    vertical-align: middle;
    display: inline-block;
    float: none;
    //margin-left: -5px;
    position: relative;
    padding: 0 15px;
    z-index: 20;
  }

  .navbar-nav > li:after {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    z-index: 10;
    top: 100%;
    left: 50%;
    margin-top: 40px;
    visibility: hidden;
    opacity: 0;
    margin-left: -4px;
    border-style: solid;
    border-width: 0 4px 4px 4px;
  }

  .navbar-nav > li:before {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    left: 14px;
    right: 14px;
    z-index: 10;
    top: 100%;
    margin-top: 40px;
    visibility: hidden;
    opacity: 0;
  }

  .navbar-nav > li:last-child {
    margin-left: 0;
    padding-right: 0;
  }

  .navbar-nav > li > a, .navbar-nav > li > span.disable_link {
    padding: 22px 0 22px;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    line-height: 100%;
    vertical-align: bottom;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .navbar-nav > li.item-border > a {
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    border: 2px solid;
    padding: 7px 20px;
  }

  .navbar-nav > li > .sub-menu {
    margin-top: 20px;
  }

  .navbar-nav > li.menu-right:after, .navbar-nav > li.menu-right:before {
    display: none;
  }

  .navbar-nav > li.menu-right:hover {
    background-color: transparent !important;
  }

  .navbar-nav > li.menu-right .widget {
    margin: 0;
  }

  .navbar-nav > li.menu-right .widget .widget-social a {
    padding-left: 25px;
    font-size: 18px;
  }

  .navbar-nav > li.menu-right .widget .widget-social a:first-child {
    padding-left: 5px;
  }

  .navbar-nav > li.menu-right ul {
    margin: 0;
  }

  .navbar-nav > li.menu-right ul > li {
    padding: 0 20px;
  }

  .navbar-nav > li.menu-right ul > li:last-child {
    padding-right: 0;
  }

  .navbar-nav > li > ul.sub-menu > li > .sub-menu, .navbar-nav > li > ul.sub-menu {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .navbar-nav > li > ul.sub-menu {
    visibility: hidden;
    width: 225px;
    position: absolute;
    top: 100%;
    left: -15px;
    margin-top: 25px;
    padding: 10px 0 10px 0;
    background-color: #fff;
    list-style: none;
    opacity: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }

  .navbar-nav > li > ul.sub-menu > li {
    margin-bottom: 5px;
    position: relative;
  }

  .navbar-nav > li > ul.sub-menu > li a {
    display: block;
    position: relative;
    padding: 6px 20px 6px 20px;
    font-size: 13px;
    font-weight: 400;
    z-index: 15;
    color: #333;
  }

  .navbar-nav > li:hover:after, .navbar-nav > li.current-menu-item:after, .navbar-nav > li.current_page_parent:after, .navbar-nav > li.current-menu-ancestor:after {
    margin-top: -4px;
    visibility: visible;
    opacity: 1;
    z-index: 9;
  }

  .navbar-nav > li:hover:before, .navbar-nav > li.current-menu-item:before, .navbar-nav > li.current_page_parent:before, .navbar-nav > li.current-menu-ancestor:before {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    z-index: 9;
  }

  .navbar-nav > li:hover > ul.sub-menu {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }

  .navbar-nav li .sub-menu {
    visibility: hidden;
    opacity: 0;
    margin-top: 25px;
    position: absolute;
    left: 25px;
    z-index: 9999;
    background: #fff;
    top: auto;
    padding: 8px 0;
  }

  .navbar-nav li .sub-menu li {
    list-style: none;
    text-align: left;
    position: relative;
  }

  .navbar-nav li .sub-menu li a {
    text-decoration: none;
    white-space: nowrap;
  }

  .navbar-nav li:hover > .sub-menu {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }

  .navbar-nav li.menu-item-has-children .sub-menu {
    min-width: 160px;
    margin-left: 0;
  }

  .navbar-nav li.menu-item-has-children .sub-menu li {
    width: 100%;
  }

  .navbar-nav li .sub-menu .sub-menu {
    left: 100%;
    top: 0;
  }

  .navbar-nav li .sub-menu li:hover .sub-menu {
    height: auto;
    z-index: 999;
  }

  .admin-bar .sticky_header.affix {
    top: 32px;
  }

  .admin-bar .boxed-area .sticky_header.affix {
    top: auto;
  }

  .mobile-menu-open {
    height: 100% !important;
    margin-top: 0 !important;
  }

  .mobile-menu-open.wrapper-container {
    position: relative;
    overflow: hidden;
  }
}

@media (min-width: 1025px) {
  .wrapper-tours-slider .owl-dots {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .wrapper-tours-slider .tours-type-slider .owl-nav {
    display: none !important;
  }

  .section-white .list_content .owl-controls .owl-dot span {
    border-color: rgba(0, 0, 0, 0.2);
  }

  .section-white .list_content .owl-controls .owl-dot.active span {
    border-color: #26bdf7;
    background-color: #26bdf7;
  }

  .wrapper-tours-slider .owl-dots {
    text-align: center;
    margin-top: 30px;
  }
}

@media (max-width: 993px) {
  .travel-booking-search form {
    padding: 20px 0;
  }

  .travel-booking-search .hb-form-table {
    width: 100%;
  }

  .travel-booking-search .hb-form-table li {
    width: 50%;
  }

  .travel-booking-search .hb-form-table li.hb-submit {
    width: 100%;
    text-align: center;
  }

  .travel-booking-search .hb-form-table li.hb-submit button {
    width: 50%;
  }

  .travel-booking-search.travel-booking-style_2 .hb-form-table {
    width: 100%;
  }

  .travel-booking-search.travel-booking-style_2 .hb-form-table li {
    width: 50%;
  }

  .travel-booking-search.travel-booking-style_2 .hb-form-table li.hb-submit {
    width: 100%;
    text-align: center;
  }

  .travel-booking-search.travel-booking-style_2 .hb-form-table li.hb-submit button {
    width: 50%;
  }

  .travel-booking-search.travel-booking-style_2 .hb-form-field-select:after {
    display: none;
  }

  .search-toggler-unit .search-toggler {
    display: inline-block;
  }

  .item-list-tour .content-list-tour .wrapper_content {
    padding: 20px;
  }

  .item-list-tour .content-list-tour .wrapper_content:before {
    right: 150px;
  }

  .item-list-tour .content-list-tour .wrapper_content .content-left {
    padding-right: 20px;
    width: calc(100% - 134px);
  }

  .item-list-tour .content-list-tour .wrapper_content .content-left .post_date {
    margin-top: 10px;
  }

  .item-list-tour .content-list-tour .wrapper_content .content-right {
    width: 130px;
    padding-left: 20px;
  }

  .item-list-tour .content-list-tour .wrapper_content .content-right .add_to_cart_button {
    padding: 0 15px;
    font-weight: normal;
    font-size: 90%;
  }

  .travel_tour ul.products li.product, .travel_tour-page ul.products li.product {
    width: 50%;
  }

  .travel_tour ul.products li.product:nth-child(3n+1), .travel_tour-page ul.products li.product:nth-child(3n+1) {
    clear: none !important;
  }

  .travel_tour ul.products li.product:nth-child(2n+1), .travel_tour-page ul.products li.product:nth-child(2n+1) {
    clear: both;
  }

  .navbar-nav > li > a {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .site-header .navbar-nav .menu-right {
    margin-top: 15px;
  }

  .site-header .navbar-nav .menu-right ul {
    margin-left: 15px;
  }

  .site-header .navbar-nav .sub-menu li:first-child {
    margin-top: 0;
  }

  .fix-col-responsive .wpb_column {
    width: 100%;
  }

  .fix-col-responsive .wpb_column .list-posts {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-toggle {
    float: none;
    display: inline-block;
    vertical-align: middle;
    padding: 9px 25px;
  }

  .header_v1 .wrapper-logo {
    display: none;
  }

  .header_v1.affix-top .sticky-logo {
    display: block;
  }

  .site-header .navbar-nav {
    //margin: 0;
    //text-align: left;
  }

  .site-header .navbar-nav li {
    list-style: none;
    position: relative;
    display: block;
  }

  .site-header .navbar-nav .sub-menu {
    position: relative;
    background: transparent;
  }

  .site-header .navbar-nav .sub-menu li {
    border: none !important;
  }

  .site-header .navbar-nav .sub-menu li a {
    padding-left: 15px !important;
  }

  .navbar-nav .sub-menu {
    overflow: hidden;
  }

  .navbar-nav .sub-menu .sub-menu {
    margin-left: 20px;
  }

  .nav > li > span {
    padding: 10px 15px;
  }

  .site-header .navigation-menu {
    padding: 10px 0;
  }

  .site-header .navigation-menu .width-navigation {
    display: block;
  }

  .side-nav {
    position: fixed;
    width: 240px;
    left: -105%;
    top: 0;
    margin: 0;
    padding-bottom: 60px;
    z-index: 999;
    overflow-y: auto;
    will-change: left;
    padding-top: 20px;
    height: 100%;
    background: #000;
  }

  .side-nav.right-aligned {
    will-change: right;
    right: -105%;
    left: auto;
  }

  .side-nav .collapsible {
    margin: 0;
  }

  .side-nav li {
    float: none;
  }

  .side-nav li .sub-menu {
    margin: 0;
    margin-left: 15px;
  }

  .side-nav a {
    display: block;
    padding: 1px 15px;
  }

  .nav > li > a:hover, .nav > li > a:focus {
    background: transparent;
  }

  .navbar-nav {
    margin: 0;
  }

  .navbar-nav li {
    list-style: none;
    position: relative;
  }

  .navbar-nav .sub-menu li {
    margin-top: 9px;
  }

  .navbar-nav .sub-menu .sub-menu {
    margin-left: 20px;
    margin-top: 10px;
  }

  body, html, .mobile-menu-open {
    height: 100% !important;
    margin-top: 0 !important;
  }

  .menu-mobile-effect {
    float: none;
    display: inline-block;
    cursor: pointer;
    z-index: 1;
    vertical-align: middle;
    padding: 0;
    margin: 0 15px 0 0;
  }

  .menu-mobile-effect span {
    background-color: #fff;
  }

  .sub-menu {
    position: relative;
  }

  .sub-menu li {
    border: none !important;
  }

  .sub-menu li a {
    padding-left: 25px !important;
  }

  .nav > li > span {
    padding: 10px 15px;
  }

  .travel_tour-checkout .columns .order-wrapper {
    margin-left: 0;
    margin-top: 20px;
  }

  .bg__shadow.section-background .col-sm-8 {
    width: 100%;
  }

  .tours-type-slider > .tours_type_item {
    width: 25%;
  }

  .tours-type-slider > .item-tour {
    width: 33.333%;
  }

  .tours-type-pain .tours_type_item .count-attr {
    font-size: 1em;
  }

  .tours-type-pain .tours_type_item .item__title {
    font-size: 1.6em;
  }

}

@media (max-width: 767px) {
  .border-below {
    padding: 10px 0 30px 0;
  }

  .alignright, .alignleft {
    float: none;
  }

  .post_list_content_unit {
    width: 50%;
    float: left;
  }

  .widget-icon-box, .stats_counter {
    margin-bottom: 25px;
  }

  .header_top_bar {
    display: none;
  }

  .wrapper-tours-slider {
    margin: 0 -10px;
  }

  .tours-type-pain .item-tour {
    width: 50%;
    float: left;
  }

  .tours-type-pain .item_border {
    margin-bottom: 20px;
  }

  .shortcode-tour-reviews {
    margin-bottom: 35px;
  }

  .wrapper-tours-slider .item-tour {
    padding: 0 10px;
  }

  .related.tours .wrapper-tours-slider .item-tour {
    padding: 0 15px;
  }

  .travel-booking-search {
    margin: 0 -10px;
  }

  .tours-type-pain .item-tour {
    width: 50%;
    float: left;
  }

  .tours-type-pain .item_border {
    margin-bottom: 20px;
  }

  .counter-group {
    margin: 10px auto;
  }

  .counter-block {
    margin-right: 8px;
  }

  .counter-block .counter {
    width: 120px;
    height: 80px;
    overflow: hidden;
    font-weight: 700;
  }

  .counter-block .counter .number {
    font-size: 70px;
    line-height: 78px;
  }

  .counter-block .counter.with-hundreds {
    width: 180px;
  }

  .counter-block .counter-caption {
    font-size: 100%;
  }

  .travel_tour, .travel_tour-page {
  }

  .travel_tour table.shop_table_responsive thead, .travel_tour-page table.shop_table_responsive thead {
    display: none;
  }

  .travel_tour table.shop_table_responsive tbody tr:first-child td:first-child, .travel_tour-page table.shop_table_responsive tbody tr:first-child td:first-child {
    border-top: 0;
  }

  .travel_tour table.shop_table_responsive tbody th, .travel_tour-page table.shop_table_responsive tbody th {
    display: none;
  }

  .travel_tour table.shop_table_responsive tr, .travel_tour-page table.shop_table_responsive tr {
    display: block;
  }

  .travel_tour table.shop_table_responsive tr td, .travel_tour-page table.shop_table_responsive tr td {
    display: block;
    text-align: right !important;
  }

  .travel_tour table.shop_table_responsive tr td.order-actions, .travel_tour-page table.shop_table_responsive tr td.order-actions {
    text-align: left !important;
  }

  .travel_tour table.shop_table_responsive tr td:before, .travel_tour-page table.shop_table_responsive tr td:before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
  }

  .travel_tour table.shop_table_responsive tr td.product-remove:before, .travel_tour table.shop_table_responsive tr td.actions:before, .travel_tour-page table.shop_table_responsive tr td.product-remove:before, .travel_tour-page table.shop_table_responsive tr td.actions:before {
    display: none;
  }

  .travel_tour table.shop_table_responsive tr:nth-child(2n) td, .travel_tour-page table.shop_table_responsive tr:nth-child(2n) td {
    background-color: rgba(0, 0, 0, 0.025);
  }

  .travel_tour .col2-set .col-1, .travel_tour .col2-set .col-2, .travel_tour-page .col2-set .col-1, .travel_tour-page .col2-set .col-2 {
    float: none;
    width: 100%;
  }

  .travel_tour table.cart .product-thumbnail, .travel_tour #content table.cart .product-thumbnail, .travel_tour-page table.cart .product-thumbnail, .travel_tour-page #content table.cart .product-thumbnail {
    display: none;
  }

  .travel_tour table.cart td.actions, .travel_tour #content table.cart td.actions, .travel_tour-page table.cart td.actions, .travel_tour-page #content table.cart td.actions {
    text-align: left;
  }

  .travel_tour table.cart td.actions .coupon, .travel_tour #content table.cart td.actions .coupon, .travel_tour-page table.cart td.actions .coupon, .travel_tour-page #content table.cart td.actions .coupon {
    float: none;
    padding-bottom: .5em;
  }

  .travel_tour table.cart td.actions .coupon, .travel_tour #content table.cart td.actions .coupon, .travel_tour-page table.cart td.actions .coupon, .travel_tour-page #content table.cart td.actions .coupon {
    *zoom: 1;
  }

  .travel_tour table.cart td.actions .coupon:before, .travel_tour table.cart td.actions .coupon:after, .travel_tour #content table.cart td.actions .coupon:before, .travel_tour #content table.cart td.actions .coupon:after, .travel_tour-page table.cart td.actions .coupon:before, .travel_tour-page table.cart td.actions .coupon:after, .travel_tour-page #content table.cart td.actions .coupon:before, .travel_tour-page #content table.cart td.actions .coupon:after {
    content: "";
    display: table;
  }

  .travel_tour table.cart td.actions .coupon:after, .travel_tour #content table.cart td.actions .coupon:after, .travel_tour-page table.cart td.actions .coupon:after, .travel_tour-page #content table.cart td.actions .coupon:after {
    clear: both;
  }

  .travel_tour table.cart td.actions .coupon input, .travel_tour table.cart td.actions .coupon .button, .travel_tour table.cart td.actions .coupon .input-text, .travel_tour #content table.cart td.actions .coupon input, .travel_tour #content table.cart td.actions .coupon .button, .travel_tour #content table.cart td.actions .coupon .input-text, .travel_tour-page table.cart td.actions .coupon input, .travel_tour-page table.cart td.actions .coupon .button, .travel_tour-page table.cart td.actions .coupon .input-text, .travel_tour-page #content table.cart td.actions .coupon input, .travel_tour-page #content table.cart td.actions .coupon .button, .travel_tour-page #content table.cart td.actions .coupon .input-text {
    width: 48%;
    box-sizing: border-box;
  }

  .travel_tour table.cart td.actions .coupon .input-text + .button, .travel_tour table.cart td.actions .coupon .button.alt, .travel_tour #content table.cart td.actions .coupon .input-text + .button, .travel_tour #content table.cart td.actions .coupon .button.alt, .travel_tour-page table.cart td.actions .coupon .input-text + .button, .travel_tour-page table.cart td.actions .coupon .button.alt, .travel_tour-page #content table.cart td.actions .coupon .input-text + .button, .travel_tour-page #content table.cart td.actions .coupon .button.alt {
    float: right;
  }

  .travel_tour table.cart td.actions .button, .travel_tour #content table.cart td.actions .button, .travel_tour-page table.cart td.actions .button, .travel_tour-page #content table.cart td.actions .button {
    display: block;
    width: 100%;
  }

  .travel_tour .cart-collaterals .cart_totals, .travel_tour .cart-collaterals .shipping_calculator, .travel_tour .cart-collaterals .cross-sells, .travel_tour-page .cart-collaterals .cart_totals, .travel_tour-page .cart-collaterals .shipping_calculator, .travel_tour-page .cart-collaterals .cross-sells {
    width: 100%;
    float: none;
    text-align: left;
  }

  .travel_tour.travel_tour-checkout form.login .form-row, .travel_tour-page.travel_tour-checkout form.login .form-row {
    width: 100%;
    float: none;
  }

  .travel_tour #payment .terms, .travel_tour-page #payment .terms {
    text-align: left;
    padding: 0;
  }

  .travel_tour #payment #place_order, .travel_tour-page #payment #place_order {
    float: none;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1em;
  }

  .travel_tour .lost_reset_password .form-row-first, .travel_tour .lost_reset_password .form-row-last, .travel_tour-page .lost_reset_password .form-row-first, .travel_tour-page .lost_reset_password .form-row-last {
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .travel_tour-account .travel_tour-MyAccount-navigation, .travel_tour-account .travel_tour-MyAccount-content {
    float: none;
    width: 100%;
  }

  .wrapper-footer ul {
    text-align: left;
  }

  .wrapper-footer ul li {
    margin-left: 0;
    margin-right: 10px;
  }

  .tb_single_tour .images_single_left, .travel_tour .images_single_left, .travel_tour-page .images_single_left {
    //width: 55%;
  }

  .tb_single_tour .description_single, .travel_tour .description_single, .travel_tour-page .description_single {
    //width: 45%;
  }

  .single-woo-tour .description_single .affix-sidebar {
    top: 0;
    margin-top: 30px;
  }

  .images_single_left .tour_after_title .meta_date, .images_single_left .tour_after_title .meta_values, .images_single_left .tour_after_title .travel_tour-product-rating {
    width: 100%;
    margin-bottom: 10px;
  }

  .images_single_left .tour_after_title .travel_tour-product-rating {
    margin-bottom: 0;
    text-align: left;
  }

  .images_single_left .tour_after_title .travel_tour-product-rating .star-rating {
    float: left !important;
  }

  .wrapper-blog-content .sticky {
    padding: 15px;
  }

  .wrapper-blog-content article .img_post {
    width: 220px;
  }

  .wrapper-blog-content article .content-thumbnail {
    width: calc(100% - 220px);
    padding-left: 20px;
  }

  .slider-tour-booking {
    margin-top: -222px;
  }

  .list-posts {
    margin-right: 0;
    margin-left: 0;
  }

  .list-posts .post_list_content_unit {
    width: 50%;
    float: left;
  }

  .comments-area .comment-respond .comment-form {
    padding: 20px;
  }

  .comments-area .comment-respond .comment-form .comment-form-author, .comments-area .comment-respond .comment-form .comment-form-email, .comments-area .comment-respond .comment-form .comment-form-url, .comments-area .comment-respond .comment-form .comment-form-phone {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .comments-area .comment-respond .comment-form .form-submit input#submit {
    margin-bottom: 10px;
  }

  .two-column-respon .wpb_column {
    width: 50%;
    float: left;
  }

  .two-column-respon .wpb_column:nth-child(2n+1) {
    clear: both;
  }

  .wrapper-tours-slider .tours-type-slider .owl-nav {
    display: none !important;
  }

  .wrapper-tours-slider .tours-type-slider .owl-nav .owl-prev, .wrapper-tours-slider .tours-type-slider .owl-nav .owl-next {
    display: none !important;
  }

  .wrapper-tours-slider .owl-controls {
    margin: 30px 0 0 0;
  }

  .wrapper-tours-slider .owl-dots {
    text-align: center;
  }

  .wrapper-tours-slider .owl-controls .owl-dot span {
    border-color: #bbbaba;
  }

  .travel_tour div.product .single-tour-tabs .panel-group .panel-default {
    margin-bottom: 15px;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 0;
  }

  .travel_tour div.product .single-tour-tabs .panel-group .panel-default .panel-heading {
    padding: 0;
    border: none !important;
    border-radius: 0;
  }

  .travel_tour div.product .single-tour-tabs .panel-group .panel-default .panel-heading .panel-title a {
    display: block;
    padding: 10px 15px;
  }

  .travel_tour div.product .single-tour-tabs .panel-group .panel-collapse {
    border: 1px solid #f5f5f5;
    border-top: none;
  }

  .travel_tour div.product .single-tour-tabs .panel-group .panel-collapse .panel-body {
    border: none !important;
  }

  .content-area {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .content-slider.carousel-caption {
    top: 40px;
  }

  .content-slider.carousel-caption .btn-slider {
    display: none;
  }

  .carousel-control-left, .carousel-control-right {
    display: none !important;
  }

  .home-page-image-banner .col-sm-12 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .shortcode_title .title_subtitle {
    font-size: 1em;
  }
}

@media (max-width: 668px) {
  .travel_tour .tb_single_tour .images_single_left, .travel_tour .tb_single_tour .description_single, .travel_tour-page .tb_single_tour .images_single_left, .travel_tour-page .tb_single_tour .description_single {
    //width: 100%;
    float: none;
  }

  .travel_tour .images_single_left, .travel_tour .description_single, .travel_tour-page .images_single_left, .travel_tour-page .description_single {
    width: 100%;
    float: none;
  }

  .travel_tour div.product .single-tour-tabs ul.tabs li a, .travel_tour-page div.product .single-tour-tabs ul.tabs li a {
    height: 43px;
    line-height: 43px;
    padding: 0 25px;
  }
}

@media (max-width: 600px) {
  .item-list-tour .content-list-tour .post_images {
    width: 100%;
    float: none;
  }

  .item-list-tour .content-list-tour .post_images a {
    display: block;
  }

  .item-list-tour .content-list-tour .wrapper_content {
    padding: 20px;
    width: 100%;
    float: none;
  }

  .post-navigation .nav-links .nav-previous, .post-navigation .nav-links .nav-next {
    width: 100%;
    text-align: left;
    float: left;
  }

  .comments-area .comment-respond .comment-form {
    padding: 0 15px;
  }

  .comments-area .comment-list-inner .comments-title {
    padding: 0 10px;
    margin: 0 10px;
  }

  .comments-area .comment-list {
    padding: 20px 0;
  }

  .comments-area .comment-list-inner li .content-comment {
    padding: 15px 0;
  }

  .comments-area .comment-list-inner li .wrapper_avatar {
    float: none;
    margin: 0 auto;
  }

  .comments-area .comment-list-inner li .comment-right {
    width: 100%;
    float: none;
    margin-top: 20px;
    padding: 0;
  }

  .comments-area .comment-list-inner li .children {
    margin-left: 0;
  }

  .comments-area .comment-list-inner li .comment-extra-info .text-left .date {
    padding-left: 10px;
  }

  .form-subscribe-form-wrap .epm-sign-up-form .mc4wp-email, .form-subscribe-form-wrap .epm-sign-up-form .email, .form-subscribe-form-wrap .mc4wp-form .mc4wp-email, .form-subscribe-form-wrap .mc4wp-form .email {
    width: auto;
  }

  .travel_tour-checkout .columns.col-md-5 {
    padding: 0;
  }

  .travel_tour-checkout .columns .order-wrapper {
    padding: 15px 15px 0 15px;
  }

  .travel_tour-checkout .columns .order-wrapper .form-row.place-order {
    padding-bottom: 5px !important;
    margin-bottom: 0;
  }

  .shortcode_title.shortcode-title-style_3 {
    text-align: center;
  }

  .shortcode_title.shortcode-title-style_3 .title_primary, .shortcode_title.shortcode-title-style_3 .title_subtitle {
    float: none;
  }

  .banner-typed .phys-typingEffect {
    font-size: 3em;
  }
}

@media (max-width: 480px) {
  .border-below h2 {
    font-size: 2.6em;
  }

  .tours-type-pain .item-tour {
    width: 100%;
    float: none;
  }

  .form-subscribe-form-wrap .mc4wp-form .mc4wp-email, .form-subscribe-form-wrap .mc4wp-form .email, .form-subscribe-form-wrap .epm-sign-up-form .mc4wp-email, .form-subscribe-form-wrap .epm-sign-up-form .email {
    width: auto;
  }

  .form-subscribe {
    padding: 50px 15px;
  }

  .travel_tour ul.products li.product, .travel_tour-page ul.products li.product {
    width: 100%;
  }

  .wrapper-blog-content article .img_post {
    width: 100%;
    margin-bottom: 20px;
  }

  .wrapper-blog-content article .img_post img {
    width: 100%;
  }

  .wrapper-blog-content article .content-thumbnail {
    width: 100%;
    padding-left: 0;
  }

  .list-posts .post_list_content_unit {
    width: 100%;
    float: left;
  }

  .custom-instagram #sb_instagram .sbi_item {
    width: 50% !important;
  }

  .custom-instagram #sb_instagram .sbi_item .sbi_photo {
    height: 130px !important;
  }

  .counter-group {
    margin: 0 auto;
  }

  .counter-block {
    margin-right: 4px;
  }

  .counter-block .counter {
    width: 96px;
    height: 64px;
  }

  .counter-block .counter .number {
    font-size: 56px;
    line-height: 63px;
  }

  .counter-block .counter.with-hundreds {
    width: 144px;
  }

  .counter-block .counter-caption {
    font-size: 85%;
  }

  .content-slider {
    top: 70px;
  }

  .content-slider p {
    font-size: 16px;
    margin-bottom: 0;
  }

  .content-slider h2 {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .content-slider .btn-slider {
    margin-top: 10px;
    font-size: 14px;
    padding: 9px 25px;
  }

  .slider-tour-booking {
    margin-top: 0;
    background-color: rgba(67, 67, 67, 1);
  }

  .content-slider.carousel-caption {
    top: 30%;
  }

  .content-slider.carousel-caption p {
    display: none;
  }

  .tours-type-pain .tours_type_item {
    width: 50%;
  }

  .tours-type-pain .tours_type_item.width2x3 {
    width: 100%;
  }

  .tours-type-pain .tours_type_item:nth-child(3) {
    clear: none;
  }
}

@media (max-width: 420px) {
  .form-subscribe-form-wrap .mc4wp-form, .form-subscribe-form-wrap .epm-sign-up-form {
    text-align: left;
    border: none;
    width: 100%;
  }

  .form-subscribe-form-wrap .mc4wp-form .epm-form-field, .form-subscribe-form-wrap .epm-sign-up-form .epm-form-field {
    margin-bottom: 10px;
  }

  .form-subscribe-form-wrap .mc4wp-form .mc4wp-email, .form-subscribe-form-wrap .mc4wp-form .email, .form-subscribe-form-wrap .mc4wp-form .epm-form-field, .form-subscribe-form-wrap .mc4wp-form .epm-sign-up-button, .form-subscribe-form-wrap .epm-sign-up-form .mc4wp-email, .form-subscribe-form-wrap .epm-sign-up-form .email, .form-subscribe-form-wrap .epm-sign-up-form .epm-form-field, .form-subscribe-form-wrap .epm-sign-up-form .epm-sign-up-button {
    width: 100%;
  }

  .form-subscribe-form-wrap .mc4wp-form .epm-sign-up-button, .form-subscribe-form-wrap .epm-sign-up-form .epm-sign-up-button {
    margin: 0 !important;
  }

  .wrapper-tours-slider .item-tour {
    width: 100%;
  }

  .two-column-respon .wpb_column {
    width: 100%;
    float: none;
  }

  .site-header .navbar-nav .menu-right {
    display: none !important;
  }

  .images_single_left .tour_after_title .tour-share {
    width: 100%;
    text-align: left;
  }

  .tours-tabs_table {
    border-bottom: none;
  }

  .tours-tabs_table tr {
    display: block;
  }

  .tours-tabs_table td, .tours-tabs_table th {
    display: block;
    padding: 15px 0;
  }

  .tours-tabs_table table tr, .tours-tabs_table table td, .tours-tabs_table table th {
    padding: 0;
  }

  .fix-col-responsive.vc_row.vc_column-gap-15 > .vc_column_container {
    padding: 0;
  }

  .interary-item {
    min-height: 40px;
  }

  .interary-item .icon-left {
    width: 40px;
    height: 40px;
    line-height: 35px;
    font-size: 20px;
  }

  .interary-item .item_content {
    padding-left: 55px;
  }
}

@media (max-height: 480px) {
  .slider-tour-booking {
    margin-top: -35px;
  }

  .home-content .slider-tour-booking {
    background: #434343 !important;
  }
}

@media (max-width: 380px) {
  .post_list_content_unit {
    width: 100%;
    float: left;
  }

  .travel-booking-search .hb-form-table li {
    width: 100%;
  }

  .travel-booking-search .hb-form-table li.hb-submit button {
    width: 100%;
  }

  .travel-booking-search.travel-booking-style_2 form {
    padding-top: 0;
  }

  .travel-booking-search.travel-booking-style_2 .hb-form-table li {
    width: 100%;
  }

  .travel-booking-search.travel-booking-style_2 .hb-form-table li.hb-submit button {
    width: 100%;
  }

  .item-list-tour .content-list-tour .wrapper_content:before {
    display: none;
  }

  .item-list-tour .content-list-tour .wrapper_content .content-left, .item-list-tour .content-list-tour .wrapper_content .content-right {
    width: 100%;
    padding: 0;
  }

  .item-list-tour .content-list-tour .wrapper_content .content-left .item_rating .star-rating, .item-list-tour .content-list-tour .wrapper_content .content-right .item_rating .star-rating {
    margin-left: 0 !important;
  }

  .item-list-tour .content-list-tour .wrapper_content .content-right {
    text-align: left;
    padding-top: 20px;
  }

  .discounts-tour .discounts-title {
    font-size: 20px;
    line-height: 30px;
  }

  .discounts-tour .discounts-title span {
    font-size: 22px;
  }

  .counter-group {
    max-width: 214px;
  }

  .counter-block {
    float: none;
    display: inline-block;
    margin-bottom: 15px;
  }

  .counter-block .counter {
    width: 100px;
  }

  .custom-instagram ul li {
    width: 50%;
  }

  .tours-type-pain .tours_type_item {
    width: 100%;
  }

  .tours-type-pain .tours_type_item .item__title {
    font-size: 2.6em;
  }

  .tours-type-pain .tours_type_item .count-attr {
    font-size: 1.2em;
  }

  .list-posts.list-post-style_2 .post_list_content_unit .post_list_item_excerpt {
    display: none;
  }

  .list-posts.list-post-style_2 .post_list_content_unit .wrapper-meta > div {
    display: block;
    margin-bottom: 8px;
  }
}

@media (max-width: 320px) {
  .border-below h2 {
    font-size: 2.2em;
  }

  .comments-area .comment-respond .comment-reply-title {
    padding: 0 20px;
  }

  .comments-area .comment-list-inner .comments-title {
    font-size: 20px;
  }

  .comments-area .comment-list-inner li .comment-extra-info .text-right a {
    background: transparent;
    padding: 0;
  }

  .comments-area .comment-list-inner li .comment-extra-info .text-right a:hover {
    background: transparent !important;
    color: inherit !important;
  }

  .content-slider {
    display: none;
  }

  .content_tour_style_2 .item_border .item_content .wrapper_content {
    padding: 15px;
  }

  .content_tour_style_2 .item_border .read_more {
    margin: 0 15px;
  }

  .content_tour_style_2 .item_border .read_more .post_date {
    letter-spacing: -1px;
  }

  .banner-typed .phys-typingEffect {
    font-size: 2em;
  }

  .banner-typed .desc {
    font-size: 1em;
  }
}

.wrapper-content {
  background-color: #ffffff
}

.post_list_content_unit .post-list-content .post_list_meta_unit .sticky_post:after {
  border-color: transparent transparent #ffffff transparent;
}

body {
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  font-family: 'Proxima Nova Cn Rg';
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
}

a {
  color: #000;
}

a:hover {
  color: var(--main-color);
}

h1, h2, h3, h4, h5, h5, h6, .header_main_menu_wrapper .header-menu li {
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  font-family: 'Proxima Nova Cn Rg';
  font-weight: 700;
  color: #333;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h5 a, h6 a {
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  font-family: 'Proxima Nova Cn Rg';
  color: #333;
}

h1 {
  font-size: 28px;
  font-weight: normal;
}

h2 {
  font-size: 24px;
  font-weight: normal;
}

h3, .wrapper-blog-content .entry-content .entry-title {
  font-size: 22px;
  font-weight: normal;
}

h4 {
  font-size: 20px;
  font-weight: normal;
}

h5 {
  font-size: 18px;
  font-weight: normal;
}

h6 {
  font-size: 16px;
  font-weight: normal;
}

.custom-content .btn-default:hover {
  color: #ffb300;
  border-color: #ffb300;
}

.wrapper-footer {
  background: var(--footer-background);
  color: var(--footer-fon-color);
}

.wrapper-footer a {
  color: var(--footer-fon-color);
}

.wrapper-footer a:hover {
  color: var(--footer-hofer);
}

.wrapper-footer .contact-info a {
  color: var(--footer-fon-color);
}

.wrapper-footer .contact-info a:hover {
  color: var(--footer-hofer);
}

.wrapper-footer .main-top-footer {
  font-size: 13px;
  /*  border-bottom: 1px solid #5b6366;*/
}

.wrapper-footer .main-top-footer .widget-title {
  color: var(--footer-title-fon-color);
  font-size: 18px;
}

.widget-area aside .widget-title:before {
  background: #ffb300;
}

.list-arrow li {
  margin-bottom: 0;
}

.list-arrow li a {
  /*  border-bottom: 1px solid #5b6366;*/
  text-decoration: none;
}

.list-arrow li a:after {
  color: var(--footer-arrow-color);
}

.subscribe_shadow {
  background: rgba(0, 0, 0, 0.5);
}

.images_single_left .tour_after_title .meta_values:before, .images_single_left .tour_after_title .meta_date:before {
  color: #ffb300;
}

.interary-item .icon-left {
  border: 2px dashed var(--icon-color);
  color: #fff;
  background: var(--icon-color);
}

.interary-item:hover .icon-left {
  background: transparent;
  color: #26bdf7;
}

.interary-item .item_content h2, .interary-item .item_content h3 {
  font-size: 22px;
}

.single-woo-tour .description_single .entry-content-tour p.price {
  background-color: var(--card-header-background);
}

.single-woo-tour .description_single #tourBookingForm input[type="text"]:focus {
  border-color: #ffb300;
}

.navigation-menu {
  background: linear-gradient(110deg, var(--navigation-menu-background1) 0, var(--navigation-menu-background1) 35%, var(--navigation-menu-background2) 35%, var(--navigation-menu-background2) 100%) !important;
}

.search-toggler-unit .search-toggler {
  border: 3px solid var(--navigation-search);
  color: var(--navigation-search-color)
}

.search-toggler-unit .search-toggler:before {
  background-color: var(--navigation-search);
}

.header_top_bar {
  background-color: #414b4f;
  color: #ccc;
}

.header_top_bar a {
  color: #ccc;
}

.header_top_bar a:hover {
  color: #fff;
}

.header_top_bar .language-switcher-unit .stm_current_language.dropdown_toggle .fa-flag {
  color: #fff;
}

.header_top_bar .qtranxs_widget select {
  color: #ccc;
}

.header_top_bar .qtranxs_widget select:hover {
  color: #fff;
}

.transparent_home_page .affix-top .navbar-nav > li > a {
  color: #fff;
}

.transparent_home_page .sticky_header.affix .navigation-menu {
  background: #fff;
}

.post_images span.price {
  background: #ffb300;
}

.post_images .onsale {
  background: #26bdf7;
}

.post_title h4 a:hover {
  color: #26bdf7;
}

.read_more_button, .travel_tour ul.products li.product .read_more .add_to_cart_button {
  background: #26bdf7;
}

.read_more_button:hover, .travel_tour ul.products li.product .read_more .add_to_cart_button:hover {
  background: #ffb300;
}

.item_rating i {
  color: #ffb300;
}

.site-content .entry-content {
  background: #26bdf7;
}

.tours-tabs .nav-tours-tabs {
  border-bottom: 3px solid #26bdf7;
}

.tours-tabs .nav-tours-tabs li.active a {
  background: #26bdf7;
  color: #fff;
}

.tours-tabs .nav-tours-tabs {
  border-bottom: 3px solid #26bdf7;
}

.tours-tabs .nav-tours-tabs li.active a {
  background: #26bdf7;
}

.tours-tabs .tab-content {
  border: 3px solid #26bdf7;
}

.navigation ul li .page-numbers {
  color: #555;
}

.navigation ul li .page-numbers:hover, .navigation ul li .page-numbers.current {
  border-color: #26bdf7;
  color: #26bdf7;
}

.widget-icon-box .boxes-icon {
  box-shadow: 0 0 0 3px #26bdf7;
}

.widget-icon-box .boxes-icon:after {
  background: #26bdf7;
}

.tour-reviews-item .reviews-item-content .reviews-item-title a:hover {
  color: #26bdf7;
}

.post_list_content_unit .post-list-content .post_list_meta_unit .sticky_post {
  background-color: #ffb300;
}

.post_list_content_unit .post-list-content .post_list_meta_unit .border-meta-unit {
  border: 2px solid #ffb300;
}

.post_list_content_unit .post-list-content .post_list_meta_unit .border-meta-unit .post_list_comment_num {
  border-top: 2px solid #ffb300;
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .post_list_title a:hover {
  color: var(--main-color);
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .wrapper-meta .date-time:before, .post_list_content_unit .post-list-content .post_list_inner_content_unit .wrapper-meta .post_list_cats:before {
  color: var(--icon-color);
}

.post_list_content_unit .post-list-content .post_list_inner_content_unit .post_list_cats a:hover {
  color: var(--main-color);
}

.hotel-booking-search form button {
  background: #ffb300;
  box-shadow: 0 2px 0 0 rgba(255, 179, 0, 0.6);
}

#tourBookingForm input[type="submit"] {
  background: #26bdf7;
  box-shadow: 0 2px 0 0 rgba(38, 189, 247, 0.6);
}

#tourBookingForm input[type="submit"]:hover {
  background: #ffb300;
  box-shadow: 0 2px 0 0 rgba(255, 179, 0, 0.6);
}

#payment input[type="submit"]:hover {
  background: #ffb300;
  color: #fff;
}

.error {
  border-color: #26bdf7;
}

.error.placeholder {
  color: #26bdf7;
}

.error:-moz-placeholder {
  color: #26bdf7;
}

.error::-webkit-input-placeholder {
  color: #26bdf7;
}

.error:-ms-input-placeholder {
  color: #26bdf7;
}

.travel_tour ul.products li.product .item-product .wrapper_content .button, .travel_tour-page ul.products li.product .item-product .wrapper_content .button, .travel_tour ul.products li.product .item-product .wrapper_content button, .travel_tour-page ul.products li.product .item-product .wrapper_content button {
  background: #26bdf7;
  box-shadow: 0 2px 0 0 rgba(38, 189, 247, 0.6);
}

.travel_tour ul.products li.product .item-product .wrapper_content .button:hover, .travel_tour-page ul.products li.product .item-product .wrapper_content .button:hover, .travel_tour ul.products li.product .item-product .wrapper_content button:hover, .travel_tour-page ul.products li.product .item-product .wrapper_content button:hover {
  background: #ffb300;
  box-shadow: 0 2px 0 0 rgba(255, 179, 0, 0.6);
}

.travel_tour ul.products .wc-backward, .travel_tour-page ul.products .wc-backward {
  background: #26bdf7;
  box-shadow: 0 2px 0 0 rgba(38, 189, 247, 0.6);
}

.travel_tour ul.products .wc-backward:hover, .travel_tour-page ul.products .wc-backward:hover {
  background: #ffb300;
  box-shadow: 0 2px 0 0 rgba(255, 179, 0, 0.6);
}

.travel_tour span.onsale, .travel_tour-page span.onsale {
  background: #26bdf7;
}

.travel_tour div.product .single-tour-tabs ul.tabs, .travel_tour-page div.product .single-tour-tabs ul.tabs {
  border-bottom: 2px solid #ffb300;
}

.travel_tour div.product .single-tour-tabs ul.tabs li.active a, .travel_tour-page div.product .single-tour-tabs ul.tabs li.active a {
  background: var(--card-header-background);
  color: var(--card-text-color);
}

.travel_tour div.product .single-tour-tabs .entry-content, .travel_tour-page div.product .single-tour-tabs .entry-content {
  border: 2px solid var(--card-header-border-color);
}

.travel_tour div.product p.stock, .travel_tour-page div.product p.stock {
  color: #26bdf7;
}

.travel_tour div.product form.cart .button, .travel_tour-page div.product form.cart .button, .travel_tour div.product form.cart button, .travel_tour-page div.product form.cart button {
  background: #26bdf7;
  box-shadow: 0 2px 0 0 rgba(38, 189, 247, 0.6);
}

.travel_tour div.product form.cart .button:hover, .travel_tour-page div.product form.cart .button:hover, .travel_tour div.product form.cart button:hover, .travel_tour-page div.product form.cart button:hover {
  background: #ffb300;
  box-shadow: 0 2px 0 0 rgba(255, 179, 0, 0.6);
}

.travel_tour #review_form #respond .form-submit input, .travel_tour-page #review_form #respond .form-submit input {
  background: #ffb300;
  box-shadow: 0 2px 0 0 rgba(255, 179, 0, 0.6);
}

.travel_tour #review_form #respond .form-submit input:hover, .travel_tour-page #review_form #respond .form-submit input:hover {
  color: #fff;
}

.travel_tour .return-to-shop .button:hover, .travel_tour-page .return-to-shop .button:hover, .travel_tour .actions .button:hover, .travel_tour-page .actions .button:hover, .travel_tour .calculated_shipping .button:hover, .travel_tour-page .calculated_shipping .button:hover, .travel_tour .wc-proceed-to-checkout .button:hover, .travel_tour-page .wc-proceed-to-checkout .button:hover, .travel_tour .return-to-shop .button.alt:hover, .travel_tour-page .return-to-shop .button.alt:hover, .travel_tour .actions .button.alt:hover, .travel_tour-page .actions .button.alt:hover, .travel_tour .calculated_shipping .button.alt:hover, .travel_tour-page .calculated_shipping .button.alt:hover, .travel_tour .wc-proceed-to-checkout .button.alt:hover, .travel_tour-page .wc-proceed-to-checkout .button.alt:hover {
  background: #ffb300;
  color: #fff;
}

.travel_tour .related.tours h2:before, .travel_tour-page .related.tours h2:before {
  background: #ffb300;
}

.travel_tour-checkout .travel_tour-info-login-form .travel_tour-info a, .travel_tour-checkout .travel_tour-info-coupon-message .travel_tour-info a {
  color: #ffb300;
}

.travel_tour-checkout .travel_tour-info-login-form .checkout_coupon .button:hover, .travel_tour-checkout .travel_tour-info-coupon-message .checkout_coupon .button:hover {
  background: #ffb300;
  color: #fff;
}

.travel_tour-ResetPassword .form-row input.button:hover {
  background: #ffb300;
  color: #fff;
}

.related.products h2:before, .up-sells h2:before {
  background: #ffb300;
}

.post_date:before {
  color: var(--icon-color);
}

.item-list-tour .content-list-tour .wrapper_content .content-left .post_date:before {
  color: #ffb300;
}

.item-list-tour .content-list-tour .wrapper_content .content-right .price {
  color: #555;
  font-size: 22px;
}

.item-list-tour .content-list-tour .wrapper_content .content-right .add_to_cart_button {
  background: #ffb300;
}

.item-list-tour .content-list-tour .wrapper_content .content-right .add_to_cart_button:hover {
  background: #26bdf7;
}

.tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs {
  border-bottom: 3px solid #26bdf7;
}

.tb_single_tour .tb_single_tour_details ul.tb_single_tour_tabs li a.active {
  background: #26bdf7;
}

.tb_single_tour .tb_single_tour_details .tb_single_tour_tabs_content {
  border: 3px solid #26bdf7;
}

.form-subscribe-form-wrap .mc4wp-form .mc4wp-submit-button, .form-subscribe-form-wrap .epm-sign-up-form .epm-sign-up-button {
  background: #ffb300;
}

.search-overlay .search-form .search-submit {
  background: var(--navigation-search);
}

.section-yellow {
  background: #ffb300;
}

.owl-theme .owl-controls .owl-dot.active span, .owl-theme .owl-controls.clickable .owl-dot:hover span {
  background: #ffb300;
  border-color: #ffb300;
}

.icon-btn {
  background: var(--button-background);
  box-shadow: 0 2px 0 0 var(--button-background-shadow);
}

.wpcf7-form .wpcf7-submit {
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  font-family: 'Proxima Nova Cn Th';
  background: #ffb300;
  box-shadow: 0 2px 0 0 rgba(255, 179, 0, 0.6);
}

#respond input#submit {
  background: #ffb300;
  box-shadow: 0 2px 0 0 rgba(255, 179, 0, 0.6);
}

.counter-block .counter .number {
  background-color: var(--discount-background);
  box-shadow: 0 2px 0 0 var(--discount-background-shadow);
}

.stats_counter .stats_counter_number {
  color: var(--counter-text-color);
}

.hotel-booking-search form button, .form-subscribe-form-wrap .mc4wp-form .mc4wp-submit-button, .stats_counter .stats_counter_number, .stats_counter .stats_counter_title, .counter-block .counter, .header_main_menu_wrapper .header-menu li, .form-subscribe-form-wrap .epm-sign-up-form .epm-sign-up-button {
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  font-family: 'Proxima Nova Cn Th';
  font-weight: 700;
}

.tours_type_item .item__title, .counter-block .counter-caption, .tour-reviews-item .reviews-item-info .reviews-item-info-name, .icon-btn, .wpcf7-form .wpcf7-submit, #respond input#submit {
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  font-family: 'Proxima Nova Cn Th';
}

.shortcode_title.title-decoration-bottom-center .line_after_title {
  color: var(--navigation-item-before);
}

.discounts-tour .discounts-title span {
  background-color: var(--discount-background);
}

.comments-area .comment-list-inner li .comment-extra-info a:hover, .comments-area .comment-list-inner li .comment-extra-info .comment-reply-link:hover {
  color: #ffb300;
}

.widget_login_form .form_popup {
  font-size: 14px;
}

.widget_login_form .form_popup .inner-form p input[type="submit"]:hover {
  background: #ffb300;
}

.widget_login_form .form_popup .inner-form .lost-pass:hover {
  color: #ffb300;
}

.travel_tour .login input[type="submit"]:hover, .travel_tour-page .login input[type="submit"]:hover, .travel_tour .register input[type="submit"]:hover, .travel_tour-page .register input[type="submit"]:hover, .travel_tour .edit-account input[type="submit"]:hover, .travel_tour-page .edit-account input[type="submit"]:hover {
  background: #ffb300;
}

.travel_tour .travel_tour-info .button:hover, .travel_tour-page .travel_tour-info .button:hover {
  background: #ffb300;
}

.widget-area .search_tour {
  background: var(--search-box-background);
}

.widget-area .search_tour button {
  background: var(--search-box-Button);
  color: var(--search-box-Button-color);
}

.widget-area .search_tour button:hover {
  background: var(--search-box-Button-hover);
}

.post-password-form input[type="Submit"]:hover {
  background: #ffb300;
}

.wrapper-special-tours .inner-special-tours .item_price .price {
  background: #26bdf7;
}

.sc-gallery .gallery_item-wrap .gallery-item {
  background: rgba(255, 179, 0, 0.9);
}

.sc-gallery .gallery-tabs-wrapper.filters li a.active {
  color: #ffb300;
}

@media (min-width: 993px) {
  .site-header .navbar-nav > li > ul.sub-menu {
    background-color: #fff;
  }

  .site-header .navbar-nav > li > ul.sub-menu > li a {
    color: #333;
  }

  .site-header .navbar-nav > li > ul.sub-menu > li a:hover {
    color: var(--navigation-item-hover);
  }

  .navbar-nav > li:after {
    border-color: transparent transparent var(--navigation-item-after) transparent;
  }

  .navbar-nav > li:before {
    background: var(--navigation-item-before);
  }

  .navbar-nav > li > a {
    font-size: 14px;
    color: var(--navigation-item);
    font-weight: 600;
  }

  .navbar-nav > li.current-menu-item:after, .navbar-nav > li.current_page_parent:after, .navbar-nav > li.current-menu-ancestor:after {
    border-color: transparent transparent var(--navigation-item-after) transparent;
  }

  .navbar-nav > li.current-menu-item:before, .navbar-nav > li.current_page_parent:before, .navbar-nav > li.current-menu-ancestor:before {
    background: var(--navigation-item-before);
  }

  .navbar-nav > li.current-menu-item > a, .navbar-nav > li.current_page_parent > a, .navbar-nav > li.current-menu-ancestor > a {
    color: var(--navigation-item);
  }

  .navbar-nav > li > ul.sub-menu {
    background-color: #fff;
    border-top: 4px solid var(--navigation-item-after);
  }

  .navbar-nav > li > ul.sub-menu > li a {
    color: #333;
  }

  .navbar-nav > li > ul.sub-menu > li a:hover {
    color: #ffb300;
  }
}

@media (min-width: 768px) {
  .menu-right li a, .menu-right li span, .menu-right li div {
    color: #333;
    font-size: 14px;
    font-weight: 600;
  }

  .menu-right li a:hover {
    color: #ffb300;
  }

  .width-logo {
    width: 190px;
  }

  .width-navigation {
    width: calc(100% - 195px);
    width: -webkit-calc(100% - 195px);
    width: -moz-calc(100% - 195px);
  }
}

@media (max-width: 993px) {
  .width-logo {
    width: 140px;
  }

  #mobile-demo li a, .icon-toggle {
    color: #fff;
  }

  #mobile-demo li.current-menu-item > a, #mobile-demo li.current-menu-item > span {
    color: #ffb300;
  }

  .menu-mobile-effect span {
    background-color: #333;
  }

  #mobile-demo {
    background: #232323;
  }

  #mobile-demo .navbar-nav > li > a, #mobile-demo .navbar-nav > li > span {
    color: #fff;
  }

}

@media (max-width: 767px) {
  .travel_tour div.product .single-tour-tabs .panel-group .panel-default .panel-title a:not(.collapsed), .travel_tour div.product .single-tour-tabs .panel-group .panel-default .panel-title a:hover {
    background: #ffb300;
    color: #fff;
  }

  .travel_tour div.product .single-tour-tabs .panel-group .panel-collapse {
    border-color: #ffb300;
  }
}

.shortcode-tour-reviews {
  background: var(--review-background) url("../images/bg-review.png");
}

.tour-reviews-item .reviews-item-content .reviews-item-title a, .tour-reviews-item {
  color: var(--review-text-color);
}

.tours-type-slider .owl-controls .owl-dot span {
  border-color: rgba(255, 255, 255, 0.6);
}

.tours-type-slider .owl-controls .owl-dot.active span, .tours-type-slider .owl-controls.clickable .owl-dot:hover span {
  background: #fff;
  border-color: #fff;
}

.tour-reviews-item .reviews-item-info .reviews-item-rating i {
  color: var(--review-rating-color);
}

/*+++++++++++++++++++++++++++++++++++++CUSTOM FOR GLOBALIS ++++++++++++++++++++++++++++++++++++*/

.container-fluid {
  max-width: 1800px !important;
}

.content-slider .btn-slider {
  font-size: 0.7em;
  padding: 12px 50px;
}

.tours-type-slider .tours_type_item .tours-type__item__image img {
  border-radius: 1%;
}

.btn-primary {
  background: var(--button2-background);
  color: #fff !important;
  text-transform: none;
  //font-weight: 700;
  margin-bottom: 0;
  border-radius: var(--button2-radius);
  //font-size: 1.5rem;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: var(--button2-background-hover);
  border-color: #ffb300; /*set the color you want here*/
}

.btn-primary a {
  text-decoration: none;
  color: #fff !important;
  text-transform: none;
  font-weight: 700;
  font-size: 1.3em;
  margin-bottom: 0;
}

.accordion-button {
  font-size: 20px;
  padding: 20px;
}


.read_more_button, .travel_tour ul.products li.product .read_more .add_to_cart_button {
  background: var(--button2-background) !important;
  border-radius: var(--button2-radius);
  font-weight: 500;
  font-size: 15px;
}

.read_more_button:before, .travel_tour ul.products li.product .read_more .add_to_cart_button:before, .travel_tour ul.products li.product .read_more .wc-forward:before {
  border: none;
}

.read_more_button, .travel_tour ul.products li.product .read_more .add_to_cart_button, .travel_tour ul.products li.product .read_more .wc-forward {
  text-transform: none;
  text-decoration: none;
}

.price {
  & span {
    color: var(--price-color);
    font-size: 1.8rem;
    font-weight: 500;
  }
}

.contact_infor ul li label i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: var(--icon-color);
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  margin-right: 10px;
  font-size: 25px;
}

p {
  font-size: 1.2rem;
}

p.error {
  color: #bf1650;
  font-size: 1rem;
}

p.error::before {
  display: inline;
  content: "⚠ ";
}

.travel_tour #review_form #respond .form-submit input, .travel_tour-page #review_form #respond .form-submit input {
  color: #fff;
}

.wrapper-footer .main-top-footer .widget-title {
  text-transform: none;
  margin-top: 20px;
}

.top_site_main .banner-wrapper-destination .breadcrumbs-wrapper {
  text-transform: none;
}

.borderless, table .borderless, th, td {
  border: none !important;
}

.shortcode_title .title_primary {
  text-transform: none;
}

.list-group ul {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0;
}

.list-group-item {
  font-size: 18px;
  display: flex;
}

.list-group-item i {
  font-size: 30px;
  color: var(--icon-color);
  vertical-align: middle;
  padding-right: 8px;
}

.p-wrap {
  white-space: pre-line;
}

.travel_tour div.product .single-tour-tabs ul.tabs li a, .travel_tour-page div.product .single-tour-tabs ul.tabs li a {
  text-transform: none;
}


.wrapper-content .top_site_main_tiny {
  padding-top: 100px;
}

.top_site_main_tiny {
  position: relative;
  text-align: left;
  background-size: cover;
  background-position: center center;
  /*background: #000;*/
  color: #fff;
}

.card {
  margin-bottom: 15px;
}

.card-header {
  color: var(--card-text-color);
  background-color: var(--card-header-background);
  border-bottom: var(--card-header-border-color) solid 1px;
}

.card-header.sub-header {
  color: black !important;
  background-color: #E7E9EB !important;
  border-bottom: #E7E9EB solid 1px;
}

.card-header h3 {
  color: #fff;
}

.card-header.sub-header h3 {
  color: black !important;
}

.iconbox-center .icon-image-svg {
  /*display: inline-block;*/
  width: 180px;
  font-size: 0;
  margin: 0 auto;
}

.tours-type-slider .tours_type_item .content-item {
  text-transform: none;
  font-size: 1.8rem;
}

.form-control {
  font-size: 1.2rem;
}

/****************************tripCard*************************/
.tours {
  display: flex;
  flex-wrap: wrap;
}

ul .products {
  display: table-row;
}

/*li .item-tour {
    width: 100px;
    border: 1px solid black;
    display: table-cell;
}*/

.product {
  display: flex;
  flex-direction: column;
}

.item-product {
  flex-grow: 1;
  position: relative;
}

.item_rating {
  width: 90%;
  position: absolute;
  bottom: 60px;
  background: none;
}

.item_button {
  width: 90%;
  position: absolute;
  bottom: 10px;
  background: none;
  text-align: center;
}

.item_rating {
  width: auto;
  padding: 0 5px 0 10px;
}

.side-nav {
  display: inline-block;
}

.grecaptcha-badge {
  display: none;
}

.page-id-833 .grecaptcha-badge {
  z-index: 99999;
  display: block !important;
}

.avatar i {
  font-size: 70px !important;
}

.iconbox-center .icon-image-svg {
  padding: 20px;
  font-size: 90px !important;
  color: var(--icon-color);
}

h3 .fa.fa-check {
  color: var(--icon-color);
  font-size: 28px
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/****************************endTripCard END *************************/

.wrapper-footer .main-top-footer {
  font-size: 14px;
}

.interary-item:hover .icon-left {
  background: transparent;
  color: #064d92;
}

.wpcf7-form .wpcf7-submit {
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  font-family: 'Proxima Nova Cn Th';
  background: var(--button2-background);
  color: #ffffff;
  box-shadow: 0 2px 0 0 var(--button2-background-shadow);
}

.search-toggler-unit .search-toggler {
  font-size: 22px;
}

@media (min-width: 993px) {
  .navbar-nav > li > a, .navbar-nav > li > span.disable_link {
    //text-transform: none;
  }

  .navbar-nav > li > a {
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
  }

  .navbar-nav > li > ul.sub-menu > li a {
    font-size: 1.1em;
  }

}

@media (max-width: 993px) {
  .menu-mobile-effect {
    float: right;
  }

  .travel_tour ul.products.wrapper-tours-slider, .travel_tour-page ul.products.wrapper-tours-slider {
    //margin: 0 -10px;
    margin: 0;
  }
}

@media (min-width: 993px) {
  .navbar-nav > li > ul.sub-menu {
    width: auto;
  }
}

.progress-bar {
  background-color: var(--card-header-background);
}

img.logo_transparent_static, img.logo_sticky {
  max-width: 350px !important;
  height: auto !important;
}

@media (max-width: 1200px) {
  img.logo_transparent_static, img.logo_sticky {
    max-width: 100% !important;
    height: auto !important;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--card-text-color);
  background-color: var(--card-header-background);
}

.owl-item {
  padding: 0 15px;
}

.tours_type_item > a:before {
  content: "";
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main-color);
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
}

.tours_type_item > a:hover:before {
  opacity: 1;
}

.read_more {
  background: none !important;
  padding: 10px 15px 15px;
}

.read_more .item_rating {
  padding: initial;
}

.read_more .item_button {
  left: 0px;
  width: 100%;
}

.item_button .btn-primary {
  width: 80%;
}

.post_list_content_unit,
.post_list_content_unit .post-list-content .post_list_inner_content_unit {
  padding: 10px 15px 15px !important;
}

@media (max-width: 1200px) {
  .wrapper-bg-video {
    position: relative;
    padding-bottom: 60.25%; /* 16:9 */
    height: 0;
  }
}

.stats_counter .wrapper-icon img {
  width: 130px;
}

.stats_counter .stats_counter_title {
  font-size: 15px;
  text-transform: none;
  padding-top: 50px;
}

.stats_counter_title_link {
  color: #FFF;
}

.navbar {
  margin-bottom: 0;
  border-radius: 0;
  background-color: #f8f9fa;
}

.img-logo {
  width: 250px;
  max-width: 100%;
}

.offcanvas {
  width: 75% !important;
  overflow: auto;
  background-color: var(--footer-background);
  color: var(--footer-fon-color);
}


nav .search, .offcanvas .search,
.content-slider .search {
  color: #FFF !important;
  background-color: var(--main-color) !important;
  padding: 0 20px !important;
  line-height: initial !important;
}

@media (max-width: 768px) {
  .navbar-collapse.collapse {
    display: none !important;
  }
  .img-logo {
    width: 150px;
    max-width: 100%;
  }
}

.offcanvas .nav-link {
  font-size: 20px;
}

.btn-link {
  background-color: transparent;
  color: #FFF;
  border: none;
  text-decoration: none;
  font-weight: 600;
}

.btn-link:hover {
  color: #000;
}

.button-icon, .tools button {
  border: none;
  border-color: unset;
  background: none;
}

.button-icon {
  //color: #FFF;
}

.tools button,
.fav i {
  color: var(--main-color);
}

.badge {
  background-color: var(--main-color);;
}

.custom-sz {
  height: 3vw;
}

button.dashboard,
table.dashboard .header td,
table.dashboard .header th {
  background-color: var(--main-color) !important;
  color: white !important;
}

.icon-btn {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2;
  border-radius: 5px;
  background: var(--button-background);
  box-shadow: 0 1px 0 0 var(--button-background-shadow);
}

h2.alfidcl_introduction, h2.alfidcl_category, h2.alfidcl_appendix, h2.alfidcl_dpoinfoneeded, h2.alfidcl_cookiesfound, h2.alfidcl_servicesfound, h2.alfidcl_thirdcountryfound, h2.alfidcl_always, h2.alfidcl_optional, h2.alfidcl_application, h2.alfidcl_host {
  /*color: #000000;*/
  /*font-family: 'Arial', sans-serif;*/
  margin: 10px 0;
  margin-top: 40px;
  /*font-size: 21px;*/
  font-weight: 500;
}

.stepper .navbar-nav > li:after {
  border-color: transparent !important;
}

.stepper .navbar-nav > li:before {
  background: transparent !important;
}

form label {
  font-size: 1.2rem;
}
/****************************CUSTOM FOR GLOBALIS END *************************/